import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './appliedFilterLabels.module.scss'
import { FILTER_SECTIONS } from 'components/filter/updateFilters'

function AppliedFilterLabels({ filters, showTerms, onRemoveFilter }) {
  const { t } = useTranslation()
  console.log({ filters })

  const countries = filters.countries || []
  const applicants = filters.applicants || []
  const inventors = filters.inventors || []
  const claimsAnd = filters.claims?.and || []
  const claimsNot = filters.claims?.not || []
  const classificationsIPCR = filters.classificationsIPCR || []
  const priorityDateStart = filters.priorityDateStart || ''
  const priorityDateEnd = filters.priorityDateEnd || ''
  const publicationDateStart = filters.publicationDateStart || ''
  const publicationDateEnd = filters.publicationDateEnd || ''
  const terms = filters.terms || []

  const label = (section, value, subSection = null) => {
    return (
      <div className={styles.label} key={value + '_' + value}>
        <span>{value}</span>
        <span
          className={styles.removeFilter}
          onClick={() => onRemoveFilter(section, value, subSection)}
        >
          X
        </span>
      </div>
    )
  }

  const category = (filterSection, value, sectionName, subSection = null) => {
    const wrappedData =
      typeof value === 'string'
        ? label(filterSection, value)
        : value.map(item => label(filterSection, item, subSection))

    return (
      <div className={`${styles.category} ${value.length === 0 ? styles.hidden : ''}`}>
        <span>{sectionName}</span>
        {wrappedData}
      </div>
    )
  }

  //TODO: to be updated in the out of boundary check ticket.
  const areFiltersEmpty = Object.values(filters)
    .map(value => {
      if (value === null) return true
      if (typeof value === 'object') {
        return Object.values(value).length === 0
      }
      if (typeof value === 'string') return value === ''
      return value === 0
    })
    .every(item => item)

  return (
    <div className={styles.base}>
      {areFiltersEmpty ? (
        <span>No filters selected</span>
      ) : (
        <div className={styles.filters}>
          {category(
            FILTER_SECTIONS.claims,
            claimsAnd,
            t('GLOBAL.patentSections.claims') + ' "AND"',
            FILTER_SECTIONS.claimsAnd
          )}

          {category(
            FILTER_SECTIONS.claims,
            claimsNot,
            t('GLOBAL.patentSections.claims') + ' "NOT"',
            FILTER_SECTIONS.claimsNot
          )}

          {category(FILTER_SECTIONS.applicants, applicants, t('GLOBAL.patentSections.applicants'))}

          {category(FILTER_SECTIONS.inventors, inventors, t('GLOBAL.patentSections.inventors'))}

          {category(
            FILTER_SECTIONS.countries,
            countries,
            t('SearchDetailsPage.PatentFilters.CountrySelector.countries')
          )}

          {category(
            FILTER_SECTIONS.classificationsIPCR,
            classificationsIPCR,
            t('GLOBAL.patentSections.classificationsIPCR')
          )}

          {category(
            FILTER_SECTIONS.publicationDateStart,
            publicationDateStart,
            t('SearchDetailsPage.PatentFilters.publicationDate', { type: 'start' })
          )}

          {category(
            FILTER_SECTIONS.publicationDateEnd,
            publicationDateEnd,
            t('SearchDetailsPage.PatentFilters.publicationDate', { type: 'end' })
          )}

          {category(
            FILTER_SECTIONS.priorityDateStart,
            priorityDateStart,
            t('SearchDetailsPage.PatentFilters.priorityDate', { type: 'start' })
          )}

          {category(
            FILTER_SECTIONS.priorityDateEnd,
            priorityDateEnd,
            t('SearchDetailsPage.PatentFilters.priorityDate', { type: 'end' })
          )}

          {showTerms &&
            category(
              FILTER_SECTIONS.terms,
              terms,
              t('SearchDetailsPage.PatentFilters.TermsSelector.terms')
            )}
        </div>
      )}
    </div>
  )
}

AppliedFilterLabels.propTypes = {
  filters: PropTypes.object,
  showTerms: PropTypes.bool,
}

AppliedFilterLabels.defaultProps = {
  filters: {},
  showTerms: true,
}

export default AppliedFilterLabels
