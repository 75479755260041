import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { debounce } from 'debounce'
import Mark from 'mark.js'

import { elementStateSelector, setExpandStateCreator, PATENT_UI_ELEMENTS } from 'store/Interface'

import Foldable from 'components/Foldable'
import { highlightText, highlightTerms } from 'components/search/highlightText'

import styles from './patent.module.scss'

let markInstance = null

function Patent({ patent, textsToHighlight, hideClaimsOnHighlight, parentElementRef }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [rerunHighlightToggle, setRerunHighlightToggle] = React.useState(false)
  const [filterText, setFilterText] = React.useState([])

  const patentTextsNodeRef = React.useRef(null)

  const setSectionExpandValue = (sectionName, value) =>
    dispatch(
      setExpandStateCreator({
        parent: parentElementRef,
        child: { [sectionName]: value },
      })
    )

  const claimsExpandStoreState = useSelector(state =>
    elementStateSelector(state, parentElementRef, [PATENT_UI_ELEMENTS.claims])
  )

  const claimsExpandState =
    claimsExpandStoreState === undefined
      ? setSectionExpandValue(PATENT_UI_ELEMENTS.claims, true)
      : claimsExpandStoreState

  const abstractExpandState = useSelector(state =>
    elementStateSelector(state, parentElementRef, [PATENT_UI_ELEMENTS.abstract])
  )

  const descriptionExpandState = useSelector(state =>
    elementStateSelector(state, parentElementRef, [PATENT_UI_ELEMENTS.description])
  )

  const patentTextsContainerRef = React.useCallback(node => {
    if (node !== null) {
      markInstance = new Mark(node)
      setRerunHighlightToggle(prev => !prev)
      patentTextsNodeRef.current = node
    }
    // eslint-disable-next-line
  }, [])

  const unhideAllClaims = useCallback(() => {
    if (patentTextsNodeRef.current !== null) {
      const claims = patentTextsNodeRef.current.querySelectorAll('claim')
      claims.forEach(item => item.classList.remove(styles.hidden))
    }
  }, [patentTextsNodeRef])

  const hideAllClaims = useCallback(() => {
    if (patentTextsNodeRef.current !== null) {
      const claims = patentTextsNodeRef.current.querySelectorAll('claim')
      claims.forEach(item => item.classList.add(styles.hidden))
    }
  }, [patentTextsNodeRef])

  const hideClaimsNonHighlighted = useCallback(
    selector => {
      if (hideClaimsOnHighlight && patentTextsNodeRef.current !== null) {
        hideAllClaims()
        const highlighted = patentTextsNodeRef.current.querySelectorAll(selector)
        highlighted.forEach(item => {
          const closest = item.parentElement.closest('claim')
          if (closest) closest.classList.remove(styles.hidden)
        })
      }
    },

    [hideAllClaims, hideClaimsOnHighlight]
  )

  const highlightWithTypedText = useCallback((toMarkUpArr, highlightClassName) => {
    markInstance = highlightText(markInstance, toMarkUpArr, highlightClassName)
  }, [])

  const highlightWithTerms = useCallback((toMarkUpArr, highlightClassName) => {
    markInstance = highlightTerms(markInstance, toMarkUpArr, highlightClassName)
  }, [])

  const handleDebounced = useCallback(func => debounce(func(), 300), [])

  useEffect(() => {
    const filterHighlightClassName = styles.typedTextHighlight
    const highlightClassName = styles.selectedTermHighlight

    if (markInstance !== null) {
      markInstance.unmark({ className: filterHighlightClassName })
      markInstance.unmark({ className: highlightClassName })
    }
    unhideAllClaims()

    if (textsToHighlight.length > 0 || filterText.length > 0) {
      handleDebounced(() => {
        highlightWithTypedText(filterText, filterHighlightClassName)
        highlightWithTerms(textsToHighlight, highlightClassName)

        const selector = `.${filterHighlightClassName}${
          hideClaimsOnHighlight ? ',.' + highlightClassName : ''
        }`
        hideClaimsNonHighlighted(selector)
      })
    }
  }, [
    textsToHighlight,
    rerunHighlightToggle,
    hideClaimsOnHighlight,
    filterText,
    highlightWithTypedText,
    highlightWithTerms,
    handleDebounced,
    unhideAllClaims,
    hideClaimsNonHighlighted,
    patentTextsContainerRef,
    claimsExpandState,
    abstractExpandState,
    descriptionExpandState,
  ])

  return (
    <div className={styles.base}>
      <div className={styles.patentTextsContainer} ref={patentTextsContainerRef}>
        <div className={styles.claimTextFilter}>
          <input
            data-testid='details-page-claims-filter-input'
            type='text'
            placeholder={t('Patent.typeToHiglightPlaceholder')}
            onChange={ev => setFilterText(ev.target.value.length > 0 ? [ev.target.value] : [])}
          />
        </div>

        <Foldable
          title={t('GLOBAL.patentSections.claims').toUpperCase()}
          isOpenInitially={claimsExpandState}
          saveIsOpenSateToLocal={value => setSectionExpandValue(PATENT_UI_ELEMENTS.claims, value)}
        >
          <div
            data-testid='details-page-claims-text'
            className={styles.claimsContainer}
            dangerouslySetInnerHTML={{ __html: patent?.claims }}
          ></div>
        </Foldable>

        <Foldable
          title={t('GLOBAL.patentSections.abstract')}
          isOpenInitially={abstractExpandState}
          saveIsOpenSateToLocal={value => setSectionExpandValue(PATENT_UI_ELEMENTS.abstract, value)}
        >
          <div
            data-testid='details-page-abstract'
            dangerouslySetInnerHTML={{ __html: patent?.abstract }}
          ></div>
        </Foldable>

        <Foldable
          title={t('GLOBAL.patentSections.description')}
          isOpenInitially={descriptionExpandState}
          saveIsOpenSateToLocal={value =>
            setSectionExpandValue(PATENT_UI_ELEMENTS.description, value)
          }
        >
          <div
            data-testid='details-page-description'
            dangerouslySetInnerHTML={{ __html: patent?.description }}
          ></div>
        </Foldable>
      </div>
    </div>
  )
}

Patent.propTypes = {
  patent: PropTypes.object.isRequired,
  textsToHighlight: PropTypes.array,
  hideClaimsOnHighlight: PropTypes.bool,
  parentElementRef: PropTypes.string,
}

Patent.defaultProps = {
  patent: {},
  textsToHighlight: [],
  hideClaimsOnHighlight: false,
}
export default Patent
