import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { paths } from 'router/paths'
import { STATUS_STATES } from 'store/Status'

import SearchListItem from './components/SearchListItem'
import Checkbox from 'components/Checkbox'

import style from './searchList.module.scss'
import ConfirmDialog from 'components/ConfirmDialog'

const actions = { default: 'default', archive: 'archive', delete: 'delete' }

function SearchList({
  searches,
  handleArchiveClick,
  handleDeleteClick,
  updateSearchName,
  handleBatchArchive,
  handleBatchDelete,
  shouldDisableSelected,
  actionStatuses,
}) {
  const { t } = useTranslation()
  const location = useLocation()

  const [allSelected, setAllSelected] = React.useState(false)
  const [selected, setSelected] = React.useState([])
  const [selectedAction, setSelectedAction] = React.useState(actions.default)

  useEffect(() => {
    // Reset state on switching between current and archive
    setAllSelected(false)
    setSelected([])
    setSelectedAction(actions.default)
  }, [location.pathname])

  const onSelectAllToggle = () => {
    setAllSelected(prev => !prev)

    if (allSelected) setSelected([])
    else {
      const allIds = searches.map(search => search.id)
      setSelected(allIds)
    }
  }

  const onSearchToggle = searchId => {
    const filterSelected = selectedSearches => {
      if (selectedSearches.includes(searchId)) {
        setAllSelected(false)
        return selectedSearches.filter(prevSearch => prevSearch !== searchId)
      }
      return [...selectedSearches, searchId]
    }

    setSelected(prev => filterSelected(prev))
  }

  const sortedSearches = searches.sort(
    (a, b) => Date.parse(b.createDate) - Date.parse(a.createDate)
  )

  if (!searches.length) return null

  const onConfirmArchive = () => {
    handleBatchArchive(selected)
    setSelectedAction(actions.default)
    setSelected([])
  }

  const confirmArchiveMessage = t('ConfirmDialog.action', {
    action: t('SearchList.archive').toLowerCase(),
    name: selected.length + ' ' + t('SearchList.searches'),
  })

  const onConfirmDelete = () => {
    handleBatchDelete(selected)
    setSelectedAction(actions.default)
    setSelected([])
  }

  const confirmDeleteMessage = t('ConfirmDialog.action', {
    action: t('SearchList.remove').toLowerCase(),
    name: selected.length + ' ' + t('SearchList.searches'),
  })

  return (
    <div className={style.base}>
      <div className={style.listActions}>
        <Checkbox
          dataTestId='current-searches-select-all'
          checked={allSelected}
          onChange={onSelectAllToggle}
          disabled={shouldDisableSelected && allSelected}
        >
          {t('SearchList.selectAll')}
        </Checkbox>

        {selectedAction === actions.archive && (
          <ConfirmDialog
            onConfirm={onConfirmArchive}
            onCancel={() => setSelectedAction(actions.default)}
            message={confirmArchiveMessage}
          />
        )}

        {selectedAction === actions.delete && (
          <ConfirmDialog
            onConfirm={onConfirmDelete}
            onCancel={() => setSelectedAction(actions.default)}
            message={confirmDeleteMessage}
          />
        )}

        <label>
          <select
            name='action'
            className={style.options}
            value={actions[selectedAction]}
            onChange={e => setSelectedAction(e.target.value)}
            disabled={selected.length === 0 || shouldDisableSelected}
          >
            <option disabled value={actions.default}>
              {t('SearchList.defaultOption')}
            </option>
            {location.pathname !== paths.archivedSearches && (
              <option value={actions.archive}>{t('SearchList.archiveSelected')}</option>
            )}
            <option value={actions.delete}>{t('SearchList.deleteSelected')}</option>
          </select>
        </label>
      </div>

      {sortedSearches.map(search => {
        return (
          <SearchListItem
            key={search.id}
            search={search}
            handleArchiveClick={handleArchiveClick}
            handleDeleteClick={handleDeleteClick}
            checked={selected.includes(search.id)}
            onSelectToggle={() => onSearchToggle(search.id)}
            updateSearchName={(id, name) => updateSearchName(id, name)}
            shouldDisableSelected={
              shouldDisableSelected &&
              Boolean(
                actionStatuses.find(
                  ([key, value]) =>
                    key.includes('_' + search.id) && value.state === STATUS_STATES.BUSY
                )
              )
            }
          />
        )
      })}
    </div>
  )
}

SearchList.propTypes = {
  searches: PropTypes.array.isRequired,
  handleArchiveClick: PropTypes.func,
  handleDeleteClick: PropTypes.func.isRequired,
  updateSearchName: PropTypes.func.isRequired,
  handleBatchArchive: PropTypes.func,
  handleBatchDelete: PropTypes.func.isRequired,
  shouldDisableSelected: PropTypes.bool,
  actionStatuses: PropTypes.array,
}

SearchList.defaultProps = {
  searches: [],
  shouldDisableSelected: false,
}

export default SearchList
