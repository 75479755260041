import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Button'

import style from './term.module.scss'

function Term({ term, addTerm, excludeTerm }) {
  const termValue = `${(term.key || '').replace('_', ' ')} (${term.value})`

  return (
    <div className={`${style.base} ${term.exclude ? style.removedTerm : ''}`}>
      <div data-testid='search-setup-term'>
        <div className={style.value}>{termValue}</div>

        {term.exclude ? (
          <Button onClick={() => addTerm()} variant={'searchTermAction'}>
            +
          </Button>
        ) : (
          <Button onClick={() => excludeTerm()} variant={'searchTermAction'}>
            x
          </Button>
        )}
      </div>
    </div>
  )
}

Term.propTypes = {
  term: PropTypes.object.isRequired,
  addTerm: PropTypes.func.isRequired,
  excludeTerm: PropTypes.func.isRequired,
}

export default Term
