import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { paths } from 'router/paths'
import { SEARCH_TYPE } from 'services/api'
import { SEARCH_STATUS } from 'store/Search/constants'
import { STATUS_STATES } from 'store/Status'
import {
  allSearchesArraySelector,
  getSearches,
  archive,
  deleteSearch,
  updateName,
} from 'store/Search'
import { userDataSelector } from 'store/Account'

import Svg from 'components/Svg'
import { ReactComponent as Spinner } from 'assets/images/spinner-animated.svg'
import SearchList from './components/SearchList'
import ConfirmDialog from 'components/ConfirmDialog'

import style from './searchesPage.module.scss'

const pageLoadStatusRef = 'SearchesPage_pageLoad'

const actionRef = 'SearchesPage_action'
const actionStatusRef = (searchId, postfix) => actionRef + '_' + searchId + '_' + postfix

let timer = null

function SearchesPage() {
  const [searchType, setSearchType] = React.useState(SEARCH_TYPE.active)
  const [deleteSearchId, setDeleteSearchId] = React.useState('')
  const [archiveSearchId, setArchiveSearchId] = React.useState('')
  const [shouldDisableSelected, setShouldDisableSelected] = React.useState(false)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const path = location.pathname

  const allSearches = useSelector(allSearchesArraySelector)
  const user = useSelector(userDataSelector)

  const pageLoadStatus = useSelector(state => state.Status.statuses[pageLoadStatusRef]) || {}
  const statuses = useSelector(state => state.Status.statuses || {})

  const actionStatuses = useMemo(
    () => Object.entries(statuses).filter(([key]) => key.startsWith(actionRef)),
    [statuses]
  )

  const allCurrentSearches = allSearches.filter(value => {
    const isStatusArchive = value.state === SEARCH_STATUS.archive

    if (searchType === SEARCH_TYPE.archive) return isStatusArchive
    return !isStatusArchive
  })

  const refreshData = options => {
    let type = null
    if (path === paths.archivedSearches) type = SEARCH_TYPE.archive
    else type = SEARCH_TYPE.active
    dispatch(getSearches({ searchType: type, statusRef: pageLoadStatusRef, poll: options?.poll }))
    setSearchType(type)
  }

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    const toRefresh = Object.values(allSearches).find(value =>
      [SEARCH_STATUS.processing, SEARCH_STATUS.queued].includes(value.status) ? true : null
    )

    if (toRefresh && path === paths.searches) {
      clearTimeout(timer)

      timer = setTimeout(() => {
        refreshData({ poll: true })
      }, 5000)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [allSearches])

  useEffect(() => {
    const areAllActionsFinished = actionStatuses.every(
      ([, value]) => value.state === STATUS_STATES.FINISHED
    )

    if (areAllActionsFinished) setShouldDisableSelected(false)
    else setShouldDisableSelected(true)
  }, [actionStatuses, pageLoadStatus.state])

  const runOnInterval = (selected, operation) => {
    setShouldDisableSelected(true)

    let i = 0
    const intervalId = setInterval(() => {
      if (i === selected.length - 1) clearInterval(intervalId)

      operation(selected[i])
      i++
    }, 100)
  }

  const batchArchive = selected => {
    const operation = searchId =>
      dispatch(
        archive({
          id: searchId,
          userId: user.id,
          statusRef: actionStatusRef(searchId, 'archive'),
        })
      )
    runOnInterval(selected, operation)
  }

  const batchDelete = selected => {
    const operation = searchId =>
      dispatch(deleteSearch({ id: searchId, statusRef: actionStatusRef(searchId, 'deleteSearch') }))
    runOnInterval(selected, operation)
  }

  return (
    <div className={style.base}>
      <div className={style.loadingContainer}>
        {pageLoadStatus.state === STATUS_STATES.BUSY && (
          <Svg
            SvgImage={Spinner}
            alt={t('GLOBAL.spinnerAlt')}
            dataTestId='spinner'
            variant='loader'
          />
        )}
      </div>

      {deleteSearchId && (
        <ConfirmDialog
          onConfirm={() => {
            dispatch(
              deleteSearch({
                id: deleteSearchId,
                statusRef: actionStatusRef(deleteSearchId, 'deleteSearch'),
              })
            )
            setDeleteSearchId('')
          }}
          onCancel={() => setDeleteSearchId('')}
          message={t('ConfirmDialog.action', {
            action: 'delete',
            name: allSearches.find(search => search.id === deleteSearchId).name,
          })}
        />
      )}

      {archiveSearchId && (
        <ConfirmDialog
          onConfirm={() => {
            dispatch(
              archive({
                id: archiveSearchId,
                userId: user.id,
                statusRef: actionStatusRef(archiveSearchId, 'archive'),
              })
            )
            setArchiveSearchId('')
          }}
          onCancel={() => setArchiveSearchId('')}
          message={t('ConfirmDialog.action', {
            action: 'archive',
            name: allSearches.find(search => search.id === archiveSearchId).name,
          })}
        />
      )}

      <div id='doneSearches'>
        <h2>{t('Searches.results')}</h2>

        {pageLoadStatus.state === STATUS_STATES.FINISHED && !allCurrentSearches.length && (
          <div data-testid='current-searches-no-searches-found' className={style.noResultsText}>
            {t('Searches.noSearchesFound')}
          </div>
        )}

        {pageLoadStatus.state === STATUS_STATES.ERROR && (
          <div data-testid='current-searches-error-getting-searches' className={style.error}>
            {t('Searches.errorGettingSearches')}
          </div>
        )}

        <SearchList
          searches={allCurrentSearches}
          handleArchiveClick={id => setArchiveSearchId(id)}
          handleDeleteClick={id => setDeleteSearchId(id)}
          updateSearchName={(id, name) =>
            dispatch(
              updateName({
                id,
                userId: user.id,
                name,
                statusRef: actionStatusRef('', 'update'),
              })
            )
          }
          handleBatchArchive={selected => batchArchive(selected)}
          handleBatchDelete={selected => batchDelete(selected)}
          shouldDisableSelected={shouldDisableSelected}
          actionStatuses={actionStatuses}
        />
      </div>
    </div>
  )
}

export default SearchesPage
