import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  preprocess,
  preprocessAndSearch,
  search,
  removePreprocessed,
  updatePreprocessedList,
} from 'store/Search'
import { STATUS_STATES, clearStatus } from 'store/Status'
import { generateFile } from 'containers/Search/utils'

import Button from 'components/Button'
import TermsList from './containers/TermsList'
import Filters from './containers/Filters'
import Checkbox from 'components/Checkbox'
import Foldable from 'components/Foldable'
import Svg from 'components/Svg'
import { ReactComponent as Spinner } from 'assets/images/spinner-animated.svg'

import style from './searchItem.module.scss'

const itemPreprocessRef = 'SearchItem_preprocess'

const getSearchStatusRef = itemRef => itemRef + '_search'

function SearchItem({
  fileData: { id, data },
  areUnsavedChanges,
  setAreUnsavedChanges,
  preprocessRef,
  canSubmitSearch,
  isSearchCopy,
  notEnoughTerms,
}) {
  const [isNextClicked, setIsNextClicked] = React.useState(false)
  const [skipOptions, setSkipOptions] = React.useState(true)
  const [inputText, setInputText] = React.useState(data.text || '')
  const [name, setName] = React.useState(data.name || data.filename)
  const [needsPreprocessing, setNeedsPreprocessing] = React.useState(false)
  const [disableSearchButton, setDisableSearchButton] = React.useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isTextSearch = !data

  const searchItemPreprocessRef = preprocessRef || itemPreprocessRef
  const searchItemSearchSubmitRef = getSearchStatusRef(searchItemPreprocessRef)

  const triggerPreprocess = (textToInclude = '') => {
    dispatch(
      preprocess({
        id,
        file: generateFile(textToInclude + ' ' + inputText),
        name,
        inputText: textToInclude + ' ' + inputText,
        statusRef: searchItemPreprocessRef,
      })
    )
  }

  const nextStepActions = () => {
    triggerPreprocess()
    setIsNextClicked(true)
    setAreUnsavedChanges(false)
  }

  useEffect(
    () => {
      setInputText(data.text)

      if (isSearchCopy) {
        nextStepActions()
        setSkipOptions(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const isItemRef = preprocessRef && preprocessRef.includes(name)

  const preprocessStatus =
    useSelector(
      state => state.Status.statuses[(isItemRef && preprocessRef) || itemPreprocessRef]
    ) || {}

  const searchStatus = useSelector(state => state.Status.statuses[searchItemSearchSubmitRef]) || {}

  const isPreprocessing = preprocessStatus.state === STATUS_STATES.BUSY
  const preprocessHasError = preprocessStatus.state === STATUS_STATES.ERROR

  const isSearching = searchStatus.state === STATUS_STATES.BUSY
  const searchHasError = searchStatus.state === STATUS_STATES.ERROR

  const triggerPreprocessAndSearch = () => {
    if (isTextSearch || areUnsavedChanges || needsPreprocessing)
      dispatch(
        preprocessAndSearch({
          id,
          file: generateFile(inputText),
          name,
          inputText,
          preprocessStatusRef: searchItemPreprocessRef,
          searchStatusRef: searchItemSearchSubmitRef,
        })
      )
    else dispatch(search({ id, name, statusRef: searchItemSearchSubmitRef }))
  }

  const canShowNextButton = !skipOptions && (!isNextClicked || areUnsavedChanges)

  //TODO: refactor: too many conditions?
  const getShouldDisableButton = isSearchButton => {
    if (isSearchButton && (searchHasError || preprocessHasError)) return false

    const isReadyToPreprocess = isPreprocessing || !inputText?.length

    return isSearchButton
      ? isSearching ||
          isReadyToPreprocess ||
          (!canSubmitSearch && isNextClicked) ||
          disableSearchButton
      : isReadyToPreprocess
  }

  useEffect(() => {
    if (searchHasError && areUnsavedChanges)
      dispatch(clearStatus({ statusRef: searchItemSearchSubmitRef }))
  })

  useEffect(() => {
    if (inputText && inputText.trim().indexOf(' ') === -1) setDisableSearchButton(true)
    else setDisableSearchButton(false)
    setNeedsPreprocessing(true)
    if (notEnoughTerms && !isNextClicked) {
      dispatch(
        updatePreprocessedList({
          id: name,
          dataToUpdate: { status: null },
        })
      )
    }
    if (preprocessHasError) dispatch(clearStatus({ statusRef: searchItemPreprocessRef }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputText])

  return (
    <div className={style.searchItem}>
      <div className={style.searchNameContainer}>
        <label htmlFor='name'>{t('SearchPage.SearchItem.searchName')}</label>
        <input
          id='searchName'
          data-testid='search-setup-search-name'
          type='text'
          value={name}
          maxLength={100}
          onChange={e => setName(e.target.value)}
        />
      </div>

      <div className={style.removeContainer}>
        <Button
          dataTestId='search-setup-remove-search'
          variant='removeButton'
          onClick={() => dispatch(removePreprocessed({ id }))}
        >
          x
        </Button>
      </div>

      <div className={style.searchTextSection}>
        {isPreprocessing && !isNextClicked ? (
          <div className={style.loadingContainer}>
            <Svg
              SvgImage={Spinner}
              alt={t('GLOBAL.spinnerAlt')}
              dataTestId='spinner'
              variant='loader'
            />
          </div>
        ) : (
          <ConditionalWrapper
            condition={isNextClicked}
            wrapper={children => (
              <Foldable title={t('SearchPage.SearchItem.textSection')}>{children}</Foldable>
            )}
          >
            <textarea
              data-testid='search-setup-text-input'
              value={inputText}
              onChange={ev => {
                setInputText(ev.target.value)
                setAreUnsavedChanges(true)
              }}
              rows='5'
              cols='100'
              placeholder={t('SearchPage.SearchItem.textInputPlaceholder')}
            />
            {notEnoughTerms && !isNextClicked && (
              <div className={style.error}>{t('SearchPage.noTerms')}</div>
            )}
          </ConditionalWrapper>
        )}
      </div>

      {!skipOptions && isNextClicked && (
        <div className={style.advancedOptions}>
          <Foldable title={t('SearchPage.SearchItem.termsSection')} isOpenInitially={true}>
            <TermsList
              id={id}
              rawTerms={data?.terms || []}
              isPreprocessing={isPreprocessing}
              updateSearchText={newTerms => setInputText(previous => newTerms + ' ' + previous)}
              triggerPreprocess={textToInclude => triggerPreprocess(textToInclude)}
              filterTerms={data?.filter?.terms || []}
            />
          </Foldable>
          <Foldable title={t('SearchPage.SearchItem.filtersSection')} isOpenInitially={true}>
            <Filters id={id} data={data} />
          </Foldable>
        </div>
      )}

      <div className={style.buttonCheckContainer}>
        <div className={style.buttons}>
          {!canSubmitSearch && !isPreprocessing && isNextClicked && !preprocessHasError && (
            <div className={style.error}>{t('SearchPage.SearchItem.minimumTermsWarning')}</div>
          )}

          {preprocessHasError && (
            <div className={style.error}>{t('SearchPage.errorPreprocessing')}</div>
          )}

          {searchHasError && <div className={style.error}>{t('SearchPage.errorSubmitting')}</div>}

          {canShowNextButton ? (
            <Button
              dataTestId='search-setup-next-button'
              onClick={nextStepActions}
              disabled={getShouldDisableButton(false)}
            >
              {t('GLOBAL.next')}
            </Button>
          ) : (
            <Button
              dataTestId='search-setup-search-button'
              onClick={() => triggerPreprocessAndSearch()}
              disabled={getShouldDisableButton(true)}
            >
              {t('SearchPage.search')}
            </Button>
          )}
        </div>

        {!isNextClicked && (
          <div className={style.check}>
            <Checkbox
              dataTestId='search-setup-option-checkbox'
              checked={skipOptions}
              onChange={e => setSkipOptions(e.target.checked)}
            >
              {t('SearchPage.skipOptions')}
            </Checkbox>
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchItem

SearchItem.propTypes = {
  fileData: PropTypes.object,
  areUnsavedChanges: PropTypes.bool.isRequired,
  setAreUnsavedChanges: PropTypes.func.isRequired,
  preprocessRef: PropTypes.string,
  canSubmitSearch: PropTypes.bool.isRequired,
  notEnoughTerms: PropTypes.bool,
}

SearchItem.defaultProptypes = {
  fileData: {},
  preprocessRef: '',
  notEnoughTerms: false,
}

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children
