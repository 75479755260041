import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import * as ApiService from 'services/api'

import { updateFilterArray } from '../updateFilters'

import Checkbox from 'components/Checkbox'
import TextInput from 'components/TextInput'
import Background from '../Background'
import Svg from 'components/Svg'
import { ReactComponent as CaretUp } from 'assets/images/caret-up.svg'
import { ReactComponent as CaretDown } from 'assets/images/caret-down.svg'

import styles from './countrySelector.module.scss'

function CountrySelector({ initialValues, values, onFilterChange, onOutOfBoundary }) {
  const { t } = useTranslation()

  const [inputValue, setInputValue] = React.useState('')
  const [isOpen, setIsOpen] = React.useState(false)
  const [checkItems, setCheckItems] = React.useState([])
  const countriesList = ApiService.defaultFilters.countries

  const isOutOfBoundary = !(
    initialValues.length === 0 || values.every(value => initialValues.includes(value))
  )

  useEffect(() => {
    if (onOutOfBoundary) onOutOfBoundary(isOutOfBoundary)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    const sorted = countriesList?.sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })

    setCheckItems(sorted)
  }, [countriesList])

  const toggleItem = (_, value) => {
    const updated = updateFilterArray({
      currentValues: values,
      value,
      shouldAdd: !values.includes(value),
    })

    onFilterChange(updated)
  }

  const toggleAll = ev => {
    const { checked } = ev.target

    const updated = checked ? checkItems.map(c => c.value) : []
    onFilterChange(updated)
  }

  const shouldBeChecked = item => values.indexOf(item.value) > -1

  //FIXME: initial values for countries seem to change where they shouldn't. To be fixed separately.
  const initialValuesRef = React.useRef()
  useEffect(() => {
    if (!initialValuesRef.current && initialValues.length > 0)
      initialValuesRef.current = initialValues
  }, [initialValues])

  const selectorRef = useRef(null)
  const handleClickOutside = e => {
    if (selectorRef.current && !selectorRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const getCountryNameTranslated = countryCode =>
    `${countryCode} (${t(
      'SearchDetailsPage.PatentFilters.CountrySelector.countryNames' + '.' + countryCode
    )})`

  const doesContainItem = text => {
    const translatedCountryName = getCountryNameTranslated(text)

    if (inputValue.length) {
      const regex = new RegExp(inputValue, 'gi')
      return regex.test(translatedCountryName)
    }

    return true
  }

  return (
    <div className={styles.base} ref={selectorRef} data-testid={'country-selector'}>
      <span>{t('SearchDetailsPage.PatentFilters.CountrySelector.countries')}</span>

      <Background>
        <TextInput
          value={inputValue}
          dataTestId='country-selector-input'
          onInputValueChange={value => setInputValue(value)}
          onEnterKeyPress={() => {}} //TODO: we could mark all visible checkboxes
          variant={'withBorder'}
          placeholder={t('SearchDetailsPage.PatentFilters.searchPlaceholder')}
          onFocus={() => setIsOpen(true)}
        />

        <div className={`${styles.arrowWrapper} ${isOpen ? styles.clicked : ''}`}>
          <Svg
            SvgImage={isOpen ? CaretUp : CaretDown}
            dataTestId='country-selector-arrow'
            variants={['rightCorner', 'grey', 'verySmall']}
            alt={t(`GLOBAL.${isOpen ? 'shrinkList' : 'extendList'}`)}
            onClick={() => setIsOpen(prev => !prev)}
          />
        </div>

        {isOpen && (
          <ul>
            <div className={styles.dropdownRowSelectAll}>
              <Checkbox
                checked={checkItems.length === values.length}
                dataTestId='country-selector-select-all'
                variants={['fontSmall']}
                onChange={toggleAll}
              >
                {t('SearchDetailsPage.PatentFilters.CountrySelector.selectAll')}
              </Checkbox>
            </div>

            {checkItems.map(
              item =>
                doesContainItem(item.name) && (
                  <li key={item.name}>
                    <Checkbox
                      checked={shouldBeChecked(item)}
                      dataTestId={'country-selector-select-' + item.name}
                      onChange={ev => toggleItem(ev, item.value)}
                      variants={['fontSmall']}
                      disabled={!initialValuesRef.current?.includes(item.value)}
                    >
                      {getCountryNameTranslated(item.name)}
                    </Checkbox>
                  </li>
                )
            )}
          </ul>
        )}
      </Background>
    </div>
  )
}

CountrySelector.propTypes = {
  values: PropTypes.array,
  onFilterChange: PropTypes.func.isRequired,
  initialValues: PropTypes.array,
  onOutOfBoundary: PropTypes.func,
}

CountrySelector.defaultProps = {
  values: [],
  initialValues: [],
}

export default CountrySelector
