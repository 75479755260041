import moment from 'moment'

export const formatRank = rank => (rank === 100 ? rank.toFixed(1) : rank.toFixed(2))

export const formatPubDate = dateString => {
  const parsed = moment(dateString)

  return parsed.isValid() ? parsed.format('DD/MM/YYYY') : ''
}

export const trimTitle = title => {
  if (!title?.trim()) return

  const maxLength = 200
  if (title?.length > maxLength) return `${title.substr(0, maxLength - 3)}...`
  return title
}
