import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ConfirmDialog from 'components/ConfirmDialog'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Svg from 'components/Svg'
import { ReactComponent as Spinner } from 'assets/images/spinner-animated.svg'
import style from './autoupdater.module.scss'

import {
  checkNeedsUpdate,
  newReleaseAvailableSelector,
  updateDesktopApp,
  updateStatusSelector,
  DESKTOP_UPDATE_STATUS,
} from 'store/Updater'

function Autoupdater() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const newReleaseAvailable = useSelector(newReleaseAvailableSelector)
  const desktopUpdateStatus = useSelector(updateStatusSelector)

  const [showConfirmDialog, setshowConfirmDialog] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)

  const onDismiss = () => setShowModal(false)

  const showMessage = () =>
    Object.prototype.hasOwnProperty.call(DESKTOP_UPDATE_STATUS, desktopUpdateStatus)
      ? t(`Autoupdater.${desktopUpdateStatus}`)
      : ''

  useEffect(() => {
    dispatch(checkNeedsUpdate())
  }, [dispatch])

  useEffect(() => {
    if (newReleaseAvailable === DESKTOP_UPDATE_STATUS.newReleaseAvailable) {
      setshowConfirmDialog(true)
    }
  }, [newReleaseAvailable])

  useEffect(() => {
    const shouldShowModal = Object.prototype.hasOwnProperty.call(
      DESKTOP_UPDATE_STATUS,
      desktopUpdateStatus
    )
    setShowModal(shouldShowModal)
  }, [desktopUpdateStatus, t])

  return (
    <div className={style.base}>
      {showConfirmDialog && (
        <ConfirmDialog
          onConfirm={() => {
            dispatch(updateDesktopApp({}))
          }}
          onCancel={() => setshowConfirmDialog(false)}
          message={t('Autoupdater.newRelease')}
        />
      )}
      {showModal && (
        <Modal className={style.messageContainer} message={showMessage()}>
          <div>
            {(desktopUpdateStatus === DESKTOP_UPDATE_STATUS.downloading ||
              desktopUpdateStatus === DESKTOP_UPDATE_STATUS.installing) && (
              <div className={style.spinner}>
                <Svg SvgImage={Spinner} alt={t('GLOBAL.spinnerAlt')} dataTestId='spinner' />
              </div>
            )}
            <Button onClick={onDismiss}>{t('Autoupdater.close')}</Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default Autoupdater
