import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Svg from 'components/Svg'

import { ReactComponent as CheckMark } from 'assets/images/check-circle.svg'

import style from './passwordRequirements.module.scss'

function PasswordRequirements({ password, passwordsMatch }) {
  const [passwordValidation, setPasswordValidation] = React.useState({})

  const { t } = useTranslation()

  useEffect(() => {
    const validation = {
      length: password.length >= 8,
      number: /[0-9]/g.test(password),
      specialChar: /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/g.test(password),
      uppercase: /[A-Z]/g.test(password),
      lowercase: /[a-z]/g.test(password),
      matching: passwordsMatch,
    }

    setPasswordValidation(validation)
  }, [password, passwordsMatch])

  const checkMarkClasses = ['small', 'indicator', 'noMargin']

  return (
    <div className={style.base}>
      <div className={style.reqsColumn}>
        <Svg
          SvgImage={CheckMark}
          alt={t('ForgotPassword.passwordReqs.length')}
          variants={[...checkMarkClasses, passwordValidation.length ? 'active' : '']}
        />

        <Svg
          SvgImage={CheckMark}
          alt={t('ForgotPassword.passwordReqs.number')}
          variants={[...checkMarkClasses, passwordValidation.number ? 'active' : '']}
        />

        <Svg
          SvgImage={CheckMark}
          alt={t('ForgotPassword.passwordReqs.specialChar')}
          variants={[...checkMarkClasses, passwordValidation.specialChar ? 'active' : '']}
        />

        <Svg
          SvgImage={CheckMark}
          alt={t('ForgotPassword.passwordReqs.uppercase')}
          variants={[...checkMarkClasses, passwordValidation.uppercase ? 'active' : '']}
        />

        <Svg
          SvgImage={CheckMark}
          alt={t('ForgotPassword.passwordReqs.lowercase')}
          variants={[...checkMarkClasses, passwordValidation.lowercase ? 'active' : '']}
        />

        <Svg
          SvgImage={CheckMark}
          alt={t('ForgotPassword.passwordReqs.matching')}
          variants={[...checkMarkClasses, passwordValidation.matching ? 'active' : '']}
        />
      </div>

      <div className={style.reqsColumn}>
        <span>{t('ForgotPassword.passwordReqs.length')}</span>
        <span>{t('ForgotPassword.passwordReqs.number')}</span>
        <span>{t('ForgotPassword.passwordReqs.specialChar')}</span>
        <span>{t('ForgotPassword.passwordReqs.uppercase')}</span>
        <span>{t('ForgotPassword.passwordReqs.lowercase')}</span>
        <span>{t('ForgotPassword.passwordReqs.matching')}</span>
      </div>
    </div>
  )
}

export default PasswordRequirements
