import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'

import 'react-day-picker/lib/style.css'

import YearMonthForm from './components/YearMonthForm'

import styles from './datePicker.module.scss'
import Button from 'components/Button'

const currentYear = new Date().getFullYear()
const maxYearsBack = currentYear - 1899
const fromMonth = new Date(currentYear - maxYearsBack + 1, 0)
const toMonth = new Date(currentYear, new Date().getMonth())

function DatePicker({
  value,
  name,
  onDateChange,
  variant,
  dataTestId,
  isLocked,
  disableBeforeDate,
  disableAfterDate,
}) {
  const [month, setMonth] = React.useState(new Date())

  const { t } = useTranslation()

  const dateParseOrEmptyString = dateOrString => {
    return dateOrString !== null && dateOrString.length > 0 ? new Date(dateOrString) : ''
  }

  const handleYearMonthChange = selectedMonth => setMonth(selectedMonth)

  return (
    <div className={`${styles.base} ${styles[variant]}`} data-testid={dataTestId}>
      <DayPickerInput
        inputProps={{ readOnly: true }}
        dayPickerProps={{
          todayButton: t('DayPicker.today'),
          disabledDays: {
            before: isLocked ? dateParseOrEmptyString(disableBeforeDate) : '',
            after: isLocked ? dateParseOrEmptyString(disableAfterDate) : new Date(),
          },
          firstDayOfWeek: 1,
          modifiers: { start: value },
          month,
          fromMonth,
          toMonth,
          captionElement: ({ date, localeUtils }) => (
            <YearMonthForm
              date={date}
              localeUtils={localeUtils}
              maxYearsBack={maxYearsBack}
              onChange={handleYearMonthChange}
            />
          ),
        }}
        format='D MMM yyyy'
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={name}
        onDayChange={day => {
          onDateChange(day)
        }}
        keepFocus={false}
        value={dateParseOrEmptyString(value)}
      />

      {(!isLocked || value) && (
        <Button onClick={() => onDateChange('')} variant={'removeDateAction'}>
          x
        </Button>
      )}
    </div>
  )
}

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
  isLocked: PropTypes.bool,
  disableBeforeDate: PropTypes.string,
  disableAfterDate: PropTypes.string,
}

DatePicker.defaultProps = {
  value: '',
  name: '',
  variant: '',
  isLocked: false,
  disableBeforeDate: '',
  disableAfterDate: '',
}
export default DatePicker
