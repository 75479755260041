import { takeLatest, call, put, select, takeEvery } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'
import { ENDPOINTS, post } from 'services/api-v1'

import { incrementStatusCreator, decrementStatusCreator, STATUS_STATES } from 'store/Status'
import {
  saveToLocalStore,
  loadFromLocalStore,
  removeFromLocalStore,
  STORAGE_ITEMS,
} from 'store/localStorage'
import { paths } from 'router/paths'
import { tryCatchWrapper } from 'store/wrappers'

// Constants
export const SIGN_IN_USER = 'account/SIGN_IN_USER'
export const SIGN_IN_FROM_LOCAL_STORE = 'account/SIGN_IN_FROM_LOCAL_STORE'
export const SIGN_UP_USER = 'account/SIGN_UP_USER'
export const CONFIRM_SIGN_UP = 'account/CONFIRM_SIGN_UP'
export const RESEND_SIGN_UP = 'account/RESEND_SIGN_UP'

export const SIGN_OUT_USER = 'account/SIGN_OUT_USER'
export const SIGN_OUT = 'account/SIGN_OUT'
export const UPDATE_CACHE = 'account/UPDATE_CACHE'
export const UPDATE_CACHE_SET = 'account/UPDATE_CACHE_SET'
export const SEND_PASSWORD_REMINDER = 'account/SEND_PASSWORD_REMINDER'
export const SEND_PASSWORD_REMINDER_CONFIRMATION = 'account/SEND_PASSWORD_REMINDER_CONFIRMATION'

export const REDIRECT = 'account/REDIRECT'

// Constants - errors
export const USER_ERROR = 'account/USER_ERROR'
export const REMOVE_USER_ERROR = 'account/REMOVE_USER_ERROR'

// Constants - error types
export const ERROR_TYPE = {
  signIn: {
    default: 'SignInException',
    userNotConfirmed: 'UserNotConfirmedException',
    wrongUsernamePassword: 'NotAuthorizedException',
  },
  signUp: {
    default: 'SignUpException',
    usernameExists: 'UsernameExistsException',
    invalidPassword: 'InvalidPasswordException',
    invalidParameter: 'InvalidParameterException',
  },
  signUpConfirmation: {
    default: 'SignUpConfirmationException',
    codeExpired: 'ExpiredCodeException',
    codeMismatch: 'CodeMismatchException',
  },
  signUpConfirmationCodeResend: {
    default: 'SignUpConfirmationCodeResendException',
    limitExceeded: 'LimitExceededException',
  },
  signOut: 'signOut',
  forgotPassword: {
    default: 'ForgotPasswordException',
    limitExceeded: 'LimitExceededException',
    invalidParameter: 'InvalidParameterException',
    userNotConfirmed: 'UserNotConfirmedException',
  },
  forgotPasswordConfirmation: {
    default: 'forgotPasswordConfirmationException',
    passwordMismatch: 'PasswordsDontMatchException',
    confirmPassword: 'ConfirmPasswordException',
    invalidPassword: 'InvalidPasswordException',
    limitExceeded: 'LimitExceededException',
    invalidParameter: 'InvalidParameterException',
    codeMismatch: 'CodeMismatchException',
    codeExpired: 'ExpiredCodeException',
  },
}

// Action Creators
export const signInUser = payload => ({ type: SIGN_IN_USER, payload })
export const signInWithLocalData = () => ({ type: SIGN_IN_FROM_LOCAL_STORE })
export const signUpUser = payload => ({ type: SIGN_UP_USER, payload })
export const resendSignUpUser = payload => ({ type: RESEND_SIGN_UP, payload })
export const signUpConfirmation = payload => ({ type: CONFIRM_SIGN_UP, payload })
export const signOutUser = () => ({ type: SIGN_OUT_USER })
export const sendPasswordReminder = payload => ({ type: SEND_PASSWORD_REMINDER, payload })
export const sendPasswordReminderConfirmation = payload => ({
  type: SEND_PASSWORD_REMINDER_CONFIRMATION,
  payload,
})
export const redirect = payload => ({ type: REDIRECT, payload })

// Action Creators - errors
export const userError = (error, type) => ({
  type: USER_ERROR,
  error: { ...error, type },
})

// Selectors
export const userDataSelector = state => state.Account.user
export const redirectSelector = state => state.Account.redirect

export const allErrorsSelector = state => state.Account.errors

export const errorSelector = (state, type) => {
  const error = state.Account.errors

  if (error.type === type) return error
  return false
}

// Sagas
export default function* sagawatcher() {
  yield takeLatest(SIGN_OUT_USER, signOut)
  yield takeLatest(SEND_PASSWORD_REMINDER, forgotPassword)
  yield takeLatest(SEND_PASSWORD_REMINDER_CONFIRMATION, forgotPasswordConfirmation)
  yield takeLatest(SIGN_IN_USER, signIn)
  yield takeLatest(SIGN_IN_FROM_LOCAL_STORE, signInFromLocalStore)
  yield takeLatest(SIGN_UP_USER, signUp)
  yield takeLatest(RESEND_SIGN_UP, resendSignUp)
  yield takeLatest(CONFIRM_SIGN_UP, confirmSignUp)

  yield takeEvery(UPDATE_CACHE, updateCacheSaga)
}

function* signOut() {
  const isDesktopApp = Boolean(window && window.nw)
  try {
    yield Auth.signOut({ global: true })
  } catch (error) {
    yield put(userError(error, ERROR_TYPE.signOut))
  } finally {
    removeFromLocalStore(STORAGE_ITEMS.user)
    if (isDesktopApp) {
      window.location.href = window.location.origin + '/build/index.html'
    } else {
      window.location.reload()
    }
  }
}

function* forgotPassword({ payload: { username, statusRef } }) {
  function* sendRequest() {
    yield Auth.forgotPassword(username)
  }

  yield tryCatchWrapper('password reminder', sendRequest, statusRef)
}

function* forgotPasswordConfirmation({ payload: { username, code, newPassword, statusRef } }) {
  function* sendRequest() {
    yield Auth.forgotPasswordSubmit(username, code, newPassword)
  }

  yield tryCatchWrapper('password confirmation', sendRequest, statusRef)
}

function* signIn({
  payload: { username, password, statusRef, retryWithLowerCaseUsername = false },
}) {
  let payload

  yield put(
    incrementStatusCreator({
      statusRef,
      message: 'Sign in started',
    })
  )

  const body = {
    email: username,
    password: password,
  }

  try {
    const response = yield call(post, ENDPOINTS.login, body)

    payload = {
      username: response.data.email,
      userDisplayName: response.data.firstName + ' ' + response.data.lastName,
      token: response.data.token,
      refreshToken: response.data.refreshToken,
      authenticated: true, //necessary?
      confirmed: true, //?
      email_verified: true, //TODO  //??
    }

    saveToLocalStore(STORAGE_ITEMS.user, payload)
    yield put({ type: UPDATE_CACHE, payload })
    yield put({ type: REDIRECT, payload: { location: '' } })
    yield put(
      decrementStatusCreator({
        statusRef,
        message: 'Sign in finished',
      })
    )
  } catch (error) {
    if (error.code === ERROR_TYPE.userNotConfirmed) {
      payload = {
        id: 0,
        username,
        confirmed: false,
        authenticated: true,
      }

      yield put({ type: UPDATE_CACHE, payload })
    }

    yield put(
      decrementStatusCreator({
        statusRef,
        message: `Sign in - ${error.message}`,
        state: STATUS_STATES.ERROR,
        data: error.code,
      })
    )
  }
}

function* signInFromLocalStore() {
  const user = loadFromLocalStore(STORAGE_ITEMS.user)

  let payload

  if (user) {
    try {
      //yield Auth.currentAuthenticatedUser() //confirm user is still signedin locally
      const response = yield call(post, ENDPOINTS.refreshToken, {
        refreshToken: user.refreshToken,
      })
    } catch (e) {
      removeFromLocalStore(STORAGE_ITEMS.user)
      window.location.reload()
    }

    payload = {
      username: user.username,
      id: user.id,
      authenticated: user.authenticated,
      confirmed: user.confirmed,
      userDisplayName: user.userDisplayName,
    }

    yield put({ type: UPDATE_CACHE, payload })
  } else {
    yield put({ type: REDIRECT, payload: { location: paths.logIn } })

    console.log('User not found in local storage')
  }
}

function* signUp({
  payload: { username, password, givenName, familyName, statusRef, termsAccepted },
}) {
  const requestBody = {
    username,
    password,
    attributes: {
      given_name: givenName,
      family_name: familyName,
      // We use a number for the T&C as Cognito attributes have only string and number types.
      // Additionally, we can only send over a string value.
      'custom:terms_and_conditions': termsAccepted ? '1' : '0',
    },
  }

  function* sendRequest() {
    const response = yield Auth.signUp(requestBody)

    return {
      username: response.user.username,
      id: response.userSub,
      authenticated: false,
      confirmed: response.userConfirmed,
    }
  }

  const payload = yield tryCatchWrapper('sign up', sendRequest, statusRef)

  yield put({ type: UPDATE_CACHE, payload })
}

function* confirmSignUp({ payload: { username, code, statusRef } }) {
  function* sendRequest() {
    yield Auth.confirmSignUp(username, code)

    yield put({ type: SIGN_OUT })
  }

  yield tryCatchWrapper('sign up confirmation', sendRequest, statusRef)
}

function* resendSignUp({ payload: { username, statusRef } }) {
  function* sendRequest() {
    yield Auth.resendSignUp(username)
  }

  yield tryCatchWrapper('sign up confirmation resend', sendRequest, statusRef)
}

function* updateCacheSaga(data) {
  if (!data || !data.payload || data.payload.length === 0) return

  const errors = yield select(allErrorsSelector)

  //Clean errors as correct data is beign saved at this point
  if (Object.entries(errors).length !== 0) yield put({ type: REMOVE_USER_ERROR })

  const currentUserData = yield select(userDataSelector)
  const payload = Object.keys(currentUserData).length === 0 ? data.payload : currentUserData

  yield put({ type: UPDATE_CACHE_SET, payload })
}

/*************************************************/
/** Reducer **/
const initialState = {
  user: {},
  errors: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CACHE_SET:
      return {
        ...state,
        user: action.payload,
      }

    case SIGN_OUT:
      return {
        ...state,
        user: {},
      }

    case USER_ERROR:
      return {
        ...state,
        errors: action.error,
      }

    case REMOVE_USER_ERROR:
      return {
        ...state,
        errors: {},
      }

    case REDIRECT:
      return {
        ...state,
        redirect: action.payload.location,
      }

    default:
      return state
  }
}
