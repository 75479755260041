import React from 'react'
import { Switch, Route } from 'react-router-dom'

import PrivateRoute from './privateRoute'
import { paths } from './paths'

import LogIn from 'containers/Auth/LogIn'
import SignUp from 'containers/Auth/SignUp'
import SignUpConfirmation from 'containers/Auth/SignUpConfirmation'
import ForgotPassword from 'containers/Auth/ForgotPassword'
import ForgotPasswordConfirmation from 'containers/Auth/ForgotPasswordConfirmation'
import SearchPage from 'containers/Search/SearchPage'
import SearchesPage from 'containers/Search/SearchesPage'
import SearchDetailsPage from 'containers/Search/SearchDetailsPage' // eslint-disable-line import/no-named-as-default
import PatentPage from 'containers/Search/PatentPage'
import SignUpConfirmationCodeResend from 'containers/Auth/SignUpConfirmationCodeResend'
import PageNotFound from 'components/PageNotFound'
import PasswordReset from 'containers/Auth/PasswordReset'

export const Routes = ({ areProjectsOpen }) => (
  <>
    <Switch>
      <Route exact path='/'>
        <LogIn />
      </Route>

      <Route path={paths.logIn}>
        <LogIn />
      </Route>

      <Route exact path={paths.signUp}>
        <SignUp />
      </Route>

      <Route exact path={paths.signUpConfirmation}>
        <SignUpConfirmation />
      </Route>

      <Route exact path={paths.signUpConfirmationCodeResend}>
        <SignUpConfirmationCodeResend />
      </Route>

      <Route exact path={paths.forgotPassword}>
        <ForgotPassword />
      </Route>

      <Route exact path={paths.forgotPasswordConfirmation}>
        <ForgotPasswordConfirmation />
      </Route>

      <PrivateRoute path={paths.searchPage}>
        <SearchPage />
      </PrivateRoute>

      <PrivateRoute exact path={paths.searches}>
        <SearchesPage />
      </PrivateRoute>

      <PrivateRoute exact path={paths.archivedSearches}>
        <SearchesPage />
      </PrivateRoute>

      <PrivateRoute path={`${paths.searches}/:id`}>
        <SearchDetailsPage areProjectsOpen={areProjectsOpen} />
      </PrivateRoute>

      <PrivateRoute path={`${paths.patentView}/:id`}>
        <PatentPage areProjectsOpen={areProjectsOpen} />
      </PrivateRoute>

      <PrivateRoute path={paths.passwordReset}>
        <PasswordReset />
      </PrivateRoute>

      <PrivateRoute>
        <PageNotFound />
      </PrivateRoute>
    </Switch>
  </>
)
