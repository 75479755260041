import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { STATUS_STATES } from 'store/Status'

import Svg from 'components/Svg'
import { ReactComponent as Spinner } from 'assets/images/spinner-animated.svg'

import styles from './button.module.scss'

function Button({
  children,
  dataTestId,
  onClick,
  type = 'button',
  variant,
  variants,
  disabled = false,
  className,
  status,
}) {
  const { t } = useTranslation()

  return (
    <button
      data-testid={dataTestId}
      className={`${styles.base} ${className ? styles[className] : ''} ${
        styles[variant]
      } ${variants.map(v => styles[v]).join(' ')}`}
      type={type} // eslint-disable-line react/button-has-type
      onClick={onClick}
      disabled={disabled || status.state === STATUS_STATES.BUSY}
    >
      {status.state === STATUS_STATES.BUSY && (
        <Svg
          SvgImage={Spinner}
          alt={t('GLOBAL.spinnerAlt')}
          dataTestId='spinner'
          variants={['loader', 'buttonBusy']}
        />
      )}

      {children}
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  onClick: PropTypes.func,
  variant: PropTypes.string,
  variants: PropTypes.array,
  status: PropTypes.object,
  dataTestId: PropTypes.string,
}

Button.defaultProps = {
  status: {},
  variants: [],
  dataTestId: 'button',
}

export default Button
