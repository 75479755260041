import { takeEvery, put, call, select, takeLatest, all } from 'redux-saga/effects'
import moment from 'moment'

import { ENDPOINTS, get, post, deleteIt } from 'services/api-v1'

import { tryCatchWrapper } from 'store/wrappers'

import { saveAsCsv } from 'containers/fileExporter'

// Constants
const GET_PPROJECT = 'project/GET_PPROJECT'
const GET_PPROJECTS = 'project/GET_PPROJECTS'
const NEW_PROJECT = 'project/NEW_PROJECT'
const EDIT_PROJECT = 'project/EDIT_PROJECT'
const DELETE_PROJECT = 'project/DELETE_PROJECT'
const REMOVE_PROJECT_FROM_STORE = 'project/REMOVE_PROJECT_FROM_STORE'

const DOWNLOAD_PATENTS = 'project/DOWNLOAD_PATENTS'

const ADD_PATENT_TO_PROJECT = 'project/ADD_PATENT_TO_PROJECT'
const EDIT_PATENT = 'project/EDIT_PATENT'
const DELETE_PATENT_FROM_PROJECT = 'project/DELETE_PATENT_FROM_PROJECT'

const UPDATE_CACHE_PROJECTS = 'project/UPDATE_CACHE_PROJECTS'

// Action Creators
export const getProjectDetails = payload => ({ type: GET_PPROJECT, payload })
export const getProjectList = payload => ({ type: GET_PPROJECTS, payload })
export const newProject = payload => ({ type: NEW_PROJECT, payload })
export const editProject = payload => ({ type: EDIT_PROJECT, payload })
export const deleteProject = payload => ({ type: DELETE_PROJECT, payload })
export const addPatentToProject = payload => ({ type: ADD_PATENT_TO_PROJECT, payload })
export const editPatent = payload => ({ type: EDIT_PATENT, payload })
export const deletePatentFromProject = payload => ({ type: DELETE_PATENT_FROM_PROJECT, payload })
export const downloadPatents = payload => ({ type: DOWNLOAD_PATENTS, payload })

// Selectors
export const projectListSelector = state => state.Project.projects
export const projectDetailsSelector = (state, id) => state.Project.projects[id]

// Sagas
export default function* sagawatcher() {
  yield takeEvery(GET_PPROJECT, getProject)
  yield takeEvery(GET_PPROJECTS, getProjects)
  yield takeLatest(NEW_PROJECT, createProject)
  yield takeLatest(EDIT_PROJECT, updateProject)
  yield takeLatest(DELETE_PROJECT, removeProject)
  yield takeLatest(ADD_PATENT_TO_PROJECT, addPatent)
  yield takeLatest(EDIT_PATENT, updatePatent)
  yield takeLatest(DELETE_PATENT_FROM_PROJECT, removePatent)
  yield takeLatest(DOWNLOAD_PATENTS, download)
}

function* getProject({ payload: { id, shouldIncludePatents, statusRef } }) {
  if (!id) return

  function* sendRequest() {
    const url = `${ENDPOINTS.project}/${id}?${shouldIncludePatents ? 'embed=true' : ''}` //TODO on BE
    const result = yield call(get, url)

    const patents = call(get, ENDPOINTS.projectPatents(result.data.ID))

    return { [result.data.ID]: { ...result.data, patents } }
  }

  const payload = yield tryCatchWrapper(`getting project details for ${id}`, sendRequest, statusRef)

  yield put({ type: UPDATE_CACHE_PROJECTS, payload })
}

export function* getProjects({ payload: { shouldIncludePatents, statusRef } }) {
  function* sendRequest() {
    // const url = `${ENDPOINTS.project}?${shouldIncludePatents ? 'embed=true' : ''}` //TODO on BE
    const url = ENDPOINTS.project
    const result = yield call(get, url)

    const patentsRequests = result.data.map(project =>
      call(get, ENDPOINTS.projectPatents(project.ID))
    )

    const resultPatents = yield all(patentsRequests)

    return result.data.reduce((projects, project) => {
      let patents = []
      resultPatents.forEach(projectPatents => {
        const id = projectPatents.data[0]?.projectID
        if (id && id === project.ID) patents = patents.concat(projectPatents.data)
      })

      return {
        ...projects,
        [project.ID]: { ...project, patents: patents.sort((a, b) => a.position - b.position) },
      }
    }, {})
  }

  const payload = yield call(tryCatchWrapper, `getting projects list`, sendRequest, statusRef)

  yield put({ type: UPDATE_CACHE_PROJECTS, payload })
}

function* createProject({ payload: { name, statusRef } }) {
  if (!name) return

  // eslint-disable-next-line require-yield
  function* sendRequest() {
    const requestBody = { name }
    const result = yield call(post, ENDPOINTS.project, requestBody)

    return { [result.data.ID]: result.data }
  }

  const payload = yield tryCatchWrapper(`creating new project`, sendRequest, statusRef)

  yield put({ type: UPDATE_CACHE_PROJECTS, payload })
}

//TODO: split `data` for validation
function* updateProject({ payload: { data, statusRef } }) {
  if (!data) return

  const projects = yield select(projectListSelector)
  const project = projects[data.id]

  function* sendRequest() {
    const requestBody = { ...data }
    const result = yield call(post, ENDPOINTS.singleProject(data.id), requestBody)

    return { [data.id]: { ...result.data, patents: project.patents } }
  }

  const payload = yield tryCatchWrapper(`data update - ${data.id}`, sendRequest, statusRef)

  yield put({ type: UPDATE_CACHE_PROJECTS, payload })
}

function* removeProject({ payload: { id, statusRef } }) {
  if (!id) return

  function* sendRequest() {
    const result = yield call(deleteIt, ENDPOINTS.singleProject(id))

    return result.statusCode //TODO
  }

  // if status code 200, delete the project
  // else retry/show error

  yield tryCatchWrapper(`deleting project - ${id}`, sendRequest, statusRef)

  yield put({ type: REMOVE_PROJECT_FROM_STORE, payload: { id } })
}

function* addPatent({ payload: { name, patentNum, projectId, statusRef } }) {
  if (!name || !patentNum || !projectId) return

  const projects = yield select(projectListSelector)
  const project = projects[projectId]

  function* sendRequest() {
    const requestBody = {
      name,
      patentNum,
    }
    const result = yield call(post, ENDPOINTS.projectPatents(projectId), requestBody)

    const patents = project.patents || []

    return { [projectId]: { ...project, patents: [...patents, result.data] } }
  }

  const payload = yield tryCatchWrapper(`adding patent ${patentNum}`, sendRequest, statusRef)

  yield put({ type: UPDATE_CACHE_PROJECTS, payload })
}

//TODO: Split `data` for validation
function* updatePatent({ payload: { data, projectId, statusRef } }) {
  if (!data || !projectId) return

  const projects = yield select(projectListSelector)
  const project = projects[projectId]

  function* sendRequest() {
    const result = yield call(post, ENDPOINTS.projectPatent(projectId, data.id), {
      position: data.position,
    })

    return {
      [projectId]: {
        ...project,
        patents: project.patents.map(patent => {
          if (patent.ID === data.id) return { ...result.data }
          return patent
        }),
      },
    }
  }

  const payload = yield tryCatchWrapper(`updating patent ${data.id}`, sendRequest, statusRef)

  yield put({ type: UPDATE_CACHE_PROJECTS, payload })
}

function* removePatent({ payload: { id, projectId, statusRef } }) {
  if (!id || !projectId) return

  const projects = yield select(projectListSelector)
  const currentProject = { ...projects[projectId] }
  const patentNum = currentProject.patents.find(patent => patent.ID === id)
  const patentsUpdated = currentProject.patents.filter(patent => patent.ID !== id)

  function* sendRequest() {
    yield call(deleteIt, ENDPOINTS.projectPatent(projectId, id))

    // if result.statusCode === 200, delete
    // else error
    // think where to show it, probably in the parent saga

    return {
      [projectId]: {
        ...currentProject,
        patents: patentsUpdated,
      },
    }
  }

  const payload = yield tryCatchWrapper(`removing patent ${patentNum}`, sendRequest, statusRef)

  yield put({ type: UPDATE_CACHE_PROJECTS, payload })
}

function* download({ payload: { projectID, statusRef } }) {
  function* sendRequest() {
    if (projectID) return yield call(get, ENDPOINTS.downloadProjectPatents(projectID))
    return yield call(get, ENDPOINTS.downloadAllPatents)
  }

  const result = yield tryCatchWrapper(
    `downloading patents for ${projectID || 'all projects'}`,
    sendRequest,
    statusRef
  )

  const project = yield select(state => projectDetailsSelector(state, projectID))

  const date = moment(new Date()).format('yyyy-MM-DD')
  const filename = projectID ? `${project.name}_${date}` : `projects_${date}`
  saveAsCsv(result.data, filename)
}

/*************************************************/
/** Reducer **/
const initialState = {
  projects: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CACHE_PROJECTS:
      return {
        ...state,
        projects: { ...state.projects, ...action.payload },
      }

    case REMOVE_PROJECT_FROM_STORE:
      return {
        ...state,
        projects: Object.keys(state.projects)
          .map(key => key !== action.payload.id && { [key]: state.projects[key] })
          .reduce((previous, current) => ({ ...previous, ...current }), {}),
      }

    default:
      return state
  }
}
