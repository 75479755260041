import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  getPatentDocument,
  patentsSelector,
  DOCUMENT_TYPE,
  getPatentDocumentPdf,
  getPatentDocumentImages,
  imagesSelector,
} from 'store/Document'
import { STATUS_STATES } from 'store/Status'
import {
  getSearchDetails,
  specificSearch,
  specificPreprocessed,
  updatePreprocessedList,
  preprocess,
  removePreprocessed,
} from 'store/Search'
import { SEARCH_STATUS } from 'store/Search/constants'
import { createSearchId, generateFile } from 'containers/Search/utils'
import { elementStateSelector, PATENT_UI_ELEMENTS } from 'store/Interface'

import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import Bibliography from 'components/search/Bibliography'
import CitationsList from 'components/search/CitationsList'
import Images from 'components/search/Images'
import Patent from 'components/search/Patent'
import ScrollToTop from 'components/search/ScrollToTop'
import Svg from 'components/Svg'
import { ReactComponent as PdfIcon } from 'assets/images/pdf.svg'
import { ReactComponent as CaretUp } from 'assets/images/caret-up.svg'
import { ReactComponent as CaretDown } from 'assets/images/caret-down.svg'

import { paths } from 'router/paths'

import styles from './patentPage.module.scss'

const documentDataRetrieveRef = 'PatentPage_retrievePatent'
const preprocessRef = 'PatentPage_preprocessPatent'
const searchDetailsRetrieveRef = 'PatentPage_retrieveSearch'
const imageLoadStatusRef = 'PatentPage_imageLoad'

const patentPreprocessedId = createSearchId()

const PATENT_SECTIONS = {
  abstract: 'abstract',
  description: 'description',
  claims: 'claims',
}

const PATENT_PAGE_UI_NAME = 'patentPageUiState'

function PatentPage({ areProjectsOpen }) {
  const { id } = useParams()
  const { t } = useTranslation()

  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const [matchTerms, setMatchTerms] = React.useState([])
  const [termsExtended, setTermsExtended] = React.useState(false)
  const [areTermsOverflowing, setAreTermsOverflowing] = React.useState(false)
  const [includeInSearch, setIncludeInSearch] = React.useState({
    abstract: false,
    description: false,
    claims: false,
  })
  const [isImageBusy, setIsImageBusy] = React.useState(false)

  const matchTermsRef = React.useRef()

  const allPatents = useSelector(patentsSelector)
  const selectedPatentDoc = allPatents[id]
  const allImages = useSelector(imagesSelector)
  const selectedPatentImage = allImages[id]

  const preprocessedPatent = useSelector(state => specificPreprocessed(state, patentPreprocessedId))

  const infoBoxesExpandStates =
    useSelector(state => elementStateSelector(state, PATENT_PAGE_UI_NAME)) || {}

  const query = new URLSearchParams(location.search)
  const searchId = query.get('searchId') || ''
  const search = useSelector(state => specificSearch(state, searchId))

  const documentDataRetrieveStatus =
    useSelector(state => state.Status.statuses[documentDataRetrieveRef]) || {}

  const imageLoadStatus = useSelector(state => state.Status.statuses[imageLoadStatusRef]) || {}

  const onPdfDownload = patentNum =>
    dispatch(
      getPatentDocumentPdf({
        docType: DOCUMENT_TYPE.patent,
        patentNum,
      })
    )

  const onNewSearchBasedOnSections = () => {
    const cleanUp = section => {
      return section
        ? section
            .replace(/(<claim.*?[>])/gi, '\n')
            .replace(/(<p>)/gi, '\n')
            .replace(/(<[^>]+>)/gi, '')
            .trim()
        : ''
    }

    const abstract = (includeInSearch.abstract && cleanUp(selectedPatentDoc?.abstract)) || ''
    const description =
      (includeInSearch.description && cleanUp(selectedPatentDoc?.description)) || ''
    const claims = (includeInSearch.claims && cleanUp(selectedPatentDoc?.claims)) || ''

    const newSearchId = createSearchId(id + '-patent')
    const text = abstract + description + claims

    dispatch(removePreprocessed({ id: patentPreprocessedId }))

    dispatch(
      updatePreprocessedList({
        id: newSearchId,
        dataToUpdate: {
          filename: newSearchId,
          text,
          status: SEARCH_STATUS.Preparing,
        },
      })
    )
    history.push(paths.searchPage)
  }

  // onMount

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!areTermsOverflowing)
      setAreTermsOverflowing(
        matchTermsRef.current?.scrollHeight > matchTermsRef.current?.clientHeight
      )
  })

  useEffect(() => {
    dispatch(
      getPatentDocument({
        docType: DOCUMENT_TYPE.patent,
        docName: id,
        statusRef: documentDataRetrieveRef,
      })
    )
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(
      getPatentDocumentImages({
        docType: DOCUMENT_TYPE.patent,
        docID: id,
        statusRef: imageLoadStatusRef,
      })
    )
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    searchId && dispatch(getSearchDetails({ id: searchId, statusRef: searchDetailsRetrieveRef }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchId) {
      const text = selectedPatentDoc?.claims || ''
      if (selectedPatentDoc && text) {
        dispatch(
          preprocess({
            id: patentPreprocessedId,
            file: generateFile(selectedPatentDoc?.claims, patentPreprocessedId),
            searchName: patentPreprocessedId,
            inputText: selectedPatentDoc?.claims || '',
            statusRef: preprocessRef,
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatentDoc])

  useEffect(() => {
    const termMatches = []
    const patentTerms = preprocessedPatent?.terms

    if (searchId && patentTerms && search?.terms) {
      patentTerms.forEach(
        ({ key, value }) => search?.terms.includes(key) && termMatches.push({ [key]: value })
      )
    }

    termMatches.sort((a, b) => takeFirstValue(b) - takeFirstValue(a))

    setMatchTerms(termMatches)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preprocessedPatent, search])

  useEffect(() => {
    setIsImageBusy(imageLoadStatus.state === STATUS_STATES.BUSY)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageLoadStatus.state])

  if (documentDataRetrieveStatus.state === STATUS_STATES.NOT_FOUND)
    return (
      <div className={styles.error}>{t('SearchDetailsPage.notFoundMessage', { object: id })}</div>
    )
  if (documentDataRetrieveStatus.state === STATUS_STATES.ERROR)
    return (
      <div className={styles.error}>
        {t('SearchDetailsPage.errorMessage', { object: 'document data' })}
      </div>
    )

  const onSectionToggle = section => {
    setIncludeInSearch(prev => ({ ...prev, [section]: !prev[section] }))
  }

  const showTermsArrowIfNeeded = () => {
    const arrowVariants = ['inline', 'verySmall', 'grey']

    if (!areTermsOverflowing) return

    if (termsExtended)
      return (
        <Svg
          SvgImage={CaretUp}
          alt={t('GLOBAL.shrinkList')}
          dataTestId='caret-up'
          variants={arrowVariants}
          onClick={() => setTermsExtended(false)}
        />
      )

    return (
      <Svg
        SvgImage={CaretDown}
        alt={t('GLOBAL.extendList')}
        dataTestId='caret-down'
        variants={arrowVariants}
        onClick={() => setTermsExtended(true)}
      />
    )
  }

  const shouldInfoBoxBeOpen = name =>
    infoBoxesExpandStates[name] === undefined ? true : infoBoxesExpandStates[name]

  return (
    <div className={`${styles.base} ${areProjectsOpen ? '' : styles.projectsHidden}`}>
      <ScrollToTop areProjectsOpen={areProjectsOpen} />
      {selectedPatentDoc && (
        <>
          <div className={styles.topActions}>
            <div className={styles.leftTopPanel}>
              <div className={styles.patentTitle}>
                <div onClick={() => onPdfDownload(selectedPatentDoc.patentNum)}>
                  <Svg
                    SvgImage={PdfIcon}
                    dataTestId='pdf-download'
                    alt='pdf download'
                    variants={['iconSmaller', 'hoverBGLight', 'maxWidthNone']}
                  />
                </div>
                <p>{selectedPatentDoc.title}</p>
              </div>
              <div className={styles.newSearchSection}>
                <Button
                  onClick={onNewSearchBasedOnSections}
                  disabled={
                    !(
                      includeInSearch.abstract ||
                      includeInSearch.description ||
                      includeInSearch.claims
                    )
                  }
                >
                  {t('PatentPage.newSearchBasedOnClaims')}
                </Button>

                <div className={styles.searchOptions}>
                  <Checkbox
                    checked={includeInSearch.abstract}
                    onChange={() => onSectionToggle(PATENT_SECTIONS.abstract)}
                  >
                    {t('GLOBAL.patentSections.abstract')}
                  </Checkbox>
                  <Checkbox
                    checked={includeInSearch.description}
                    onChange={() => onSectionToggle(PATENT_SECTIONS.description)}
                  >
                    {t('GLOBAL.patentSections.description')}
                  </Checkbox>
                  <Checkbox
                    checked={includeInSearch.claims}
                    onChange={() => onSectionToggle(PATENT_SECTIONS.claims)}
                  >
                    {t('GLOBAL.patentSections.claims')}
                  </Checkbox>
                </div>
              </div>
            </div>

            {searchId && <div className={styles.divider}></div>}

            {searchId && (
              <>
                <div className={styles.matchTermsArea}>
                  <p>
                    {t('PatentPage.matchTermsTitle')} -{' '}
                    <Link to={`${paths.searches}/${searchId}`}>{search?.name}</Link>
                  </p>

                  <div
                    className={`${styles.matchTerms} ${termsExtended ? styles.extended : ''}`}
                    ref={matchTermsRef}
                  >
                    {matchTerms.length > 0 ? (
                      matchTerms.map(termObj => {
                        const [key, count] = takeFirst(termObj)
                        return (
                          <span
                            data-testid='patent-page-common-term'
                            key={key}
                            className={styles.term}
                          >
                            {`${key.replace('_', ' ')} (${count})`}
                          </span>
                        )
                      })
                    ) : (
                      <p>{t('PatentPage.noCommonTerms')}</p>
                    )}
                  </div>
                </div>
                <div className={styles.termsArrow}>{showTermsArrowIfNeeded()}</div>
              </>
            )}
          </div>

          <div className={styles.metadataTables}>
            <Bibliography
              result={selectedPatentDoc}
              isOpenInitially={shouldInfoBoxBeOpen(PATENT_UI_ELEMENTS.bibliography)}
              parentElementRef={PATENT_PAGE_UI_NAME}
            />

            <CitationsList
              result={selectedPatentDoc}
              isOpenInitially={shouldInfoBoxBeOpen(PATENT_UI_ELEMENTS.citations)}
              parentElementRef={PATENT_PAGE_UI_NAME}
            />

            {/* <Images
                  result={selectedPatentImage}
                  isOpenInitially={Boolean(
                    patentDetailBoxesExpandStates[PATENT_UI_ELEMENTS.images]
                  )}
                  isLoading={isImageBusy}
                  parentElementRef={PATENT_PAGE_UI_NAME} /> */}
          </div>
          <Patent patent={selectedPatentDoc} parentElementRef={PATENT_PAGE_UI_NAME} />
        </>
      )}
    </div>
  )
}

function takeFirstValue(obj) {
  return Object.values(obj)[0]
}

function takeFirst(obj) {
  return Object.entries(obj)[0]
}

export default PatentPage
