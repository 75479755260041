import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import DatePicker from 'components/DatePicker'

import styles from './dateRangeSelector.module.scss'
import Background from '../Background'

function DateRangeSelector({
  initialValues,
  title,
  valueStart,
  valueEnd,
  onStartChange,
  onEndChange,
  onOutOfBoundary,
}) {
  const { t } = useTranslation()

  const getDisableDate = date => date || ''

  //TODO: compare to initial valies like in Date picker below

  const getTime = date => new Date(date).getTime()

  return (
    <div className={styles.base}>
      <span>{title}</span>
      <Background>
        <div className={`${styles.datePickerContainer}`}>
          <DatePicker
            value={valueStart}
            onDateChange={day => {
              if (onOutOfBoundary) {
                const isOutOfBoundary = getTime(day) < getTime(initialValues.valueStart)
                onOutOfBoundary(isOutOfBoundary)
              }
              return onStartChange(day || null)
            }}
            name={t('Filters.dateStart')}
            disableBeforeDate={getDisableDate(initialValues.valueStart)}
            disableAfterDate={getDisableDate(initialValues.valueEnd)}
          />

          <DatePicker
            value={valueEnd}
            onDateChange={day => {
              if (onOutOfBoundary) {
                const isOutOfBoundary = getTime(day) > getTime(initialValues.valueEnd)
                onOutOfBoundary(isOutOfBoundary)
              }

              return onEndChange(day || null)
            }}
            name={t('Filters.dateEnd')}
            variant={'shiftLeft'}
            disableBeforeDate={getDisableDate(initialValues.valueStart)}
            disableAfterDate={getDisableDate(initialValues.valueEnd)}
          />
        </div>
      </Background>
    </div>
  )
}

DateRangeSelector.propTypes = {
  initialValues: PropTypes.object,
  title: PropTypes.string,
  valueStart: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  valueEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onStartChange: PropTypes.func.isRequired,
  onEndChange: PropTypes.func.isRequired,
  onOutOfBoundary: PropTypes.func,
}

DateRangeSelector.defaultProps = {
  initialValues: {},
}

export default DateRangeSelector
