import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { STATUS_STATES } from 'store/Status'

import ConfirmDialog from 'components/ConfirmDialog'
import Radio from 'components/RadioButton'
import Checkbox from 'components/Checkbox'
import Svg from 'components/Svg'
import { ReactComponent as Download } from 'assets/images/download.svg'
import { ReactComponent as Spinner } from 'assets/images/spinner-animated.svg'

import style from './ResultsExporter.module.scss'

const FORMAT = {
  CSV: 'csv',
  EXCEL: 'excel',
}

const EXPORT_LENGTHS = {
  first: '100',
  second: '250',
  third: '1000',
}

function ResultsExporter({ results, onExportDownload, downloadState }) {
  const { t } = useTranslation()

  const [showConfirmDialog, setshowConfirmDialog] = React.useState(false)
  const [checkedItems, setCheckedItems] = React.useState(['patentNum'])
  const [format, setFormat] = React.useState(FORMAT.CSV)
  const [numResults, setNumResults] = React.useState(EXPORT_LENGTHS.first)

  useEffect(() => {
    if (results.length > 0) setNumResults(null)
    // eslint-disable-next-line
  }, [showConfirmDialog])

  const COLUMNS = [
    {
      key: 'patentNum',
      name: t('SearchDetailsPage.patentNumber'),
    },
    {
      key: 'title',
      name: t('SearchDetailsPage.title'),
    },
    {
      key: 'abstract',
      name: t('GLOBAL.patentSections.abstract'),
    },
    {
      key: 'applicants',
      name: t('SearchDetailsPage.applicants'),
    },
    {
      key: 'inventors',
      name: t('GLOBAL.patentSections.inventors'),
    },
    {
      key: 'classificationsIPCR',
      name: t('GLOBAL.patentSections.classificationsIPCR'),
    },
    {
      key: 'classificationsCPC',
      name: t('SearchDetailsPage.ResultsExporter.classificationsCPC'),
    },
    {
      key: 'matchedCount',
      name: t('SearchDetailsPage.matchedCount'),
    },
    {
      key: 'matchedTerms',
      name: t('SearchDetailsPage.matchedTerms'),
    },
    {
      key: 'priorityDate',
      name: t('SearchDetailsPage.Bibliography.priorityDate'),
    },
    {
      key: 'publicationDate',
      name: t('SearchDetailsPage.Bibliography.publicationDate'),
    },
    {
      key: 'familyID',
      name: t('SearchDetailsPage.ResultsExporter.familyID'),
    },
    {
      key: 'countryCode',
      name: t('SearchDetailsPage.ResultsExporter.countryCode'),
    },
    {
      key: 'firstClaim',
      name: t('SearchDetailsPage.ResultsExporter.firstClaim'),
    },
    {
      key: 'allClaims',
      name: t('SearchDetailsPage.ResultsExporter.allClaims'),
    },
    // {
    //   key: 'linkImage',
    //   name: t('SearchDetailsPage.ResultsExporter.linkImage'),
    // },
  ]

  const selectedPatentNums = Object.keys(results).map(key => results[key].patentNum)

  const onSelectAll = e => {
    const checked = e.target.checked

    let updated = []
    if (checked) {
      updated = COLUMNS.map(column => column.key)
    } else {
      updated = ['patentNum']
    }
    setCheckedItems(updated)
  }

  const onCheckChange = columnKey => {
    if (columnKey === 'patentNum') return
    const updated = [...checkedItems]

    if (updated.indexOf(columnKey) >= 0) {
      updated.splice(checkedItems.indexOf(columnKey), 1)
    } else {
      updated.push(columnKey)
    }

    setCheckedItems(updated)
  }

  const getCheckedItems = () => {
    const orderedChekedItems = []
    COLUMNS.forEach(column => {
      if (checkedItems?.indexOf(column.key) >= 0) {
        orderedChekedItems.push(column.key)
      }
    })
    return orderedChekedItems
  }

  return (
    <div className={style.base}>
      {downloadState === STATUS_STATES.BUSY ? (
        <div className={style.spinner}>
          <Svg
            SvgImage={Spinner}
            alt={t('GLOBAL.spinnerAlt')}
            dataTestId='spinner'
            variants={['iconSmaller']}
          />
        </div>
      ) : (
        <div data-tooltip={t('SearchDetailsPage.ResultsExporter.export')}>
          <Svg
            SvgImage={Download}
            dataTestId='export-results'
            alt={t('SearchDetailsPage.ResultsExporter.export')}
            onClick={() => setshowConfirmDialog(true)}
            variants={['grey', 'iconSmaller']}
          />
        </div>
      )}

      {showConfirmDialog && (
        <ConfirmDialog
          onConfirm={() => {
            onExportDownload({
              format,
              numResults,
              columns: getCheckedItems(),
              patentNums: selectedPatentNums,
            })
            setshowConfirmDialog(false)
          }}
          message={t('SearchDetailsPage.ResultsExporter.title')}
          info={`*${t('SearchDetailsPage.ResultsExporter.limit')}`}
          variant='exportModal'
          onCancel={() => setshowConfirmDialog(false)}
        >
          <div className={style.formContainer}>
            <p>{t('SearchDetailsPage.ResultsExporter.exportFormat')}:</p>
            <div className={style.itemsContainer}>
              <div className={style.item}>
                <Radio
                  name='format'
                  checked={format === FORMAT.CSV}
                  onChange={() => setFormat(FORMAT.CSV)}
                >
                  {t('SearchDetailsPage.ResultsExporter.csv')}
                </Radio>
              </div>
              <div className={style.item}>
                <Radio
                  name='format'
                  checked={format === FORMAT.EXCEL}
                  onChange={() => setFormat(FORMAT.EXCEL)}
                >
                  {t('SearchDetailsPage.ResultsExporter.excel')}
                </Radio>
              </div>
            </div>
            <div className={style.line}></div>
            <p>{t('SearchDetailsPage.ResultsExporter.numberOfResults')}:</p>
            <div className={style.itemsContainer}>
              {results.length > 0 && (
                <div className={style.item}>
                  <Radio
                    name='numResults'
                    checked={numResults === null}
                    onChange={() => setNumResults(null)}
                  >
                    {t('SearchDetailsPage.ResultsExporter.selectedPatents', {
                      count: results.length,
                    })}
                  </Radio>
                </div>
              )}
              <div className={style.item}>
                <Radio
                  name='numResults'
                  checked={numResults === EXPORT_LENGTHS.first}
                  onChange={() => setNumResults(EXPORT_LENGTHS.first)}
                >
                  {EXPORT_LENGTHS.first}
                </Radio>
              </div>
              <div className={style.item}>
                <Radio
                  name='numResults'
                  checked={numResults === EXPORT_LENGTHS.second}
                  onChange={() => setNumResults(EXPORT_LENGTHS.second)}
                >
                  {EXPORT_LENGTHS.second}
                </Radio>
              </div>
              <div className={style.item}>
                <Radio
                  name='numResults'
                  checked={numResults === EXPORT_LENGTHS.third}
                  onChange={() => setNumResults(EXPORT_LENGTHS.third)}
                >
                  {EXPORT_LENGTHS.third}
                </Radio>
              </div>
            </div>
            <div className={style.line}></div>
            <p>{t('SearchDetailsPage.ResultsExporter.columnsToInclude')}:</p>
            <div className={style.item}>
              <Checkbox
                key='Select all'
                checked={COLUMNS.length === checkedItems.length}
                onChange={onSelectAll}
                variants={['fontSmallSub']}
              >
                {t('GLOBAL.selectAll')}
              </Checkbox>
            </div>
            <div className={style.itemsContainer}>
              {COLUMNS.map(column => (
                <div className={style.item} key={column.key}>
                  <Checkbox
                    checked={checkedItems?.indexOf(column.key) >= 0}
                    onChange={() => onCheckChange(column.key)}
                    variants={
                      column.key === 'patentNum' ? ['fontSmallSub', 'grey'] : ['fontSmallSub']
                    }
                  >
                    {column.name}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
        </ConfirmDialog>
      )}
    </div>
  )
}

ResultsExporter.propTypes = {
  results: PropTypes.array,
  onExportDownload: PropTypes.func,
  downloadState: PropTypes.string,
}

ResultsExporter.defaultProps = {
  results: [],
  downloadState: '',
}

export default ResultsExporter
