import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import { projectListSelector } from 'store/Project'
import { STATUS_STATES } from 'store/Status'
import { formatRank, formatPubDate } from '../../formatters'

import ProjectsSelector from './components/ProjectsSelector'
import Checkbox from 'components/Checkbox'
import MatchedTerm from '../MatchedTerm'
import Svg from 'components/Svg'
import { ReactComponent as Spinner } from 'assets/images/spinner-animated.svg'
import { ReactComponent as PdfIcon } from 'assets/images/pdf.svg'
import { ReactComponent as CaretUp } from 'assets/images/caret-up.svg'
import { ReactComponent as CaretDown } from 'assets/images/caret-down.svg'
import { ReactComponent as ErrorIcon } from 'assets/images/exclamation-circle.svg'

import styles from './listView.module.scss'

const getIPCRClasses = (classificationList, count) => {
  const filteredList = classificationList?.filter(Boolean) || []
  const maxCount = filteredList.length > count ? count : filteredList.length

  const classifications = []
  let item
  for (let i = 0; i < maxCount; i++) {
    item =
      filteredList[i] &&
      filteredList[i].replace(/  +/g, ' ').trim().split(' ').slice(0, 2).join(' ')
    classifications.push(item)
  }

  return classifications || []
}

const getRowId = patent => 'results_row_' + patent
const getMatchedTermsId = patent => 'matchedTermsList_' + patent

function ListView({
  results,
  onPatentSelectionChange,
  patentSelected,
  onPdfDownload,
  pdfDownloadState,
  downloadAll,
  onTermClick,
  isFamilyView,
  onCheckRowsChange,
  checkedRows,
  fetchResultsStatus,
  addPatent,
  isBusy,
  selectedTerms,
  clearMatchTermsUnlessCurrent,
  filtersOutOfBoundary,
  onSearchCopy,
  hasInitialResults,
}) {
  const { t } = useTranslation()

  const [activeDropdowns, setActiveDropdowns] = React.useState([])
  const [extendedPatent, setExtendedPatent] = React.useState(null)
  const [rowCellsOverflowingList, setRowCellsOverflowingList] = React.useState([])
  const showProjectsForPatentNumRef = React.useRef('')
  const [isProjectSelectionOpen, setIsProjectSelectionOpen] = React.useState(false)

  const clickedTerm = React.useRef()

  const projects = useSelector(projectListSelector)

  const resultsToDisplay = React.useMemo(() => {
    if (!isFamilyView) return results

    let arr = []
    results.forEach(result => {
      const family = arr.filter(
        el => el.familyID && result.familyID && el.familyID === result.familyID
      )

      const idx = arr.indexOf(family[family.length - 1])

      if (idx >= 0) arr = [...arr.slice(0, idx + 1), result, ...arr.slice(idx + 1)]
      else arr.push(result)
    })
    return arr
  }, [results, isFamilyView])

  const isFamilyMember = familyId => {
    if (!familyId) return false

    let count = 0

    for (let i = 0; i < results.length; ++i) {
      if (results[i].familyID === familyId) {
        if (count === 1) return true
        count++
      }
    }
    return false
  }

  const isFirstMember = (familyId, index) => {
    const foundResultIndex = resultsToDisplay.findIndex(result => result.familyID === familyId)
    return index === foundResultIndex
  }

  const showPatentRow = (patent, index) =>
    !isFamilyMember(patent.familyID, index) ||
    activeDropdowns.includes(patent.familyID) ||
    isFirstMember(patent.familyID, index)

  const orderTerms = matches =>
    matches
      .map(key => {
        const keySplit = key.split(' ')
        return { term: keySplit[0], count: keySplit[1] }
      })
      .sort((a, b) => b.count - a.count)

  const onCheckChange = patentNum => {
    const updated = [...checkedRows]

    if (updated.indexOf(patentNum) >= 0) {
      updated.splice(checkedRows.indexOf(patentNum), 1)
    } else {
      updated.push(patentNum)
    }

    onCheckRowsChange(updated)
  }

  const onSelectAll = ev => {
    const checked = ev.target.checked

    let updated = []
    if (checked) {
      updated = results.map(result => result.patentNum)
    }

    onCheckRowsChange(updated)
    downloadAll(updated)
  }

  const displayPatentFamilyArrow = (familyId, index) => {
    if (isFamilyView) return isFamilyMember(familyId) && isFirstMember(familyId, index)
    return false
  }

  const onActiveDropdown = familyId =>
    setActiveDropdowns(previous => {
      const idx = previous.indexOf(familyId)
      return previous.includes(familyId)
        ? previous.slice(0, idx).concat(previous.slice(idx + 1))
        : [...previous, familyId]
    })

  const calculateResultsRowsOverflowIfNeeded = () => {
    const overflowingList = []
    resultsToDisplay.forEach(patent => {
      // Calculate only if we don't already have it on the list
      if (!rowCellsOverflowingList.includes(patent)) {
        const patentNum = patent.patentNum
        const patentRow = document.getElementById(getRowId(patentNum))
        const allDivs =
          patentRow &&
          Array.from(patentRow.children).filter(
            //Prevent tooltip from affecting scroll height check
            child => !child.querySelector('div')?.getAttribute('data-tooltip')
          )

        if (allDivs)
          allDivs.forEach(column => {
            const textElement = column.querySelector('p')
            if (
              column.scrollHeight > column.clientHeight ||
              (textElement && textElement.scrollHeight > column.clientHeight) ||
              getIPCRClasses(patent.classificationsIPCR).length > 2
            )
              overflowingList.push(patentNum)
          })
      }
    })

    return overflowingList
  }

  useEffect(
    () => {
      setRowCellsOverflowingList(previous =>
        previous.concat(calculateResultsRowsOverflowIfNeeded())
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results]
  )

  const showTermsArrowIfNeeded = (currentPatent = null) => {
    const arrowVariants = ['inline', 'verySmall', 'rightCorner', 'grey']
    if (currentPatent === extendedPatent)
      return (
        <Svg
          SvgImage={CaretUp}
          alt={t('GLOBAL.shrinkList')}
          dataTestId='caret-up'
          variants={arrowVariants}
          onClick={() => setExtendedPatent(null)}
        />
      )

    return (
      rowCellsOverflowingList?.some(patent => patent === currentPatent) && (
        <Svg
          SvgImage={CaretDown}
          alt={t('GLOBAL.extendList')}
          dataTestId='caret-down'
          variants={arrowVariants}
          onClick={() => setExtendedPatent(currentPatent)}
        />
      )
    )
  }

  const getRowCssClasses = patent => {
    return `${styles.row} ${extendedPatent === patent.patentNum ? styles.extended : ''} ${
      //Only family members that are not the first one will have this class
      isFamilyView && isFamilyMember(patent.familyID) && activeDropdowns.includes(patent.familyID)
        ? styles.familyMember
        : ''
    } ${patentSelected === patent.patentNum ? styles.selected : ''} ${
      extendedPatent === patent.patentNum ? styles.termsExtended : ''
    } `
  }

  useEffect(() => {
    if (!isProjectSelectionOpen) showProjectsForPatentNumRef.current = null
  }, [isProjectSelectionOpen])

  const showPdfDownloadStateIcon = patentNum => {
    const state = pdfDownloadState?.state

    if (pdfDownloadState?.data?.includes(patentNum)) {
      if (state === STATUS_STATES.BUSY)
        return (
          <Svg
            SvgImage={Spinner}
            alt={t('GLOBAL.spinnerAlt')}
            dataTestId='spinner'
            variants={['loader', 'iconSmall']}
          />
        )

      if (state === STATUS_STATES.ERROR)
        return (
          <Svg
            SvgImage={ErrorIcon}
            alt={t('SearchDetailsPage.pdfDownloadError')}
            dataTestId='error-mark'
            variants={['red', 'verySmall', 'withMargin']}
          />
        )
    }

    return (
      <Svg
        SvgImage={PdfIcon}
        dataTestId='pdf-download'
        alt={t('SearchDetailsPage.pdfDownload')}
        variants={['iconSmaller', 'hoverBGLight', 'maxWidthNone']}
      />
    )
  }

  return (
    <div className={styles.base}>
      <div className={styles.table}>
        <div className={`${styles.row} ${styles.header}`}>
          <div className={`${styles.col} ${styles.widthXSmall}`}>
            <Checkbox
              dataTestId='details-page-list-view-select-all'
              checked={
                resultsToDisplay.length > 0 && checkedRows.length === resultsToDisplay.length
              }
              onChange={onSelectAll}
            />
          </div>
          {isFamilyView && <div className={`${styles.col} ${styles.widthSmall}`}></div>}
          <div className={`${styles.col} ${styles.widthXSmall}`}></div>
          <div className={`${styles.col} ${styles.widthSmall}`}>
            {t('SearchDetailsPage.matchedCount')}
          </div>

          <div className={`${styles.col} ${styles.widthSmall}`}>
            {t('SearchDetailsPage.intersection')} %
          </div>

          <div className={`${styles.col} ${styles.widthXSmall}`}></div>

          <div className={`${styles.col} ${styles.widthLong}`}>
            <div>{t('SearchDetailsPage.patentNumber')}</div>
            <div className={styles.pubDateHeader}>{t('SearchDetailsPage.pubDate')}</div>
          </div>

          <div className={`${styles.col} ${styles.widthMiddle}`}>
            {t('SearchDetailsPage.mainClass')}
          </div>

          <div className={`${styles.col} ${styles.widthLong}`}>
            {t('GLOBAL.patentSections.applicants')}
          </div>

          <div className={`${styles.col} ${styles.widthVeryLong}`}>
            {t('SearchDetailsPage.title')}
          </div>

          <div className={`${styles.col} ${styles.matchedTerms}`}>
            {t('SearchDetailsPage.matchedTerms')}
          </div>

          <div className={`${styles.col} ${styles.widthXSmall}`}></div>
        </div>

        {!filtersOutOfBoundary &&
          resultsToDisplay.map(
            (patent, index) =>
              (showPatentRow(patent, index) || !isFamilyView) && (
                <div
                  data-testid='details-page-list-view-result-row'
                  id={getRowId(patent.patentNum)}
                  key={`list_view_table_${index}`}
                  className={getRowCssClasses(patent)}
                  onClick={() => {
                    if (patentSelected !== patent.patentNum)
                      clearMatchTermsUnlessCurrent(clickedTerm.current)
                    onPatentSelectionChange(patent.patentNum)
                    clickedTerm.current = null
                  }}
                >
                  <div className={`${styles.col} ${styles.widthXSmall}`}>
                    <Checkbox
                      checked={checkedRows?.indexOf(patent.patentNum) >= 0}
                      onChange={() => onCheckChange(patent.patentNum)}
                    />
                  </div>

                  {isFamilyView && (
                    <div className={`${styles.col} ${styles.widthSmall}`}>
                      {displayPatentFamilyArrow(patent.familyID, index) && (
                        <span
                          className={`${styles.familyList} ${
                            isFamilyView && activeDropdowns.includes(patent.familyID)
                              ? styles.familyListDown
                              : ''
                          }`}
                          onClick={() => onActiveDropdown(patent.familyID)}
                        />
                      )}
                    </div>
                  )}

                  <div
                    className={`${styles.col} ${styles.widthXSmall} ${styles.addPatent} ${
                      Object.keys(projects).length > 0 ? '' : styles.disabled
                    }`}
                    onClick={() => {
                      if (Object.keys(projects).length > 0) {
                        showProjectsForPatentNumRef.current = patent
                        setIsProjectSelectionOpen(true)
                      }
                    }}
                  >
                    +
                    {isProjectSelectionOpen &&
                      showProjectsForPatentNumRef.current?.patentNum === patent.patentNum && (
                        <ProjectsSelector
                          patent={showProjectsForPatentNumRef.current}
                          projects={projects}
                          addPatent={data => addPatent({ ...data })}
                          hide={() => setIsProjectSelectionOpen(false)}
                        />
                      )}
                  </div>

                  <div className={`${styles.col} ${styles.widthSmall}`}>{patent.scoreBigrams}</div>
                  <div className={`${styles.col} ${styles.widthSmall}`}>
                    <div
                      data-tooltip={`Source = ${formatRank(
                        patent.normalizedRank
                      )} \nResult = ${formatRank(patent.reverseIntersection)} \nSingle Words = ${
                        patent.scoreSingles
                      }`}
                    >
                      {formatRank(patent.normalizedRank)}
                    </div>
                  </div>

                  <div
                    className={`${`${styles.col} ${styles.widthXSmall}`} ${styles.icon}`}
                    onClick={() =>
                      pdfDownloadState?.state !== STATUS_STATES.BUSY &&
                      onPdfDownload(patent.patentNum)
                    }
                  >
                    {showPdfDownloadStateIcon(patent.patentNum)}
                  </div>

                  <div className={`${styles.col} ${styles.widthLong}`}>
                    <div>{patent.patentNum}</div>
                    <div className={styles.pubDate}>{formatPubDate(patent.pubDate)}</div>
                  </div>

                  <div className={`${styles.col} ${styles.widthMiddle}`}>
                    {(extendedPatent === patent.patentNum
                      ? getIPCRClasses(
                          patent.classificationsIPCR,
                          patent.classificationsIPCR?.length
                        )
                      : getIPCRClasses(patent.classificationsIPCR, 2)
                    ).map((ipcr, idx) => (
                      <p key={patent.patentNum + idx}>{ipcr}</p>
                    ))}
                  </div>
                  <div className={`${styles.col} ${styles.widthLong}`}>
                    <p>{patent.applicants?.join(', ')}</p>
                  </div>
                  <div className={`${styles.col} ${styles.widthVeryLong}`}>
                    <p>{patent.title}</p>
                  </div>

                  <div
                    className={`${styles.col} ${styles.matchedTerms}`}
                    id={getMatchedTermsId(patent.patentNum)}
                  >
                    {orderTerms(patent.wordMatches).map(term => (
                      <MatchedTerm
                        term={term}
                        onTermClick={(termClicked, isSelected) => {
                          onTermClick(termClicked, isSelected)
                          clickedTerm.current = termClicked.term
                        }}
                        isTermSelected={
                          selectedTerms &&
                          patent.patentNum === patentSelected &&
                          selectedTerms.indexOf(term.term) >= 0
                        }
                        key={term.term}
                      />
                    ))}
                  </div>
                  <div
                    className={`${styles.col} ${styles.widthXSmall} ${
                      extendedPatent === patent.patentNum ? styles.rowExtended : ''
                    }`}
                  >
                    {showTermsArrowIfNeeded(patent.patentNum)}
                  </div>
                </div>
              )
          )}
      </div>

      {fetchResultsStatus.state === STATUS_STATES.FINISHED && !isBusy && !hasInitialResults && (
        <div className={styles.inTableMessage}>
          <p>{t('SearchDetailsPage.noResults')}</p>
        </div>
      )}

      {fetchResultsStatus.state === STATUS_STATES.FINISHED &&
        !isBusy &&
        hasInitialResults &&
        filtersOutOfBoundary && (
          <div className={styles.inTableMessage}>
            <p>{t('SearchDetailsPage.noResults')}</p>
            <p className={styles.outOfBoundaryLink} onClick={onSearchCopy}>
              {t('SearchDetailsPage.outOfBoundaryMessage')}
            </p>
          </div>
        )}

      {(fetchResultsStatus.state === STATUS_STATES.BUSY || isBusy) && (
        <div className={styles.scrollBottomBoundary}>
          <Svg
            SvgImage={Spinner}
            alt={t('GLOBAL.spinnerAlt')}
            dataTestId='spinner'
            variants={['loader']}
          />
        </div>
      )}
    </div>
  )
}

ListView.propTypes = {
  results: PropTypes.array,
  onPatentSelectionChange: PropTypes.func.isRequired,
  onTermClick: PropTypes.func.isRequired,
  checkedRows: PropTypes.array,
  onCheckRowsChange: PropTypes.func.isRequired,
  fetchResultsStatus: PropTypes.object,
  isBusy: PropTypes.bool,
  filtersOutOfBoundary: PropTypes.bool,
  onSearchCopy: PropTypes.func.isRequired,
  hasInitialResults: PropTypes.bool,
}

ListView.defaultProps = {
  results: [],
  fetchResultsStatus: {},
  checkedRows: [],
  filtersOutOfBoundary: false,
  hasInitialResults: true,
}

export default ListView
