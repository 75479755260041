import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { formatRank, formatPubDate } from '../../formatters'

import { STATUS_STATES } from 'store/Status'

import MatchedTerm from '../MatchedTerm'
import Svg from 'components/Svg'
import { ReactComponent as PdfIcon } from 'assets/images/pdf.svg'
import { ReactComponent as CaretUp } from 'assets/images/caret-up.svg'
import { ReactComponent as CaretDown } from 'assets/images/caret-down.svg'

import styles from './selectedResultInfo.module.scss'

function SelectedResultInfo({
  patent,
  selectedTerms,
  onTermClick,
  pdfDownloadState,
  onPdfDownload,
}) {
  const { t } = useTranslation()

  const [areTermsExtended, setAreTermsExtended] = React.useState(false)

  const formatClassifications = ipcr =>
    ipcr.replace(/  +/g, ' ').trim().split(' ').slice(0, 2).join(' ')

  const classifications = count => {
    const filteredClasses = (patent.classificationsIPCR || []).filter(Boolean)

    const classesToDisplay = filteredClasses
      .map(classif => formatClassifications(classif))
      .slice(0, count)
      .join(', ')

    return filteredClasses.length > count ? [...classesToDisplay, ', ...'] : classesToDisplay
  }

  const rowRef = React.useRef()
  const shouldRowWrap = React.useRef()

  const termsRef = React.useRef()
  const shouldShowArrow = React.useRef()

  useEffect(() => {
    //+5 as a margin of error of this estimation

    shouldRowWrap.current = rowRef.current.scrollWidth > rowRef.current.clientWidth + 5

    shouldShowArrow.current =
      termsRef.current && termsRef.current.scrollHeight > termsRef.current.clientHeight + 5
  }, [])

  const orderedTerms = React.useMemo(
    () =>
      patent.wordMatches
        .map(key => {
          const keySplit = key.split(' ')
          return { term: keySplit[0], count: keySplit[1] }
        })
        .sort((a, b) => b.count - a.count),
    [patent]
  )

  return (
    <div className={`${styles.base} ${areTermsExtended ? styles.termsExtended : ''}`}>
      <div
        className={`${styles.row} ${shouldRowWrap.current ? styles.rowWrapped : ''} `}
        ref={rowRef}
      >
        <div className={styles.dontShrink}>
          <div className={styles.key}> {t('SearchDetailsPage.patentNumber')}</div>
          <div className={styles.value}>
            <div
              className={styles.icon}
              onClick={() =>
                pdfDownloadState?.state !== STATUS_STATES.BUSY && onPdfDownload(patent.patentNum)
              }
            >
              <Svg
                SvgImage={PdfIcon}
                dataTestId='pdf-download'
                alt={t('SearchDetailsPage.pdfDownload')}
                variants={['verySmall', 'hoverBGLight', 'minimal']}
              />
            </div>
            {patent.patentNum}
          </div>
        </div>
        <div className={styles.dontShrink}>
          <div className={styles.key}>{t('SearchDetailsPage.pubDate')}</div>
          <div className={styles.value}>{formatPubDate(patent.pubDate)}</div>
        </div>
        <div>
          <div className={styles.key}>{t('SearchDetailsPage.title')}</div>
          <div className={`${styles.value} ${styles.title}`}>{patent.title}</div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.dontShrink}>
          <div className={styles.key}>{t('SearchDetailsPage.intersection')} %</div>
          <div className={styles.value}>{formatRank(patent.normalizedRank)}</div>
        </div>
        <div>
          <div className={styles.key}>{t('SearchDetailsPage.mainClass')} </div>
          <div className={styles.value}>{classifications(3)}</div>
        </div>
        <div>
          <div className={styles.key}>{t('GLOBAL.patentSections.applicants')}</div>
          <div className={styles.value}>{patent.applicants?.join(', ')}</div>
        </div>
      </div>
      <div className={`${styles.row} ${styles.terms}`} ref={termsRef}>
        <div className={styles.dontShrink}>
          <div className={styles.key}>{t('SearchDetailsPage.matchedCount')}</div>
          <div className={styles.value}>{patent.scoreBigrams}</div>
        </div>
        <div>
          <div className={styles.key}>{t('SearchDetailsPage.matchedTerms')}</div>
          <div className={styles.value}>
            {orderedTerms.map(term => (
              <MatchedTerm
                term={term}
                onTermClick={onTermClick}
                isTermSelected={selectedTerms && selectedTerms.indexOf(term.term) >= 0}
                key={term.term}
              />
            ))}
          </div>
          {shouldShowArrow.current && (
            <div className={`${styles.value} ${styles.arrow}`}>
              <Svg
                SvgImage={areTermsExtended ? CaretUp : CaretDown}
                alt={t('GLOBAL.shrinkList')}
                dataTestId='caret-up'
                variants={['inline', 'verySmall', 'grey']}
                onClick={() => setAreTermsExtended(prev => !prev)}
              />
            </div>
          )}{' '}
        </div>
      </div>
    </div>
  )
}

SelectedResultInfo.propTypes = {
  patent: PropTypes.object,
  selectedTerms: PropTypes.array,
  onTermClick: PropTypes.func,
  pdfDownloadState: PropTypes.object,
  onPdfDownload: PropTypes.func,
}

SelectedResultInfo.defaultProps = {
  patent: {},
  selectedTerms: [],
}

export default SelectedResultInfo
