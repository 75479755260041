import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import { termsAndConditionsText } from './termsAndConditions'

import style from './termsAndConditions.module.scss'

function TermsAndConditions({ termsAndConditionsAccepted, setTermsAndConditionsAccepted }) {
  const [showModal, setShowModal] = React.useState(false)

  const { t } = useTranslation()

  const onDismiss = () => setShowModal(false)
  const onShowTC = () => setShowModal(true)
  return (
    <div className={style.base}>
      <div className={style.termsCheckbox}>
        <Checkbox
          dataTestId='signUp-terms-and-conditions'
          checked={termsAndConditionsAccepted}
          onChange={e => setTermsAndConditionsAccepted(e.target.checked)}
          required={true}
        ></Checkbox>
        {t('SignUp.TermsAndConditions.agreementText')}
        <span onClick={onShowTC}>{t('SignUp.TermsAndConditions.termsAndConditionsLabel')}</span>
      </div>

      {showModal && (
        <Modal message={termsAndConditionsText}>
          <Button onClick={onDismiss}>{t('SignUp.TermsAndConditions.close')}</Button>
        </Modal>
      )}
    </div>
  )
}

TermsAndConditions.propTypes = {
  termsAndConditionsAccepted: PropTypes.bool,
  setTermsAndConditionsAccepted: PropTypes.func,
}

export default TermsAndConditions
