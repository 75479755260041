import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  allPreprocessedSearchesSelector,
  preprocess,
  updatePreprocessedList,
  removePreprocessed,
} from 'store/Search'

import { paths } from 'router/paths'
import { SEARCH_STATUS } from 'store/Search/constants'
import { createSearchId } from 'containers/Search/utils'
import { STATUS_STATES } from 'store/Status'

import SearchItem from './containers/SearchItem'
import SubmittedSearchItem from './components/SubmittedSearchItem'
import Svg from 'components/Svg'
import { ReactComponent as Spinner } from 'assets/images/spinner-animated.svg'

import style from './searchPage.module.scss'

const searchPreprocessRef = 'SearchPage_preprocess'

const acceptedFileFormats = '.txt, .json, .pdf, .doc, .docx'

function SearchPage() {
  const [showPrompt, setShowPrompt] = React.useState(false)
  const [dragging, setDragging] = React.useState(false)
  const [newItemPreprocessing, setNewItemPreprocessing] = React.useState(false)
  const inputRef = React.useRef()
  const loaderRef = React.useRef()

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const location = useLocation()
  const preprocessedSearches = useSelector(allPreprocessedSearchesSelector)

  const createSearchStatus = useSelector(state => state.Status.statuses[searchPreprocessRef]) || {}

  const handleFileSelect = e => {
    e.preventDefault()

    if (!newItemPreprocessing) setNewItemPreprocessing(true)

    const uploadedFiles = [...e.target.files]
    if (uploadedFiles.length)
      uploadedFiles.forEach(file => {
        const filename = file.name
        dispatch(
          preprocess({
            id: createSearchId(filename),
            file,
            searchName: filename,
            statusRef: searchPreprocessRef,
          })
        )
      })
    inputRef.current.value = []
  }

  const addNewSearch = () => {
    const searchId = createSearchId()
    dispatch(
      updatePreprocessedList({
        id: searchId,
        dataToUpdate: { filename: searchId, text: '', status: SEARCH_STATUS.Preparing },
      })
    )
  }

  useEffect(() => {
    const startDragEvent = 'dragenter'
    const handleDragAndDrop = e =>
      inputRef.current &&
      inputRef.current.contains(e.target) &&
      setDragging(e.type === startDragEvent)

    const events = [startDragEvent, 'drop', 'dragleave']
    events.forEach(ev => window.addEventListener(ev, handleDragAndDrop, true))
    return () => {
      events.forEach(ev => window.removeEventListener(ev, handleDragAndDrop, true))
    }
  }, [])

  useEffect(
    () => {
      Object.entries(preprocessedSearches).forEach(
        ([key, value]) =>
          [SEARCH_STATUS.processing, SEARCH_STATUS.complete].includes(value.status) &&
          dispatch(removePreprocessed({ id: key }))
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    const unprocessedSearch = Object.values(preprocessedSearches).find(
      search => search.status === SEARCH_STATUS.Preparing
    )
    setShowPrompt(Boolean(unprocessedSearch))
  }, [preprocessedSearches])

  const spinner = (
    <div className={style.loader} ref={loaderRef}>
      <Svg
        SvgImage={Spinner}
        alt={t('GLOBAL.spinnerAlt')}
        dataTestId='spinner'
        variants={['loader']}
      />
    </div>
  )

  useEffect(() => {
    const preprocessing = createSearchStatus.state === STATUS_STATES.BUSY

    if (newItemPreprocessing && !preprocessing) setNewItemPreprocessing(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSearchStatus.state])

  return (
    <div className={style.base}>
      <div className={style.searchTextSelector}>
        <div
          id='fileSearch'
          className={`${style.uploadWrapper} ${dragging ? style.dragged : ''} ${
            newItemPreprocessing ? style.disable : ''
          }`}
        >
          <label>
            {newItemPreprocessing ? (
              spinner
            ) : (
              <>
                <img
                  src='https://img.icons8.com/clouds/100/000000/upload.png'
                  alt={t('SearchPage.upload')}
                />
                <input
                  data-testid='search-setup-file-search'
                  className={style.uploadInput}
                  type='file'
                  onChange={handleFileSelect}
                  accept={acceptedFileFormats}
                  multiple
                  ref={inputRef}
                  onDrop={() => setNewItemPreprocessing(true)}
                />
              </>
            )}
          </label>

          <p>{t('SearchPage.fileSearch')}</p>
        </div>

        <div id='textSearch' className={style.searchClickable}>
          <img
            data-testid='search-setup-text-search'
            src='https://img.icons8.com/clouds/100/000000/edit-file.png'
            alt={t('SearchPage.type')}
            onClick={() => addNewSearch()}
          />

          <p>{t('SearchPage.textSearch')}</p>
        </div>
      </div>

      {/* TODO: prompt only if any of the searches is in status preparing */}
      <Prompt when={showPrompt} message={() => t('ConfirmDialog.leaveWithoutSaving')} />

      {createSearchStatus.state === STATUS_STATES.ERROR && (
        <div className={style.errorContainerMessage}>{t('SearchPage.errorPreprocessing')}</div>
      )}

      {Object.entries(preprocessedSearches).map(([key, value]) => {
        return (
          <div className={style.searchContainer} key={key}>
            {value?.error ? (
              <div className={style.errorContainer} key={key}>
                <div className={style.errorContainerFilename}>{value.filename}</div>

                {t('SearchPage.errorSubmitting')}
              </div>
            ) : [SEARCH_STATUS.processing, SEARCH_STATUS.complete].includes(value.status) ? (
              <SubmittedSearchItem key={key} name={value.name} path={paths.searches} />
            ) : (
              <SearchItem
                fileData={{ id: key, data: value }}
                areUnsavedChanges={showPrompt}
                setAreUnsavedChanges={hasChanges => setShowPrompt(hasChanges)}
                preprocessRef={searchPreprocessRef + (value.name || value.filename)}
                canSubmitSearch={Boolean(value.terms?.find(term => !term.exclude))}
                isSearchCopy={location.state?.isSearchCopy && key === location.state?.searchKey}
                notEnoughTerms={[SEARCH_STATUS.noTerms].includes(value.status)}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default SearchPage
