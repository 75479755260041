/* eslint-disable new-cap */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Dragula from 'react-dragula'

import {
  getProjectList,
  newProject,
  projectListSelector,
  editProject,
  deleteProject,
  deletePatentFromProject,
  editPatent,
  downloadPatents,
} from 'store/Project'
import { elementStateSelector, setExpandStateCreator } from 'store/Interface'

import TextInput from 'components/TextInput'
import ConfirmDialog from 'components/ConfirmDialog'
import Patent from './components/Patent'
import Svg from 'components/Svg'
import { ReactComponent as Plus } from 'assets/images/plus-square.svg'
import { ReactComponent as CaretUp } from 'assets/images/caret-up.svg'
import { ReactComponent as Edit } from 'assets/images/pencil-alt.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/minus-circle.svg'
import { ReactComponent as Download } from 'assets/images/download.svg'

import styles from './projectsPanel.module.scss'

const fetchProjectsRef = 'SearchDetailsPage_fetchProjectsRef'
const createNewProjectRef = 'SearchDetailsPage_createNewProjectRef'

const PROJECTS_PANEL_UI_STATE = 'projectsPanelState'
const PROJECT_PANEL_OPEN_STATE = 'projectsPanelOpen'
const PATENT_LISTS_UI_STATE = 'patentListsUiState'

function ProjectsPanel({ setAreProjectsOpen, areProjectsOpen }) {
  const [addProject, setAddProject] = React.useState(false)
  const [newProjectName, setNewProjectName] = React.useState('')
  const [editNameOpenId, setEditNameOpenId] = React.useState('')
  const [showDeleteProjId, setShowDeleteProjId] = React.useState('')
  const [showConfirmationForId, setShowConfirmationForId] = React.useState(null)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const projects = useSelector(projectListSelector)

  useEffect(() => {
    dispatch(getProjectList({ shouldIncludePatents: true, statusRef: fetchProjectsRef }))
    // eslint-disable-next-line
  }, [])

  const projectsPanelOpenState = useSelector(state =>
    elementStateSelector(state, PROJECTS_PANEL_UI_STATE, PROJECT_PANEL_OPEN_STATE)
  )

  const patentsOpenState =
    useSelector(state =>
      elementStateSelector(state, PROJECTS_PANEL_UI_STATE, PATENT_LISTS_UI_STATE)
    ) || {}

  const setProjectsPanelExpandValue = value =>
    dispatch(
      setExpandStateCreator({
        parent: PROJECTS_PANEL_UI_STATE,
        child: { [PROJECT_PANEL_OPEN_STATE]: value },
      })
    )

  const setPatentsListOpenState = projectId => {
    const projectOpenState = { [projectId]: !patentsOpenState[projectId] }

    dispatch(
      setExpandStateCreator({
        parent: PROJECTS_PANEL_UI_STATE,
        child: { [PATENT_LISTS_UI_STATE]: projectOpenState },
      })
    )
  }

  useEffect(() => {
    if (projectsPanelOpenState === undefined) setAreProjectsOpen(false)
    else setAreProjectsOpen(projectsPanelOpenState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects])

  useEffect(() => {
    setProjectsPanelExpandValue(areProjectsOpen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areProjectsOpen])

  const sortedProjects = React.useMemo(
    () => Object.values(projects).sort((a, b) => a.position - b.position),
    [projects]
  )

  const createProject = () => {
    dispatch(newProject({ name: newProjectName, statusRef: createNewProjectRef }))
    setNewProjectName('')
  }

  const editProjectName = (projectId, name, e) => {
    e.preventDefault()

    dispatch(editProject({ data: { name, id: projectId } }))
    setEditNameOpenId('')
  }

  const showProject = project => (
    <li
      key={project.ID}
      id={project.ID}
      className={styles.project}
      onMouseEnter={() => setShowDeleteProjId(project.ID)}
      onMouseLeave={() => setShowDeleteProjId('')}
    >
      <div
        className={`${styles.arrow} ${styles.patentsListArrow} ${
          patentsOpenState[project.ID] ? '' : styles.arrowRight
        }`}
        onClick={() => setPatentsListOpenState(project.ID)}
      >
        {project.patents?.length > 0 && (
          <Svg
            SvgImage={CaretUp}
            alt={t('GLOBAL.shrinkList')}
            dataTestId='caret-up'
            variants={['grey', 'verySmall', 'inline']}
          />
        )}
      </div>

      <div
        className={`${styles.projectName} ${
          project.patents?.length > 0 ? styles.borderBottom : ''
        }`}
      >
        {editNameOpenId === project.ID ? (
          <form onSubmit={e => editProjectName(project.ID, newProjectName, e)}>
            <input
              data-testid='project-panel-project-name-input'
              id='projectName'
              type='text'
              className={styles.editProjectNameInput}
              autoFocus
              value={newProjectName}
              maxLength={100}
              onChange={e => setNewProjectName(e.target.value)}
              onBlur={() => setEditNameOpenId('')}
              required
            />
          </form>
        ) : (
          <>
            <span onClick={() => setPatentsListOpenState(project.ID)}>{project.name}</span>
            <Svg
              SvgImage={Download}
              alt={t('ProjectsPanel.download', { object: project.name })}
              variants={['verySmall', 'rightCorner']}
              onClick={() => dispatch(downloadPatents({ projectID: project.ID }))}
            />
            <Svg
              SvgImage={Edit}
              alt={t('ProjectsPanel.editProjectName')}
              variants={['verySmall', 'rightCorner']}
              onClick={() => {
                setAddProject(false)
                setEditNameOpenId(project.ID)
                setNewProjectName(project.name)
              }}
            />
            {showDeleteProjId === project.ID && (
              <Svg
                SvgImage={DeleteIcon}
                dataTestId='projects-panel-remove'
                alt={t('ProjectsPanel.deleteProject')}
                variants={['verySmall', 'red', 'rightCorner']}
                onClick={() => setShowConfirmationForId(project.ID)}
              />
            )}
          </>
        )}
      </div>

      {patentsOpenState[project.ID] && (
        <ul className={`${styles.patentList} ${styles.dropdown}`} id='patents-container'>
          {project.patents?.map(patent => (
            <li key={patent.ID} id={patent.ID} dragula={'patent'}>
              <Patent
                patent={patent}
                removeFromProject={() =>
                  dispatch(
                    deletePatentFromProject({
                      id: patent.ID,
                      projectId: project.ID,
                      statusRef: 'test',
                    })
                  )
                }
              ></Patent>
            </li>
          ))}
        </ul>
      )}
    </li>
  )

  const hideInputOnEscape = e => {
    if (e.keyCode === 27) {
      setNewProjectName('')
      setAddProject(false)
      setEditNameOpenId('')
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', hideInputOnEscape, false)

    return () => document.removeEventListener('keydown', hideInputOnEscape, false)
  }, [])

  const drake = React.useRef()
  useEffect(() => {
    drake.current = Dragula([...document.querySelectorAll('#patents-container')])
  })

  useEffect(() => {
    drake.current?.on('drop', function (el) {
      const patentsListElement = el.parentElement
      const siblingIndex = [...patentsListElement.children]

      const newPosition = el && siblingIndex.findIndex(element => element.id === el.id)

      dispatch(
        editPatent({
          data: {
            id: el.id,
            position: newPosition,
          },
          projectId: patentsListElement.parentElement.id,
        })
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  return (
    <div className={`${styles.base} ${areProjectsOpen ? '' : styles.panelHidden}`}>
      <div className={styles.topActions}>
        <div className={`${styles.arrow} ${areProjectsOpen ? '' : styles.arrowRight}`}>
          <Svg
            SvgImage={CaretUp}
            alt={t('GLOBAL.shrinkList')}
            dataTestId='caret-up'
            variants={['rightCorner', 'grey', 'noMargin']}
            onClick={() => setAreProjectsOpen(prev => !prev)}
          />
        </div>
        <p onClick={() => setAreProjectsOpen(prev => !prev)}>{t('ProjectsPanel.title')}</p>

        <Svg
          SvgImage={Download}
          alt={t('ProjectsPanel.download', { object: t('ProjectsPanel.allProjects') })}
          variants={['withMargin', 'grey', 'iconSmaller']}
          onClick={() => {
            dispatch(downloadPatents({ projectId: null }))
          }}
        />
      </div>

      <ul className={styles.projectsList}>
        <div id='projects-container'>
          {sortedProjects.map(project => showProject(project))}

          {addProject && (
            <TextInput
              value={newProjectName}
              placeholder='Project name'
              onInputValueChange={value => setNewProjectName(value)}
              onEnterKeyPress={createProject}
              shouldAutofocus={true}
              onBlur={() => {
                setAddProject()
                setNewProjectName('')
              }}
              variant='withBorder'
            />
          )}
        </div>

        <div
          className={styles.addProjectButton}
          onClick={() => {
            setAddProject(true)
            setEditNameOpenId('')
            setNewProjectName('')
          }}
        >
          <Svg
            SvgImage={Plus}
            alt={t('ProjectsPanel.addProject')}
            dataTestId='projects-panel-add-project'
            variant='iconSmall'
          />
        </div>
      </ul>

      {showConfirmationForId && projects[showConfirmationForId] && (
        <ConfirmDialog
          onConfirm={() => {
            dispatch(deleteProject({ id: showConfirmationForId }))
            setShowConfirmationForId(null)
          }}
          onCancel={() => setShowConfirmationForId(null)}
          message={t('ProjectsPanel.confirmDeleteMessage', {
            name: projects[showConfirmationForId].name || 'a project',
          })}
        />
      )}
    </div>
  )
}

ProjectsPanel.propTypes = {
  setAreProjectsOpen: PropTypes.func.isRequired,
  areProjectsOpen: PropTypes.bool.isRequired,
}

export default ProjectsPanel
