import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { SEARCH_STATUS } from 'store/Search/constants'
import { paths } from 'router/paths'

import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import Svg from 'components/Svg'

import { ReactComponent as Edit } from 'assets/images/pencil-alt.svg'
import { ReactComponent as Desktop } from 'assets/images/desktop-alt.svg'
import { ReactComponent as Archive } from 'assets/images/archive.svg'
import { ReactComponent as Delete } from 'assets/images/trash-alt.svg'
import { ReactComponent as Info } from 'assets/images/info-circle.svg'

import styles from './searchListItem.module.scss'

function SearchListItem({
  search,
  handleArchiveClick,
  handleDeleteClick,
  checked,
  onSelectToggle,
  updateSearchName,
  shouldDisableSelected,
}) {
  const { t } = useTranslation()

  const [isNameInputVisible, setIsNameInputVisible] = React.useState(false)
  const [searchName, setSearchName] = React.useState(search.name)

  const hideInputOnEscape = e => {
    if (e.keyCode === 27) {
      setIsNameInputVisible(false)
      setSearchName(search.name)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', hideInputOnEscape, false)

    return () => document.removeEventListener('keydown', hideInputOnEscape, false)
  })

  const onNameSubmit = e => {
    e.preventDefault()

    setIsNameInputVisible(false)
    updateSearchName(search.id, searchName)
  }

  const getLastModifiedDate = () => {
    const lastModifiedLabel =
      search.status === SEARCH_STATUS.archive
        ? t('SearchList.archived')
        : search.status === SEARCH_STATUS.complete
        ? t('SearchList.completed')
        : t('SearchList.lastUpdated')

    return lastModifiedLabel + ': ' + new Date(search.modifiedDate).toLocaleString()
  }

  return (
    <div className={styles.base} data-testid={`current-searches-search-${searchName}`}>
      <div>
        <div className={styles.checkbox}>
          <Checkbox
            checked={checked}
            onChange={onSelectToggle}
            name={t('SearchList.checkbox')}
            disabled={shouldDisableSelected}
          />
        </div>

        {search.filename && (
          <div data-tooltip={t('SearchPage.SearchItem.desktop')}>
            <Svg
              SvgImage={Desktop}
              alt={t('SearchPage.SearchItem.desktop')}
              variants={['verySmall', 'desktopSearch']}
            />
          </div>
        )}
      </div>

      <div className={styles.search}>
        {isNameInputVisible ? (
          <form onSubmit={onNameSubmit} className={styles.searchName}>
            <input
              data-testid='current-searches-search-name-input'
              id='searchName'
              type='text'
              className={styles.searchInput}
              autoFocus
              value={searchName}
              maxLength={100}
              onChange={e => setSearchName(e.target.value)}
              required
              disabled={shouldDisableSelected}
            />
          </form>
        ) : (
          <div
            onClick={() => setIsNameInputVisible(true)}
            className={`${styles.editable} ${styles.searchName}`}
            data-testid='current-searches-search-name'
          >
            {searchName}
            <Svg
              SvgImage={Edit}
              alt={t('SearchPage.SearchItem.editName')}
              variants={['verySmall', 'inline']}
            />
          </div>
        )}

        <div
          className={`${styles.statusArea} ${
            search.status === SEARCH_STATUS.error ? styles.error : ''
          } ${
            [SEARCH_STATUS.complete, SEARCH_STATUS.error, SEARCH_STATUS.archive].includes(
              search.status
            )
              ? ''
              : styles.inProgress
          }`}
          data-testid='current-searches-status'
        >
          {search.status === SEARCH_STATUS.complete ? '' : search.status}
        </div>

        {[SEARCH_STATUS.complete].includes(search.status) && (
          <Link to={`${paths.searches}/${search.id}`}>
            <Button
              dataTestId={`current-searches-view-button-${searchName}`}
              variants={['long', 'thin']}
              disabled={shouldDisableSelected}
            >
              {t('SearchList.view')}
            </Button>
          </Link>
        )}

        <div
          className={styles.infoArea}
          data-tooltip={`${
            t('SearchList.created') + ': ' + new Date(search.createDate).toLocaleString()
          }\n\n${getLastModifiedDate()}\n${
            t('SearchList.termsCount') + ': ' + search.searchTermsCount
          }`}
        >
          <Svg SvgImage={Info} variants={['iconSmaller', 'blue']} />
        </div>

        <div className={styles.dateTimeArea} data-testid='current-searches-created-date'>
          {moment(search.createDate).format('MMM DD, YYYY')}
          <br />
          {moment(search.createDate).format('HH:mm')}
        </div>

        <div className={styles.buttonContainer}>
          {search.status !== SEARCH_STATUS.archive && (
            <div className={styles.archiveContainer}>
              <Svg
                SvgImage={Archive}
                alt={t('SearchList.archive')}
                onClick={() => handleArchiveClick(search.id)}
                variants={['iconSmaller', shouldDisableSelected ? 'hide' : '', 'grey']}
              />
            </div>
          )}

          <Svg
            SvgImage={Delete}
            alt={t('SearchList.remove')}
            onClick={() => handleDeleteClick(search.id)}
            variants={['iconSmaller', shouldDisableSelected ? 'hide' : '', 'grey']}
          />
        </div>
      </div>
    </div>
  )
}

SearchListItem.propTypes = {
  search: PropTypes.object.isRequired,
  updateSearchName: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  handleArchiveClick: PropTypes.func,
  handleDeleteClick: PropTypes.func.isRequired,
  shouldDisableSelected: PropTypes.bool,
}

SearchListItem.defaultProptypes = {
  shouldDisableSelected: false,
}

export default SearchListItem
