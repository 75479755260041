import { combineReducers } from 'redux'

import { reducer as Search } from 'store/Search'
import { reducer as Account } from 'store/Account'
import { reducer as Document } from 'store/Document'
import { reducer as Status } from 'store/Status'
import { reducer as Pinger } from 'store/Pinger'
import { reducer as Updater } from 'store/Updater'
import { reducer as Project } from 'store/Project'
import { reducer as Interface } from 'store/Interface'

export default combineReducers({
  Search,
  Account,
  Document,
  Status,
  Pinger,
  Updater,
  Project,
  Interface,
})
