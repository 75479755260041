import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Gallery, Item, DefaultLayout } from 'react-photoswipe-gallery'

import { setExpandStateCreator, PATENT_UI_ELEMENTS } from 'store/Interface'

import Svg from 'components/Svg'
import { ReactComponent as Spinner } from 'assets/images/spinner-animated.svg'

import 'photoswipe/dist/default-skin/default-skin.css'
import './photoswipe.css'
import styles from './images.module.scss'

function Images({ result, isOpenInitially, isLoading, parentElementRef }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = React.useState(isOpenInitially)

  const options = {
    //http://photoswipe.com/documentation/options.html
    bgOpacity: '0.8',
    timeToIdle: 40000,
    timeToIdleOutside: 10000,
    shareButtons: [
      { id: 'download', label: 'Download image', url: '{{raw_image_url}}', download: true },
    ],
  }

  useEffect(() => {
    dispatch(
      setExpandStateCreator({
        parent: parentElementRef,
        child: { [PATENT_UI_ELEMENTS.images]: isOpen },
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <div
      className={`${styles.base} ${isOpen ? '' : styles.hidden}`}
      data-testid='details-page-patent-images'
    >
      <div
        className={`${styles.title} ${isOpen ? styles.active : ''}`}
        onClick={() => setIsOpen(prev => !prev)}
      >
        {t('SearchDetailsPage.images')}
      </div>

      {isLoading && (
        <div className={styles.loadingImagesContainer}>
          <Svg SvgImage={Spinner} alt={t('GLOBAL.spinnerAlt')} dataTestId='spinner' />
        </div>
      )}

      <div className={styles.imagesContainer}>
        <Gallery layout={DefaultLayout} options={options}>
          {result.data?.map((item, index) => (
            <Item
              key={index}
              original={`${process.env.REACT_APP_API_V1_URL}${item.image}`}
              thumbnail={`${process.env.REACT_APP_API_V1_URL}${item.image}`}
              height='200'
            >
              {({ ref, open }) => (
                <div className={styles.thumbnailContainer}>
                  <div className={styles.thumbnailBorder} onClick={open}>
                    <img
                      ref={ref}
                      alt='thumbnail'
                      className={styles.thumbnailImg}
                      src={`${process.env.REACT_APP_API_V1_URL}${item.thumb}`}
                    />
                  </div>
                </div>
              )}
            </Item>
          ))}
        </Gallery>
      </div>
    </div>
  )
}

Images.propTypes = {
  result: PropTypes.object,
  isOpenInitially: PropTypes.bool,
  isLoading: PropTypes.bool,
  parentElementRef: PropTypes.string,
}

Images.defaultProps = {
  result: {},
  isOpenInitially: false,
}

export default Images
