import 'intersection-observer'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'

import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import rootSaga from './store/rootSaga'
import rootReducer from './store/rootReducer'
import { unregister } from './serviceWorker'

import './index.scss'

const createStoreInstance = initialState => {
  const sagaMiddleware = createSagaMiddleware()
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, logger))
  )

  sagaMiddleware.run(rootSaga)

  return store
}

const createHistoryInstance = () => {
  const history = createBrowserHistory()
  if (history.location.pathname === '/build/index.html') {
    history.location.pathname = '/'
  }
  return history
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister()

function wrapInProviders(
  component,
  {
    store = createStoreInstance(),
    history = createHistoryInstance(),
    translationProvider = i18n,
  } = {}
) {
  const root = (
    <React.StrictMode>
      <I18nextProvider i18n={translationProvider}>
        <Provider store={store}>
          <Router history={history}>{component}</Router>
        </Provider>
      </I18nextProvider>
    </React.StrictMode>
  )

  return { root, store }
}

export { wrapInProviders, createHistoryInstance, createStoreInstance }
