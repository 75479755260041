import React from 'react'
import PropTypes from 'prop-types'

import styles from './svg.module.scss'

function Svg({ SvgImage, onClick, alt, variant, variants, dataTestId }) {
  return (
    <SvgImage
      data-testid={dataTestId}
      alt={alt}
      onClick={onClick}
      className={`${styles.base} ${styles[variant]} ${variants.map(v => styles[v]).join(' ')}`}
    />
  )
}

Svg.propTypes = {
  SvgImage: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  variants: PropTypes.array,
}

Svg.defaultProps = {
  variant: '',
  variants: [],
}

export default Svg
