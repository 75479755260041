import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { userDataSelector, redirectSelector } from 'store/Account'
import { paths } from './paths'

function PrivateRoute({ children, ...rest }) {
  const user = useSelector(userDataSelector)
  const redirect = useSelector(redirectSelector)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user?.authenticated
          ? children
          : redirect && (
              <Redirect
                to={{
                  pathname: paths.logIn,
                  state: { from: location },
                }}
              />
            )
      }
    />
  )
}

export default PrivateRoute
