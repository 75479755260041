import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { paths } from 'router/paths'

import Svg from 'components/Svg'
import { ReactComponent as LogoFull } from 'assets/images/logo-full-red.svg'

import style from './pageNotFound.module.scss'

function PageNotFound() {
  const { t } = useTranslation()

  return (
    <div className={style.base}>
      <div className={style.link}>
        <Link to={paths.searchPage}>
          <Svg
            SvgImage={LogoFull}
            dataTestId='elute-logo'
            alt={t('GLOBAL.logoAlt')}
            variant='logoFullMedium'
          />
        </Link>
      </div>

      <p className={style.messageTitle}>404</p>
      <p className={style.messageTitle}>{t('PageNotFound.messageTitle')}</p>
      <div className={style.message}>{t('PageNotFound.message')}</div>

      <div className={style.link}>
        <Link to={paths.searchPage}>{t('PageNotFound.linkMessage')}</Link>
      </div>
    </div>
  )
}

export default PageNotFound
