export function saveAsCsv(data, filename) {
  const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' })

  let csvURL = null
  if (navigator.msSaveBlob) {
    csvURL = navigator.msSaveBlob(csvData, filename + '.csv')
  } else {
    csvURL = window.URL.createObjectURL(csvData)
  }

  const tempLink = document.createElement('a')
  tempLink.href = csvURL
  tempLink.setAttribute('download', filename + '.csv')
  tempLink.click()
}

export function saveAsExcel(data, filename) {
  const excelData = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  let excelURL = null
  if (navigator.msSaveBlob) {
    excelURL = navigator.msSaveBlob(excelData, filename + '.xls')
  } else {
    excelURL = window.URL.createObjectURL(excelData)
  }

  const tempLink = document.createElement('a')
  tempLink.href = excelURL
  tempLink.setAttribute('download', filename + '.xls')
  tempLink.click()
}
