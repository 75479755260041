import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import { setExpandStateCreator, PATENT_UI_ELEMENTS } from 'store/Interface'

import styles from './bibliography.module.scss'

function Bibliography({ result, isOpenInitially, parentElementRef }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = React.useState(isOpenInitially)

  useEffect(() => {
    dispatch(
      setExpandStateCreator({
        parent: parentElementRef,
        child: { [PATENT_UI_ELEMENTS.bibliography]: isOpen },
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const classifications = React.useMemo(() => {
    if (Object.keys(result).length === 0) return []

    const filteredList = result.classificationsIPCR?.filter(Boolean)
    const list =
      filteredList &&
      filteredList.map(classif => classif && classif.replace(/  +/g, ' ').trim().split(' '))

    return list || []
  }, [result])

  const formatDate = date => (date ? new Date(date).toLocaleDateString() : '')

  const formatNames = namesList => namesList?.join(', ')

  const getBibliographicData = () => {
    return {
      patentNum: result.patentNum,
      applicants: formatNames(result.applicants),
      inventors: formatNames(result.inventors),
      agents: result.agents && result.agents.length > 0 ? formatNames(result.agents) : null,
      priorityDate: formatDate(result.priorityDate),
      applicationDate: formatDate(result.applicationDate),
      publicationDate: formatDate(result.publicationDate),
    }
  }

  return (
    <div
      className={`${styles.base} ${isOpen ? '' : styles.hidden}`}
      data-testid='details-page-patent-bibliography'
    >
      <div
        className={`${styles.title} ${isOpenInitially ? styles.active : ''}`}
        onClick={() => setIsOpen(prev => !prev)}
      >
        {t('SearchDetailsPage.Bibliography.bibliographicalInfo')}
      </div>

      {result.patentNum &&
        Object.entries(getBibliographicData()).map(
          ([key, value]) =>
            value && (
              <div key={key} className={styles.data}>
                <p className={styles.key}>{t(`SearchDetailsPage.Bibliography.${key}`)}:</p>
                <p className={styles.value}>{value}</p>
              </div>
            )
        )}

      <div className={styles.data}>
        <p className={styles.key}>{t(`GLOBAL.patentSections.classificationsIPCR`)}:</p>
        <p className={styles.value}>
          {classifications.map((classification, index) => (
            <span key={`${classification}_${index}`} data-tooltip={classification.join(' ')}>
              {classification.slice(0, 2).join(' ')}
            </span>
          ))}{' '}
        </p>
      </div>
    </div>
  )
}

Bibliography.propTypes = {
  result: PropTypes.object,
  isOpenInitially: PropTypes.bool,
  parentElementRef: PropTypes.string,
}

Bibliography.defaultProps = {
  result: {},
  isOpenInitially: false,
}

export default Bibliography
