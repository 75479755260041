import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getPatentDocument, DOCUMENT_TYPE } from 'store/Document'
import { STATUS_STATES, clearStatus } from 'store/Status'
import { paths } from 'router/paths'

import TextInput from 'components/TextInput'
import Svg from 'components/Svg'
import { ReactComponent as MagnifyingGlass } from 'assets/images/magnifying-glass.svg'
import { ReactComponent as Spinner } from 'assets/images/spinner-animated.svg'

import styles from './patentsearchbox.module.scss'

const documentDataRetrieveRef = 'PatentSearchBox_retrievePatent'

function PatentSearchBox() {
  const { t } = useTranslation()

  const [inputValue, setInputValue] = React.useState('')

  const dispatch = useDispatch()
  const history = useHistory()

  const documentDataRetrieveStatus =
    useSelector(state => state.Status.statuses[documentDataRetrieveRef]) || {}

  useEffect(() => {
    if (documentDataRetrieveStatus.state === STATUS_STATES.FINISHED) {
      history.push(`${paths.patentView}/${inputValue}`)
    }
    // eslint-disable-next-line
  }, [documentDataRetrieveStatus.state])

  const update = () => {
    const patentNum = inputValue.replace(/[^a-z0-9+]+/gi, '')
    setInputValue(patentNum)
    dispatch(
      getPatentDocument({
        docType: DOCUMENT_TYPE.patent,
        docName: patentNum,
        statusRef: documentDataRetrieveRef,
      })
    )
  }

  return (
    <div className={styles.base}>
      {documentDataRetrieveStatus.state === STATUS_STATES.BUSY ? (
        <div className={styles.loaderWrapper}>
          <Svg
            SvgImage={Spinner}
            alt={t('GLOBAL.spinnerAlt')}
            dataTestId='spinner'
            variants={['loader', 'iconSmaller', 'noMargin']}
          />
        </div>
      ) : (
        <div className={styles.magnifyingGlassWrapper}>
          <Svg
            SvgImage={MagnifyingGlass}
            alt={t('GLOBAL.magnifyingGlassAlt')}
            variants={['magnifyingGlass', 'noMargin']}
            onClick={() => update(inputValue)}
          />
        </div>
      )}

      <TextInput
        value={inputValue}
        placeholder={t('Header.PatentSearchBox.placeholder')}
        onInputValueChange={value => {
          if (
            documentDataRetrieveStatus.state === STATUS_STATES.NOT_FOUND ||
            documentDataRetrieveStatus.state === STATUS_STATES.INVALID
          ) {
            dispatch(clearStatus({ statusRef: documentDataRetrieveRef }))
          }
          setInputValue(value)
        }}
        onEnterKeyPress={() => inputValue && update(inputValue)}
        type={'search'}
        variant='serachBox'
      />

      {documentDataRetrieveStatus.state === STATUS_STATES.NOT_FOUND && (
        <div className={styles.error}>{t('Header.PatentSearchBox.notFoundMessage')}</div>
      )}
      {documentDataRetrieveStatus.state === STATUS_STATES.INVALID && (
        <div className={styles.error}>{t('Header.PatentSearchBox.invalidPatentNumMessage')}</div>
      )}
    </div>
  )
}

export default PatentSearchBox
