import React from 'react'
import PropTypes from 'prop-types'

import styles from './radio.module.scss'

function RadioButton({ children, onChange, checked, name, disabled, variants }) {
  return (
    <label className={`${styles.base} ${variants.map(v => styles[v]).join(' ')}`}>
      <input type='radio' checked={checked} onChange={onChange} name={name} disabled={disabled} />
      <span className={disabled ? styles.disabled : ''}>{children}</span>
    </label>
  )
}

RadioButton.propTypes = {
  children: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  variants: PropTypes.array,
}

RadioButton.defaultProps = {
  checked: false,
  name: '',
  disabled: false,
  variants: [],
}

export default RadioButton
