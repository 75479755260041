import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import styles from './foldable.module.scss'

function Foldable({
  isOpenInitially,
  title,
  variants,
  children,
  overrideIsOpenValue,
  saveIsOpenSateToLocal,
}) {
  const [isOpen, setIsOpen] = React.useState(isOpenInitially)

  useEffect(() => {
    setIsOpen(isOpenInitially)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (overrideIsOpenValue) setIsOpen(overrideIsOpenValue)
  }, [overrideIsOpenValue])

  useEffect(() => {
    if (saveIsOpenSateToLocal) saveIsOpenSateToLocal(isOpen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const onTitleClick = () => {
    setIsOpen(prev => !prev)
  }

  return (
    <div
      className={`${styles.base} ${variants.map(v => styles[v]).join(' ')} 
        ${isOpen ? styles.isOpen : ''}`}
    >
      <div className={styles.title} onClick={onTitleClick}>
        {title}
      </div>
      {isOpen && <div className={styles.dropdown}>{children}</div>}
    </div>
  )
}

Foldable.propTypes = {
  title: PropTypes.string.isRequired,
  isOpenInitially: PropTypes.bool,
  variants: PropTypes.array,
  overrideIsOpenValue: PropTypes.bool,
}

Foldable.defaultProps = {
  isOpenInitially: false,
  variants: [],
}
export default Foldable
