import React from 'react'
import 'react-day-picker/lib/style.css'

import style from './yearMonthForm.module.scss'

function YearMonthForm({ date, localeUtils, maxYearsBack, onChange }) {
  const monthRef = React.useRef()
  const yearRef = React.useRef()

  const years = React.useMemo(
    () =>
      Array(maxYearsBack)
        .fill()
        .map((e, idx) => new Date().getFullYear() - maxYearsBack + idx + 1),
    [maxYearsBack]
  )

  const months = localeUtils.getMonths()

  const handleChange = () => onChange(new Date(yearRef.current.value, monthRef.current.value))

  return (
    <div className='DayPicker-Caption'>
      <select
        name='month'
        ref={monthRef}
        className={style.options}
        onChange={handleChange}
        value={date.getMonth()}
      >
        {months.map((month, idx) => (
          <option key={month} value={idx}>
            {month}
          </option>
        ))}
      </select>

      <select
        name='year'
        ref={yearRef}
        className={style.options}
        onChange={handleChange}
        value={date.getFullYear()}
      >
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  )
}

export default YearMonthForm
