import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { STATUS_STATES, clearStatus } from 'store/Status'
import { updateFilterSection, FILTER_SECTIONS } from 'components/filter/updateFilters'

import BooleanTextGroup from 'components/filter/BooleanTextGroup'
import CountrySelector from 'components/filter/CountrySelector'
import DateRangeSelector from 'components/filter/DateRangeSelector'
import TextInputTagsList from 'components/filter/TextInputTagsList'
import AppliedFilterLabels from 'components/filter/AppliedFilterLabels'
import Background from 'components/filter/Background'
import TermsSelector from './components/TermsSelector'

import styles from './patentFilters.module.scss'

function PatentFilters({
  initialValues,
  filters,
  onFilterChange,
  fetchResultsStatus,
  searchTerms,
  open,
  onOutOfBoundary,
  filtersOutOfBoundary,
  onSearchCopy,
}) {
  const { t } = useTranslation()

  const allFiltersOpenByDefault = true

  const onFilterValueChange = (type, value) => onFilterChange({ ...filters, [type]: value })

  useEffect(() => {
    if (fetchResultsStatus?.state === STATUS_STATES.FINISHED)
      clearStatus({ statusRef: fetchResultsStatus })
  }, [fetchResultsStatus, fetchResultsStatus?.state])

  const formatDate = dt => {
    return moment.isDate(dt) ? moment(dt).format('yyyy-MM-DD') : null
  }

  return (
    <div className={`${styles.base}`}>
      <div className={`${styles.filterSelection} ${open ? '' : styles.hidden}`}>
        <div>
          <BooleanTextGroup
            title={t('GLOBAL.patentSections.claims')}
            onInputValueChange={value => onFilterValueChange(FILTER_SECTIONS.claims, value)}
            values={filters?.claims}
            includeOr={false}
          />
        </div>

        <div>
          <div className={styles.filter}>
            <span>{t('GLOBAL.patentSections.applicants')}</span>
            <Background>
              <TextInputTagsList
                values={filters?.applicants}
                onSearchTextSubmit={value => onFilterValueChange(FILTER_SECTIONS.applicants, value)}
                variants={['withBorder', 'withButton']}
              />
            </Background>
          </div>

          <div>
            <span>{t('GLOBAL.patentSections.inventors')}</span>
            <Background>
              <TextInputTagsList
                values={filters?.inventors}
                onSearchTextSubmit={value => onFilterValueChange(FILTER_SECTIONS.inventors, value)}
                variants={['withBorder', 'withButton']}
              />
            </Background>
          </div>
        </div>

        <div>
          <CountrySelector
            initialValues={initialValues.countries}
            values={filters?.countries}
            onFilterChange={values => onFilterValueChange(FILTER_SECTIONS.countries, values)}
            onOutOfBoundary={onOutOfBoundary}
          />

          <div>
            <span>{t('GLOBAL.patentSections.classificationsIPCR')}</span>
            <Background>
              <TextInputTagsList
                values={filters?.classificationsIPCR}
                onSearchTextSubmit={value =>
                  onFilterValueChange(FILTER_SECTIONS.classificationsIPCR, value)
                }
                variants={['withBorder', 'withButton']}
              />
            </Background>
          </div>
        </div>

        <div>
          <DateRangeSelector
            initialValues={{
              valueStart: initialValues.publicationDateStart,
              valueEnd: initialValues.publicationDateEnd,
            }}
            title={t('SearchDetailsPage.PatentFilters.publicationDate')}
            valueStart={filters?.publicationDateStart}
            valueEnd={filters?.publicationDateEnd}
            onStartChange={value =>
              onFilterValueChange(FILTER_SECTIONS.publicationDateStart, formatDate(value))
            }
            onEndChange={value =>
              onFilterValueChange(FILTER_SECTIONS.publicationDateEnd, formatDate(value))
            }
            onOutOfBoundary={onOutOfBoundary}
          />

          <DateRangeSelector
            initialValues={{
              valueStart: initialValues.priorityDateStart,
              valueEnd: initialValues.priorityDateEnd,
            }}
            title={t('SearchDetailsPage.PatentFilters.priorityDate')}
            valueStart={filters?.priorityDateStart}
            valueEnd={filters?.priorityDateEnd}
            onStartChange={value =>
              onFilterValueChange(FILTER_SECTIONS.priorityDateStart, formatDate(value))
            }
            onEndChange={value =>
              onFilterValueChange(FILTER_SECTIONS.priorityDateEnd, formatDate(value))
            }
            onOutOfBoundary={onOutOfBoundary}
          />
        </div>

        <div className={styles.termsSelector}>
          <TermsSelector
            terms={searchTerms}
            onFilterChange={values => onFilterValueChange('terms', values)}
            appliedTermsFilter={filters?.terms}
            isOpen={allFiltersOpenByDefault}
          />
        </div>
      </div>

      {filtersOutOfBoundary && (
        <p className={styles.outOfBoundaryLink} onClick={onSearchCopy}>
          {t('SearchDetailsPage.outOfBoundaryMessage')}
        </p>
      )}

      <AppliedFilterLabels
        filters={filters}
        onRemoveFilter={(section, value, subSection = null) => {
          const updatedFilterSection = updateFilterSection({
            section,
            filters,
            value,
            subSection,
          })

          onFilterValueChange(section, updatedFilterSection)
        }}
      />
    </div>
  )
}

PatentFilters.propTypes = {
  filters: PropTypes.object,
  initialValues: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
  fetchResultsStatus: PropTypes.object,
  searchTerms: PropTypes.array,
  open: PropTypes.bool,
  onOutOfBoundary: PropTypes.func,
  onSearchCopy: PropTypes.func,
}

PatentFilters.defaultProps = {
  filters: {},
  initialValues: {},
  fetchResultsStatus: {},
  searchTerms: [],
  open: false,
}

export default PatentFilters
