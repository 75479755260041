/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { createSearchId } from 'containers/Search/utils'
import { paths } from 'router/paths'
import {
  getSearchDetails,
  getSearchResults,
  getSearchResultMarkup,
  copySearch,
  downloadExportedResults,
} from 'store/Search'
import { addPatentToProject } from 'store/Project'
import { STATUS_STATES, clearStatus } from 'store/Status'
import {
  getPatentDocument,
  patentsSelector,
  DOCUMENT_TYPE,
  getPatentDocumentPdf,
  getPatentDocumentImages,
  imagesSelector,
} from 'store/Document'
import { elementStateSelector, setExpandStateCreator, PATENT_UI_ELEMENTS } from 'store/Interface'

import PatentFilters from './components/PatentFilters'
import ResultsExporter from './components/ResultsExporter'
import ListView from './components/ListView'
import Bibliography from 'components/search/Bibliography'
import CitationsList from 'components/search/CitationsList'
import Images from 'components/search/Images'
import Patent from 'components/search/Patent'
import SourceText from './components/SourceText'
import ScrollToTop from 'components/search/ScrollToTop'
import Modal from 'components/Modal'
import Button from 'components/Button'

import Svg from 'components/Svg'
import { ReactComponent as Spinner } from 'assets/images/spinner-animated.svg'
import { ReactComponent as CaretUp } from 'assets/images/caret-up.svg'
import { ReactComponent as CaretDown } from 'assets/images/caret-down.svg'
import { ReactComponent as CheckMark } from 'assets/images/check-circle.svg'
import { ReactComponent as Copy } from 'assets/images/copy.svg'
import { ReactComponent as Undo } from 'assets/images/undo.svg'

import styles from './searchDetailsPage.module.scss'
import Checkbox from 'components/Checkbox'
import SelectedResultInfo from './components/SelectedResultInfo'
import Pagination from './components/Pagination'

const pageLoadStatusRef = 'SearchDetailsPage_pageLoad'
const fetchMathTermsHighlightsRef = 'SearchDetailsPage_fetchMathTermsHighlightsRef'
const fetchResultsStatusRef = 'SearchDetailsPage_fetchResultsStatusRef'
const fetchPatentDocumentRef = 'SearchDetailsPage_fetchPatentDocumentRef'
const fetchPatentPdfRef = 'SearchDetailsPage_fetchPatentPdfRef'
const imageLoadStatusRef = 'SearchDetailsPage_imageLoad'
const fetchExportedResultsFileRef = 'fetchExportedResultsFileRef'

const resultsPageSize = 30

const PATENT_DETAILS_UI_NAME = 'detailsPagePatentUiState'
const RESULTS_LIST_EXPANDED_UI_NAME = 'resultsListUiState'
const SEARCH_DETAILS_UI_NAME = 'searchDetails'

export function SearchDetailsPage({ areProjectsOpen }) {
  const [selectedResultNum, setSelectedResultNum] = React.useState()
  const [filter, setFilter] = React.useState({})
  const [filtersOpen, setFiltersOpen] = React.useState(false)
  const [filtersOutOfBoundary, setFiltersOutOfBoundary] = React.useState(false)
  const [textsToHighlight, setTextsToHighlight] = React.useState([])
  const [hideClaimsCheck, setHideClaimsCheck] = React.useState(true)
  const [selectedMatchTerms, setSelectedMatchTerms] = React.useState([])
  const [isFamilyView, setIsFamilyView] = React.useState(false)
  const [checkedRows, setCheckedRows] = React.useState([])
  const [isBusy, setIsBusy] = React.useState(false)
  const [showPatentPdfErrorModal, setShowPatentPdfErrorModal] = React.useState(false)
  const [isImageBusy, setIsImageBusy] = React.useState(false)
  const initialFiltersRef = React.useRef()
  const hasResults = React.useRef(false)
  const resultsPage = React.useRef(0)

  const isUserScreenSmall = document.getElementById('root').clientWidth < 1250

  const pageLoadStatus = useSelector(state => state.Status.statuses[pageLoadStatusRef]) || {}

  const fetchResultsStatus =
    useSelector(state => state.Status.statuses[fetchResultsStatusRef]) || {}

  const fetchPatentDocumentStatus =
    useSelector(state => state.Status.statuses[fetchPatentDocumentRef]) || {}

  const fetchPatentPdfStatus = useSelector(state => state.Status.statuses[fetchPatentPdfRef]) || {}

  const fetchMatchTermsHighlightStatus =
    useSelector(state => state.Status.statuses[fetchMathTermsHighlightsRef]) || {}

  const imageLoadStatus = useSelector(state => state.Status.statuses[imageLoadStatusRef]) || {}

  const fetchExportedResultsFileStatus =
    useSelector(state => state.Status.statuses[fetchExportedResultsFileRef]) || {}

  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const search = useSelector(state => state.Search.searches[id])
  const sourceTextNotAvailable = search?.sourceTextNotAvailable
  const sourceTextDeleted = search?.sourceTextDeleted
  const searchText =
    (sourceTextNotAvailable && t('SearchDetailsPage.sourceTextNotAvailable')) ||
    (sourceTextDeleted && t('SearchDetailsPage.sourceTextDeleted')) ||
    search?.text

  const topResults = useSelector(state => state.Search.searches[id]?.topResults)
  const topResultsFilter = useSelector(state => state.Search.searches[id]?.filter)

  const selectedResult = React.useMemo(
    () =>
      topResults?.[resultsPage.current]?.find(result => result.patentNum === selectedResultNum) ||
      {},
    [topResults, selectedResultNum]
  )
  const resultsCount = search?.resultsCount
  const allPatents = useSelector(patentsSelector)
  const selectedPatentDoc = allPatents[selectedResultNum]
  const allImages = useSelector(imagesSelector)
  const selectedPatentImage = allImages[selectedResultNum]

  const resultsListExpandedStoreState = useSelector(state =>
    elementStateSelector(state, SEARCH_DETAILS_UI_NAME, RESULTS_LIST_EXPANDED_UI_NAME)
  )
  const setResultsExpandValue = value =>
    dispatch(
      setExpandStateCreator({
        parent: SEARCH_DETAILS_UI_NAME,
        child: { [RESULTS_LIST_EXPANDED_UI_NAME]: value },
      })
    )

  const isResultsListExtended =
    resultsListExpandedStoreState === undefined
      ? setResultsExpandValue(true)
      : resultsListExpandedStoreState

  const patentDetailBoxesExpandStates =
    useSelector(state => elementStateSelector(state, PATENT_DETAILS_UI_NAME)) || {}

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(clearStatus({ statusRef: pageLoadStatusRef }))
    dispatch(clearStatus({ statusRef: fetchMathTermsHighlightsRef }))
    dispatch(clearStatus({ statusRef: fetchResultsStatusRef }))
    dispatch(clearStatus({ statusRef: fetchPatentDocumentRef }))
    dispatch(clearStatus({ statusRef: imageLoadStatusRef }))
    setSelectedResultNum(null)
    dispatch(getSearchDetails({ id, statusRef: pageLoadStatusRef }))

    // eslint-disable-next-line
  }, [])

  // filter search returned new results
  useEffect(() => {
    const topPatentNum = topResults?.[resultsPage.current]?.[0]?.patentNum
    if (fetchResultsStatus.state === STATUS_STATES.FINISHED) {
      if (!hasResults.current)
        hasResults.current =
          Boolean(topResults?.[resultsPage.current]?.length) && resultsPage.current === 0

      if (topPatentNum) setSelectedResultNum(topPatentNum)
    }
    // eslint-disable-next-line
  }, [fetchResultsStatus.state, topResults?.[resultsPage.current]?.[0]])

  useEffect(() => {
    setFilter(topResultsFilter)
    if (!initialFiltersRef.current) initialFiltersRef.current = topResultsFilter
  }, [topResultsFilter])

  const downloadPatentDetails = patentNum =>
    !allPatents[patentNum] &&
    dispatch(
      getPatentDocument({
        docType: DOCUMENT_TYPE.patent,
        docName: patentNum,
        statusRef: fetchPatentDocumentRef,
      })
    )

  const getResultsMarkup = terms =>
    dispatch(
      getSearchResultMarkup({
        searchID: id,
        pageNumber: resultsPage.current,
        resultID: selectedResult.id,
        terms,
        filter: topResultsFilter,
        statusRef: fetchMathTermsHighlightsRef,
      })
    )

  useEffect(() => {
    setTextsToHighlight([])

    if (!selectedResultNum) return

    downloadPatentDetails(selectedResultNum)
    dispatch(clearStatus({ statusRef: imageLoadStatusRef }))
    dispatch(
      getPatentDocumentImages({
        docType: DOCUMENT_TYPE.patent,
        docID: selectedResultNum,
        statusRef: imageLoadStatusRef,
      })
    )

    // eslint-disable-next-line
  }, [selectedResultNum])

  useEffect(() => {
    setIsImageBusy(imageLoadStatus.state === STATUS_STATES.BUSY)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageLoadStatus.state])

  const handleMatchTermClick = (matchTerm, isSelected) => {
    const updated = [...selectedMatchTerms]
    if (isSelected) {
      if (updated.indexOf(matchTerm.term) === -1) {
        updated.push(matchTerm.term)
      }
    } else {
      updated.splice(selectedMatchTerms.indexOf(matchTerm.term), 1)
    }
    setSelectedMatchTerms(updated)
  }

  const clearMatchTermsUnlessCurrent = currentClickedTerm => {
    const updated = currentClickedTerm ? [currentClickedTerm] : []
    setSelectedMatchTerms(updated)
  }

  const termsWithoutHighlights = () => {
    if (!selectedResult?.markupPositions) {
      return selectedMatchTerms
    }

    const termsNotInCommon = selectedMatchTerms.filter(term => {
      const foundVals = selectedResult.markupPositions.filter(markupTerm => {
        return term === markupTerm.wordPair
      })
      return foundVals.length === 0
    })

    return termsNotInCommon
  }

  useEffect(() => {
    const textToHighlight = []

    if (selectedMatchTerms.length > 0) {
      if (termsWithoutHighlights().length === 0 && selectedResult?.markupPositions) {
        selectedResult.markupPositions.forEach(item =>
          selectedMatchTerms.forEach(selectedMatchTerm =>
            item.wordPair.replace('_', ' ') === selectedMatchTerm.replace('_', ' ') &&
            textToHighlight.indexOf(item.spannedText) < 0
              ? textToHighlight.push(item.spannedText)
              : null
          )
        )
      } else {
        getResultsMarkup(termsWithoutHighlights())
      }
    }

    setTextsToHighlight(textToHighlight)
    // eslint-disable-next-line
  }, [selectedMatchTerms, selectedResult, selectedResult?.markupPositions])

  const checkRetryBusyProcessing = statusToCheck => {
    if (statusToCheck.state === STATUS_STATES.ERROR) {
      if (statusToCheck.data?.busyProcessing && statusToCheck.count <= 0) {
        setIsBusy(true)
        if (statusToCheck.data.payload) statusToCheck.data.payload.isPolling = true
        setTimeout(() => {
          dispatch(getSearchResults(statusToCheck.data.payload))
        }, 1000)

        return true
      }
      setIsBusy(false)
    }

    if (statusToCheck.state === STATUS_STATES.FINISHED) setIsBusy(false)

    return false
  }

  useEffect(() => {
    checkRetryBusyProcessing(fetchResultsStatus)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchResultsStatus])

  const fetchResultsPage = (
    offset,
    clearResults = false,
    _filter = filter,
    statusRef = fetchResultsStatusRef
  ) => {
    dispatch(
      getSearchResults({
        id,
        offset,
        limit: resultsPageSize,
        filter: _filter,
        clearResults,
        statusRef,
        pageNumber: resultsPage.current,
      })
    )
  }

  useEffect(() => {
    fetchResultsPage(0, true, filter)
    // eslint-disable-next-line
  }, [])

  const onFilterChange = filterToApply => {
    setFilter(filterToApply)
    setIsBusy(true)
    fetchResultsPage(0, true, filterToApply)
  }

  const downloadPatents = patentNums => patentNums.forEach(num => downloadPatentDetails(num))

  const onPdfDownload = patentNum =>
    dispatch(
      getPatentDocumentPdf({
        docType: DOCUMENT_TYPE.patent,
        patentNum,
        statusRef: fetchPatentPdfRef,
      })
    )

  useEffect(() => {
    fetchPatentPdfStatus.state === STATUS_STATES.ERROR && setShowPatentPdfErrorModal(true)
  }, [fetchPatentPdfStatus.state])

  const onNewSearchWithFilters = () => {
    const searchName = createSearchId(search.name)

    dispatch(
      copySearch({
        id,
        searchName,
        detailsFilter: filter,
      })
    )
    history.push(paths.searchPage, { isSearchCopy: true, searchKey: searchName })
  }

  if (pageLoadStatus.state === STATUS_STATES.BUSY) {
    return (
      <div className={`${styles.base}`}>
        <div className={styles.loadingContainer}>
          <Svg SvgImage={Spinner} alt={t('GLOBAL.spinnerAlt')} dataTestId='spinner' />
        </div>
      </div>
    )
  }

  if (pageLoadStatus.state === STATUS_STATES.ERROR)
    return (
      <div className={styles.error}>
        {t('SearchDetailsPage.errorMessage', { object: 'search' })}
      </div>
    )

  if (fetchResultsStatus.state === STATUS_STATES.ERROR)
    return (
      <div className={styles.error}>
        {t('SearchDetailsPage.errorMessage', { object: 'results' })}
      </div>
    )

  const showListViewArrow = () => {
    return (
      <div className={styles.extendResultsButton}>
        <Button
          variants={[isUserScreenSmall ? 'long' : 'veryLong', 'greyInverted']}
          onClick={() => setResultsExpandValue(!isResultsListExtended)}
        >
          {isResultsListExtended ? (
            <Svg
              SvgImage={CaretUp}
              alt={t('GLOBAL.shrinkList')}
              dataTestId='caret-up'
              variants={['minimal', 'grey']}
            />
          ) : (
            <Svg
              SvgImage={CaretDown}
              alt={t('GLOBAL.extendList')}
              dataTestId='caret-down'
              variants={['minimal', 'grey']}
            />
          )}
        </Button>
      </div>
    )
  }

  const onExportDownload = ({ format, numResults, columns, patentNums }) => {
    dispatch(
      downloadExportedResults({
        searchID: id,
        exportFormat: format,
        numResults,
        patents: patentNums,
        columns,
        filename: `${search?.name || 'patentSearch'}`,
        statusRef: fetchExportedResultsFileRef,
      })
    )
  }

  const displayDownloadComplete = patentNum => {
    setTimeout(function () {
      dispatch(clearStatus({ statusRef: fetchPatentPdfRef }))
    }, 3000)

    return (
      <div className={styles.downloadText}>
        <Svg
          SvgImage={CheckMark}
          dataTestId='pdf-download-complete'
          alt={t('SearchDetailsPage.downloadComplete', { patent: patentNum })}
          variants={['iconSmaller', 'green', 'inline']}
        />
        {t('SearchDetailsPage.downloadComplete', { patent: patentNum })}
      </div>
    )
  }

  return (
    <div className={`${styles.base} ${areProjectsOpen ? '' : styles.projectsHidden}`}>
      <div>
        <span className={styles.searchName}>{search?.name}</span>
        <span data-testid='details-page-searchid' className={styles.searchId}>
          ({id})
        </span>
      </div>

      <div className={styles.tableOptions}>
        <ScrollToTop areProjectsOpen={areProjectsOpen} />

        <div
          className={`${styles.filtersArrow} ${filtersOpen ? styles.open : ''}`}
          onClick={() => setFiltersOpen(prev => !prev)}
        >
          <Svg
            SvgImage={CaretDown}
            alt={t('GLOBAL.shrinkList')}
            dataTestId='caret-up'
            variants={['grey', 'iconSmaller', 'noMargin']}
          />
        </div>
        <div className={styles.titleLine}>
          <div onClick={() => setFiltersOpen(prev => !prev)}>
            {t('SearchDetailsPage.PatentFilters.filter')}
          </div>
        </div>

        <div className={styles.buttons}>
          <div data-tooltip={t('SearchDetailsPage.resetFilters')}>
            <Svg
              SvgImage={Undo}
              dataTestId='reset-filters'
              alt={t('SearchDetailsPage.resetFilters')}
              onClick={() => {
                setFilter(initialFiltersRef.current)
                onFilterChange(initialFiltersRef.current)
              }}
              variants={['grey', 'iconSmaller']}
            />
          </div>

          <div data-tooltip={t('SearchDetailsPage.reuseSearch')}>
            <Svg
              SvgImage={Copy}
              dataTestId='reuse-search'
              alt={t('SearchDetailsPage.reuseSearch')}
              onClick={onNewSearchWithFilters}
              variants={['grey', 'iconSmaller']}
            />
          </div>

          <ResultsExporter
            results={Object.values(topResults || {}).flatMap(page =>
              page.filter(top => checkedRows.includes(top.patentNum))
            )}
            onExportDownload={onExportDownload}
            downloadState={fetchExportedResultsFileStatus.state}
          />
        </div>

        <div className={styles.keepRight}>
          {fetchPatentPdfStatus.count > 0 && (
            <p className={styles.downloadText}>
              {t('SearchDetailsPage.downloadPdfQueue', {
                currentDownloadCount: fetchPatentPdfStatus.count,
              })}
            </p>
          )}

          <Checkbox
            dataTestId='details-page-family-view-checkbox'
            checked={isFamilyView}
            onChange={e => setIsFamilyView(e.target.checked)}
          >
            {t('SearchDetailsPage.familyView')}
          </Checkbox>

          {fetchPatentPdfStatus.count === 0 && displayDownloadComplete(fetchPatentPdfStatus.data)}

          <Checkbox
            dataTestId='details-page-hide-claims-checkbox'
            checked={hideClaimsCheck}
            onChange={e => setHideClaimsCheck(e.target.checked)}
          >
            {t('SearchDetailsPage.hideClaims')}
          </Checkbox>
        </div>
      </div>

      <PatentFilters
        initialValues={initialFiltersRef.current}
        filters={filter}
        onFilterChange={onFilterChange}
        fetchResultsStatus={fetchResultsStatus}
        searchTerms={(search?.terms || []).sort()}
        onSearchCopy={onNewSearchWithFilters}
        open={filtersOpen}
        onOutOfBoundary={isOutOfBoundary => setFiltersOutOfBoundary(isOutOfBoundary)}
        filtersOutOfBoundary={filtersOutOfBoundary}
      />

      <div
        className={`${styles.results} ${isResultsListExtended ? styles.resultsListExtended : ''} ${
          filtersOpen ? styles.shrink : ''
        }`}
      >
        <ListView
          results={topResults?.[resultsPage.current]}
          fetchResultsStatus={fetchResultsStatus}
          onPatentSelectionChange={setSelectedResultNum}
          patentSelected={selectedResultNum}
          onTermClick={handleMatchTermClick}
          selectedTerms={selectedMatchTerms}
          clearMatchTermsUnlessCurrent={clearMatchTermsUnlessCurrent}
          onPdfDownload={onPdfDownload}
          pdfDownloadState={fetchPatentPdfStatus}
          downloadAll={downloadPatents}
          isFamilyView={isFamilyView}
          checkedRows={checkedRows}
          onCheckRowsChange={setCheckedRows}
          isBusy={isBusy}
          addPatent={data => dispatch(addPatentToProject(data))}
          filtersOutOfBoundary={filtersOutOfBoundary}
          onSearchCopy={onNewSearchWithFilters}
          hasInitialResults={hasResults.current}
        />

        {showListViewArrow()}

        {resultsCount && (
          <div className={styles.paginationArea}>
            <Pagination
              lastPageNumber={Math.floor(resultsCount / resultsPageSize)}
              onPageChange={pageNumber => {
                resultsPage.current = pageNumber
                setSelectedMatchTerms([])
                fetchResultsPage(pageNumber * resultsPageSize)
              }}
            />

            <div className={styles.resultsCount}>
              ( {t('SearchDetailsPage.results', { count: resultsCount })} )
            </div>
          </div>
        )}
      </div>

      {allPatents[selectedResult.patentNum] && (
        <div
          className={`${styles.selectedResult} ${
            isResultsListExtended ? styles.resultsListExtended : ''
          }`}
        >
          <SelectedResultInfo
            patent={selectedResult}
            selectedTerms={selectedMatchTerms}
            onTermClick={handleMatchTermClick}
            pdfDownloadState={fetchPatentPdfStatus}
            onPdfDownload={onPdfDownload}
          />

          <div className={styles.metaTadaTables}>
            <Bibliography
              result={selectedPatentDoc}
              isOpenInitially={Boolean(
                patentDetailBoxesExpandStates[PATENT_UI_ELEMENTS.bibliography]
              )}
              parentElementRef={PATENT_DETAILS_UI_NAME}
            />

            <CitationsList
              result={selectedPatentDoc}
              searchId={id}
              isOpenInitially={Boolean(patentDetailBoxesExpandStates[PATENT_UI_ELEMENTS.citations])}
              parentElementRef={PATENT_DETAILS_UI_NAME}
            />

            {/* <Images
                  result={selectedPatentImage}
                  isOpenInitially={Boolean(
                    patentDetailBoxesExpandStates[PATENT_UI_ELEMENTS.images]
                  )}
                  isLoading={isImageBusy}
                  parentElementRef={PATENT_DETAILS_UI_NAME}
                /> */}
          </div>

          <div className={styles.resultDetails}>
            <SourceText
              searchText={searchText}
              isOpenInitially={Boolean(
                patentDetailBoxesExpandStates[PATENT_UI_ELEMENTS.sourceText]
              )}
              textsToHighlight={textsToHighlight}
              parentElementRef={PATENT_DETAILS_UI_NAME}
            />

            <Patent
              patent={selectedPatentDoc}
              textsToHighlight={textsToHighlight}
              hideClaimsOnHighlight={hideClaimsCheck}
              parentElementRef={PATENT_DETAILS_UI_NAME}
            />
          </div>
        </div>
      )}
      {fetchPatentDocumentStatus.state === STATUS_STATES.ERROR && (
        <div className={styles.error}>
          {t('SearchDetailsPage.errorMessage', { object: 'selected document data' })}
        </div>
      )}

      {showPatentPdfErrorModal && (
        <Modal message={t('SearchDetailsPage.errorMessage', { object: 'patent PDF' })}>
          <Button onClick={() => setShowPatentPdfErrorModal(false)}>{t('GLOBAL.ok')}</Button>
        </Modal>
      )}
    </div>
  )
}

SearchDetailsPage.propTypes = {
  areProjectsOpen: PropTypes.bool.isRequired,
}

export default SearchDetailsPage
