import axios from 'axios'
import { serialize } from 'object-to-formdata'
import jwt_decode from 'jwt-decode'

import { saveToLocalStore, loadFromLocalStore, STORAGE_ITEMS } from 'store/localStorage'

const apiUrl = `${process.env.REACT_APP_API_V1_URL}`

export const ENDPOINTS = {
  search: '/search',
  preprocess: '/search/preprocess',
  singleSearch: searchID => `/search/${searchID}`,
  searchResults: searchID => `/search/${searchID}/results`,
  searchResultsMarkup: (searchID, patentNum) => `/search/${searchID}/results/${patentNum}/markup`,
  project: '/project',
  singleProject: projectID => `/project/${projectID}`,
  projectPatents: projectID => `/project/${projectID}/patent`,
  projectPatent: (projectID, patentID) => `/project/${projectID}/patent/${patentID}`,
  downloadProjectPatents: projectID => `/project/${projectID}/export`,
  downloadAllPatents: `/project/export`,
  documentImages: (docType, docID) => `/${docType}/${docID}/image`,
  exportResults: searchID => `/search/${searchID}/results/export`,
  login: '/user/login',
  refreshToken: '/user/refresh',
}

/********************************************************
 * Add any headers etc needed for every call
 ********************************************************/
function axiosConfig(params, headers = {}) {
  const user = loadFromLocalStore(STORAGE_ITEMS.user)

  const token = user?.token

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Csrf-Token': 'nocheck',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'x-userID': user?.id,
      ...headers,
    },
  }

  config.params = {}

  if (params) {
    config.params = params

    Object.keys(config.params).map(function (key, index) {
      if (config.params[key] === 'true' || config.params[key] === true) {
        config.params[key] = 1
      }
      if (config.params[key] === 'false' || config.params[key] === false) {
        config.params[key] = 0
      }

      return null
    })
  }

  return config
}

export const isTokenExpired = () => {
  const user = loadFromLocalStore(STORAGE_ITEMS.user)
  if (!user) return false
  const decodedToken = jwt_decode(user.token)
  const now = new Date().getTime() / 1000
  if (decodedToken.exp > now) return false
  else return true
}

export const refreshToken = async () => {
  const user = loadFromLocalStore(STORAGE_ITEMS.user)
  return new Promise((resolve, reject) => {
    const config = axiosConfig({ refreshToken: user.refreshToken })
    axios
      .post(apiUrl + ENDPOINTS.refreshToken, config.params)
      .then(resp => {
        if (resp.status === 200) {
          user.token = resp.data.token
          saveToLocalStore(STORAGE_ITEMS.user, user)
          return resolve(true)
        }
      })
      .catch(err => {
        return reject()
      })
  })
}

/********************************************************
 * To make the requests
 ********************************************************/

/**
 * Makes a GET request
 * @param {String} url
 * @param {Object} data
 * @param {CancelToke} cancelToken
 */

export async function get(url, data, cancelToken) {
  if (isTokenExpired()) {
    await refreshToken()
  }
  return axios.get(apiUrl + url, axiosConfig(data), { cancelToken })
}

// POST
export async function post(url, data, cancelToken, headers = {}, responseType) {
  if (isTokenExpired()) {
    await refreshToken()
  }
  const config = axiosConfig(data, headers)
  const configToSend = { headers: config.headers, cancelToken, responseType }
  return axios.post(apiUrl + url, config.params, configToSend)
}

// POST form data as x-www-form-urlencoded
// Expects and object of form values
export async function postForm(url, data, cancelToken, headers = {}) {
  if (isTokenExpired()) {
    await refreshToken()
  }
  const config = axiosConfig(data, headers)
  const configToSend = { headers: config.headers, cancelToken }
  // const body = config.params;

  const options = {
    /**
     * whether or not to include array indices in FormData keys
     * defaults to false
     */
    indices: true,

    /**
     * treat null values like undefined values and ignore them
     * defaults to false
     */
    nullsAsUndefineds: false,

    /**
     * store arrays even if they're empty
     * defaults to false
     */
    allowEmptyArrays: true,
  }

  const formData = serialize(config.params, options)
  // config.headers['Content-Type'] = `multipart/form-data; boundary=${data._boundary}`;
  config.headers['Content-Type'] = `application/x-www-form-urlencoded`

  return axios.post(apiUrl + url, formData, configToSend)
}

// PUT
export async function put(url, data, cancelToken) {
  if (isTokenExpired()) {
    await refreshToken()
  }
  const config = axiosConfig(data)
  const configToSend = { headers: config.headers, cancelToken }

  return axios.put(apiUrl + url, config.params, configToSend)
}

// PATCH
export async function patch(url, data, cancelToken) {
  if (isTokenExpired()) {
    await refreshToken()
  }
  const config = axiosConfig(data)
  const configToSend = { headers: config.headers, cancelToken }

  return axios.patch(apiUrl + url, config.params, configToSend)
}

// DELETE
export async function deleteIt(url, data, cancelToken, headers = {}) {
  if (isTokenExpired()) {
    await refreshToken()
  }
  const config = axiosConfig(data, headers)
  const configToSend = { data: config.params, headers: config.headers, cancelToken }

  return axios.delete(apiUrl + url, configToSend)
}

/**
 * Create the axios token needed to properly cancel a request
 * @returns object continaing the newly created cancellation token and the canceller
 */
export const generateCancelSource = () => {
  const CancelToken = axios.CancelToken
  let cancel

  return {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),

    cancel,
  }
}
