import React from 'react'

import style from './termsAndConditions.module.scss'

export const termsAndConditionsText = (
  <div>
    <h2>Service terms and conditions</h2>
    <br />
    <h3>Who we are</h3>
    <p>
      This service (the “Service”) is a website belonging to Elute Intelligence Limited (“Elute”,
      “us”, or “we”), a company registered in England and Wales under company number 06647282 and
      with its registered address at 21 Church Road, Tadley, England, RG26 3AX.
    </p>
    <br />
    <h3>Acceptance of terms</h3>
    <p>
      Please read these terms of use of the Service carefully. By using the Service you accept these
      terms of use and you agree to comply with them and the general terms and conditions that were
      agreed between Elute and the Customer who has purchased this user license. We reserve the
      right to vary these terms of use at any time and will post any variations here. You are
      advised to check these terms every time you wish to use the Service to ensure that you
      understand the terms that apply at that time – you will be deemed to have accepted variations
      if you continue to use the Service after they have been posted. If you do not agree to these
      terms of use you should not use the Service in any way.
    </p>
    <br />
    <h3>Keeping your account details safe</h3>
    <p>
      If you choose, or you are provided with, a user identification code, password or any other
      piece of information as part of our security procedures, you must treat such information as
      confidential. You must not disclose it to any third party. Elute has the right to disable any
      user identification code or password, whether chosen by you or allocated by us, at any time,
      if in our reasonable opinion you have failed to comply with any of the provisions of these
      terms of use. If you know or suspect that anyone other than you knows your user identification
      code or password, you must promptly notify your local administrator for this Service or us at{' '}
      <a href='mailto:patentreader@eluteintelligence.com'>patentreader@eluteintelligence.com</a> .
    </p>
    <br />
    <h3>Prohibited uses</h3>
    <p>
      You may use the Website only for lawful purposes. You may not use the Website:
      <ul className={style.prohibitted}>
        <li>
          In any way that breaches any applicable local, national or international law or
          regulation.
        </li>
        <li>
          In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or
          effect.
        </li>
        <li>
          To send, knowingly receive, upload, download, use or re-use any material which does not
          comply with our content standards.
        </li>
        <li>
          To transmit, or procure the sending of, any unsolicited or unauthorised advertising or
          promotional material or any other form of similar solicitation (spam).
        </li>
        <li>
          To knowingly transmit any data, send or upload any material that contains viruses, Trojan
          horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful
          programs or similar computer code designed to adversely affect the operation of any
          computer software or hardware.
        </li>
      </ul>
    </p>
    <br />
    <h3>General</h3>
    <p>
      These terms of use are governed by the laws of England and Wales and you agree that the
      English courts shall have exclusive jurisdiction in any dispute. To the extent that any part
      of these terms of use is found to be invalid, unlawful or unenforceable by any court of
      competent jurisdiction such part shall to that extent be severed from the remaining terms all
      of which shall remain in full force and effect as permitted by law.
    </p>
  </div>
)
