import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { debounce } from 'debounce'
import Mark from 'mark.js'

import { setExpandStateCreator, PATENT_UI_ELEMENTS } from 'store/Interface'
import { highlightTerms } from 'components/search/highlightText'
import styles from './sourceText.module.scss'

let markInstance = null

function SourceText({ searchText, isOpenInitially, textsToHighlight, parentElementRef }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = React.useState(isOpenInitially)
  const sourceTextContainerRef = React.useCallback(node => {
    if (node !== null) {
      markInstance = new Mark(node)
    }
    // eslint-disable-next-line
  }, [])

  const handleDebounced = useCallback(func => debounce(func(), 300), [])

  const highlightWithTerms = useCallback((toMarkUpArr, highlightClassName) => {
    markInstance = highlightTerms(markInstance, toMarkUpArr, highlightClassName)
  }, [])

  useEffect(() => {
    dispatch(
      setExpandStateCreator({
        parent: parentElementRef,
        child: { [PATENT_UI_ELEMENTS.sourceText]: isOpen },
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    const highlightClassName = styles.selectedTermHighlight
    if (markInstance !== null) {
      markInstance.unmark({ className: highlightClassName })
    }

    if (textsToHighlight.length > 0) {
      handleDebounced(() => {
        highlightWithTerms(textsToHighlight, highlightClassName)
      })
    }
  }, [textsToHighlight, highlightWithTerms, handleDebounced, sourceTextContainerRef])

  return (
    <div className={`${styles.base} ${isOpen ? '' : styles.hidden}`}>
      <div
        className={styles.title}
        data-testid='details-page-search-text'
        onClick={() => setIsOpen(prev => !prev)}
      >
        {t('SearchDetailsPage.searchText')}
      </div>
      <div ref={sourceTextContainerRef}>{searchText}</div>
    </div>
  )
}

SourceText.propTypes = {
  searchText: PropTypes.string,
  isOpenInitially: PropTypes.bool,
  textsToHighlight: PropTypes.array,
  parentElementRef: PropTypes.string,
}

SourceText.defaultProps = {
  searchText: '',
  isOpenInitially: true,
  textsToHighlight: [],
}

export default SourceText
