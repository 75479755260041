import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { setExpandStateCreator, PATENT_UI_ELEMENTS } from 'store/Interface'

import { paths } from 'router/paths'

import styles from './citationsList.module.scss'

function CitationsList({ result, searchId, isOpenInitially, parentElementRef }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isDesktopApp = Boolean(window && window.nw)
  const [isOpen, setIsOpen] = React.useState(isOpenInitially)

  useEffect(() => {
    dispatch(
      setExpandStateCreator({
        parent: parentElementRef,
        child: { [PATENT_UI_ELEMENTS.citations]: isOpen },
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <div
      className={`${styles.base} ${isOpen ? '' : styles.hidden}`}
      data-testid='details-page-patent-citations'
    >
      <div
        className={`${styles.title} ${isOpen ? styles.active : ''}`}
        onClick={() => setIsOpen(prev => !prev)}
      >
        {t('SearchDetailsPage.citations')}
      </div>

      {result?.citations?.map((citation, index) => {
        const patentNumFormatted = citation.patentNum.replace(/[^a-z0-9]/gi, '')
        return (
          <div key={`${result.patentNum}-${patentNumFormatted}-${index}`}>
            {!isDesktopApp && (
              <Link
                to={`${paths.patentView}/${patentNumFormatted}${
                  searchId ? '?searchId=' + searchId : ''
                } `}
                target='_blank'
              >
                <p>{patentNumFormatted}</p>
              </Link>
            )}
            {isDesktopApp && <p>{patentNumFormatted}</p>}
          </div>
        )
      })}
    </div>
  )
}

CitationsList.propTypes = {
  result: PropTypes.object,
  searchId: PropTypes.string,
  isOpenInitially: PropTypes.bool,
  parentElementRef: PropTypes.string,
}

CitationsList.defaultProps = {
  result: {},
  isOpenInitially: false,
}

export default CitationsList
