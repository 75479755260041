import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'components/Button'
import PasswordRequirements from 'components/PasswordRequirements'

import {
  userDataSelector,
  sendPasswordReminder,
  sendPasswordReminderConfirmation,
  ERROR_TYPE,
} from 'store/Account'
import { STATUS_STATES, clearStatus } from 'store/Status'

import style from './passwordReset.module.scss'

const passwordResetRef = 'PasswordReset_confirm'

function PasswordReset() {
  const [reminderSent, setReminderSent] = React.useState(false)
  const [passwordError, setPaswordError] = React.useState('')
  const [code, setCode] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('')
  const [confirmationSent, setConfirmationSent] = React.useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const user = useSelector(userDataSelector)

  useEffect(() => user?.username && setUsername(user.username), [user])

  const passwordResetState = useSelector(state => state.Status.statuses[passwordResetRef]) || {}

  const error =
    passwordResetState.state === STATUS_STATES.ERROR &&
    (Object.keys(ERROR_TYPE.forgotPasswordConfirmation).find(key =>
      [passwordResetState.data, passwordError].includes(ERROR_TYPE.forgotPasswordConfirmation[key])
    ) ||
      'default')

  const clearState = () => {
    if (passwordResetState.state) dispatch(clearStatus({ statusRef: passwordResetRef }))
    setConfirmationSent(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearState, [])

  const passwordsMatch = newPassword === newPasswordConfirm

  const reminderError = error === ERROR_TYPE.forgotPasswordConfirmation.confirmPassword

  const success =
    passwordsMatch && confirmationSent && passwordResetState.state !== STATUS_STATES.ERROR

  const sendReminder = e => {
    e.preventDefault()

    if (passwordsMatch) {
      dispatch(sendPasswordReminder({ username, statusRef: passwordResetRef }))

      if (!reminderError) setReminderSent(true)
      setPaswordError('')
    } else setPaswordError(ERROR_TYPE.forgotPassword.passwordMismatch)
  }

  const confirmNewPassword = async e => {
    e.preventDefault()

    if (reminderSent) {
      dispatch(
        sendPasswordReminderConfirmation({
          username,
          code,
          newPassword,
          statusRef: passwordResetRef,
        })
      )

      setConfirmationSent(true)
      setPaswordError('')
    } else sendReminder(e)
  }

  return (
    <div className={style.base}>
      {reminderSent && !error ? (
        <h4>{t('PasswordReset.changePassword')}</h4>
      ) : (
        <h4>{t('PasswordReset.sendCode')}</h4>
      )}

      <form onSubmit={confirmNewPassword} className={style.form}>
        <div>
          <label htmlFor='password'>{t('ForgotPassword.newPassword')}</label>
          <input
            id='password'
            type='password'
            value={newPassword}
            onChange={e => {
              setNewPassword(e.target.value)
              clearState()
            }}
            autoComplete='off'
            disabled={reminderSent}
            required
          />
        </div>

        <div>
          <label htmlFor='passwordConfirm'>{t('ForgotPassword.newPasswordConfirm')}</label>
          <input
            id='passwordConfirm'
            type='password'
            value={newPasswordConfirm}
            onChange={e => {
              setNewPasswordConfirm(e.target.value)
              clearState()
            }}
            autoComplete='off'
            disabled={reminderSent}
            required
          />
        </div>
        <PasswordRequirements
          password={newPassword}
          passwordsMatch={newPassword && passwordsMatch}
        />

        {reminderSent && !reminderError && (
          <div>
            <label htmlFor='verificationCode'>{t('GLOBAL.verificationCode')}</label>
            <input
              id='verificationCode'
              type='text'
              value={code}
              placeholder={t('GLOBAL.verificationCodeExample')}
              onChange={e => {
                setCode(e.target.value)
                clearState()
              }}
              required
            />
          </div>
        )}

        {error && <div className={style.error}>{t(`ForgotPassword.errors.${error}`)}</div>}

        {success && <p className={style.successMessage}>{t('ForgotPassword.changeSucceeded')}</p>}

        {reminderSent && !confirmationSent && !error ? (
          <div className={style.requestCode}>
            <p className={style.successMessage}>{t('PasswordReset.codeSent')}</p>
            <Button type='submit'>{t('ForgotPassword.changePassword')}</Button>
          </div>
        ) : (
          <Button type='submit'>{t('ForgotPassword.sendReminder')}</Button>
        )}
      </form>
    </div>
  )
}

export default PasswordReset
