import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import Svg from 'components/Svg'
import Button from 'components/Button'
import { ReactComponent as Wrench } from 'assets/images/wrench.svg'

import style from './pageMaintenance.module.scss'

function PageMaintenance({ status }) {
  const { t } = useTranslation()

  const MaintananceHeader = (
    <>
      <span className={style.messageTitle}>{t('PageMaintenance.title')}</span>{' '}
      <span className={style.message}>{t('PageMaintenance.message')}</span>
    </>
  )

  const PageRefreshBanner = (
    <>
      <span className={style.messageTitle}>{t('PageMaintenance.refreshPage.title')}</span>
      <span className={style.message}>{t('PageMaintenance.refreshPage.message')}</span>
      <Button
        variants={['thick', 'long', 'colorNegative']}
        onClick={() => window.location.reload()}
      >
        {t('GLOBAL.refresh')}
      </Button>
    </>
  )

  return (
    <div className={style.base}>
      <div className={style.header}>
        <Svg SvgImage={Wrench} alt={t('PageMaintenance.title')} variants={['inline']} />
        {!status.maintenanceMode && status.pageRefresh ? PageRefreshBanner : MaintananceHeader}
      </div>
      <div className={`${status.maintenanceMode ? style.overlay : ''}`}></div>
    </div>
  )
}

PageMaintenance.propTypes = {
  status: PropTypes.object,
}

PageMaintenance.defaultProps = {
  status: { maintenanceMode: false, pageRefresh: false },
}

export default PageMaintenance
