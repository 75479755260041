import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import TextInputTagsList from 'components/filter/TextInputTagsList'
import Background from '../Background'

import styles from './booleanTextGroup.module.scss'

function BooleanTextGroup({
  values,
  title,
  onInputValueChange,
  includeAnd,
  includeOr,
  includeNot,
}) {
  const { t } = useTranslation()

  const update = (type, value) => {
    const filter = { ...values, [type]: value }
    onInputValueChange(filter)
  }

  return (
    <div className={styles.base}>
      <span>{title}</span>

      <Background>
        {includeAnd && (
          <TextInputTagsList
            values={values?.and}
            onSearchTextSubmit={value => update('and', value)}
            placeholder={t('SearchDetailsPage.PatentFilters.claimsAnd')}
            variants={['marginBottom', 'withButton']}
          />
        )}

        {includeOr && (
          <TextInputTagsList
            values={values?.or}
            onSearchTextSubmit={value => update('or', value)}
            placeholder={t('SearchDetailsPage.PatentFilters.claimsOr')}
            variants={['withButton']}
          />
        )}

        {includeNot && (
          <TextInputTagsList
            values={values?.not}
            onSearchTextSubmit={value => update('not', value)}
            placeholder={t('SearchDetailsPage.PatentFilters.claimsNot')}
            variants={['withButton']}
          />
        )}

        <div className={styles.note}>{t('SearchDetailsPage.PatentFilters.claimsNote')}</div>
      </Background>
    </div>
  )
}

BooleanTextGroup.propTypes = {
  values: PropTypes.object,
  title: PropTypes.string,
  onInputValueChange: PropTypes.func.isRequired,
  includeAnd: PropTypes.bool,
  includeOr: PropTypes.bool,
  includeNot: PropTypes.bool,
}

BooleanTextGroup.defaultProps = {
  title: '',
  includeAnd: true,
  includeOr: true,
  includeNot: true,
}

export default BooleanTextGroup
