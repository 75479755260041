export const SEARCH_STATUS = {
  Preparing: 'Preparing',
  Preprocessed: 'Preprocessed', //preprocess complete, search terms and search text returned from BE
  Preprocessing: 'Preprocessing',
  queued: 'queued', // search has been submitted and wil be picked up for processing
  processing: 'processing', //being processed in BE, percantege of completeness is < 100
  archive: 'archive',
  Searching: 'Searching', //TODO: remove
  complete: 'complete', //search complete, results available
  error: 'error',
  noTerms: 'noTerms',
}
