import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Checkbox from 'components/Checkbox'
import Background from 'components/filter/Background'
import TextInput from 'components/TextInput'

import styles from './termsSelector.module.scss'

function TermsSelector({ terms, onFilterChange, appliedTermsFilter }) {
  const { t } = useTranslation()

  const [selectedTermMatches, setSelectedTermMatches] = React.useState([])
  const [inputValue, setInputValue] = React.useState('')

  const onSelectAll = ev => {
    const { checked } = ev.target

    const updated = checked ? terms : []

    onFilterChange(updated)
  }

  useEffect(() => {
    setSelectedTermMatches(appliedTermsFilter)
  }, [appliedTermsFilter])

  const onTermToggle = item => {
    let updated = []
    if (appliedTermsFilter.includes(item)) {
      updated = appliedTermsFilter.filter(prevItem => prevItem !== item)
    } else updated = [...appliedTermsFilter, item]

    onFilterChange(updated)
  }

  const doesContainItem = text => {
    if (inputValue.length) {
      const regex = new RegExp(inputValue, 'gi')
      return regex.test(text)
    }
    return true
  }

  return (
    <div className={styles.base}>
      <span>{t('SearchDetailsPage.PatentFilters.TermsSelector.terms')}</span>
      <Background>
        <TextInput
          value={inputValue}
          onInputValueChange={value => setInputValue(value)}
          onEnterKeyPress={() => {}}
          variant={'withBorder'}
          placeholder={t('SearchDetailsPage.PatentFilters.searchPlaceholder')}
        />
        <ul>
          <div className={styles.dropdownRowSelectAll}>
            <Checkbox
              checked={terms?.length > 0 && selectedTermMatches?.length === terms?.length}
              onChange={onSelectAll}
              variants={['fontSmall']}
            >
              {t('SearchDetailsPage.PatentFilters.CountrySelector.selectAll')}
            </Checkbox>
          </div>

          {terms.length &&
            terms.map(
              item =>
                doesContainItem(item) && (
                  <li key={item}>
                    <Checkbox
                      checked={selectedTermMatches.includes(item)}
                      onChange={() => onTermToggle(item)}
                      variants={['fontSmall']}
                    >
                      {item.replace('_', ' ')}
                    </Checkbox>
                  </li>
                )
            )}
        </ul>
      </Background>
    </div>
  )
}

TermsSelector.propTypes = {
  terms: PropTypes.array,
  onFilterChange: PropTypes.func.isRequired,
  appliedTermsFilter: PropTypes.array,
}

TermsSelector.defaultProps = {
  terms: [],
  appliedTermsFilter: [],
}

export default TermsSelector
