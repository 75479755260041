import React from 'react'
import PropTypes from 'prop-types'

import { updateFilterArray } from '../updateFilters'
import Button from 'components/Button'
import TextInput from 'components/TextInput'
import Svg from 'components/Svg'
import { ReactComponent as CaretUp } from 'assets/images/caret-up.svg'

import styles from './textInputTagsList.module.scss'

function TextInputTagsList({
  values,
  onSearchTextSubmit,
  placeholder,
  onFocus,
  onClick,
  isActive,
  buttonAlt,
  variants,
}) {
  const [inputValue, setInputValue] = React.useState('')

  const update = (value, shouldAdd) => {
    const filter = updateFilterArray({ currentValues: values, value, shouldAdd })
    onSearchTextSubmit(filter)
    setInputValue('')
  }

  return (
    <div className={`${styles.base} ${variants.map(v => styles[v]).join(' ')}`}>
      <TextInput
        value={inputValue}
        placeholder={placeholder}
        onInputValueChange={value => setInputValue(value)}
        onEnterKeyPress={() => inputValue && update(inputValue, true)}
        variants={['withBorder']}
        onFocus={onFocus}
      />

      <div className={`${styles.addButtonWrapper} ${values.length > 0 ? styles.shift : ''}`}>
        <Button onClick={() => inputValue && update(inputValue, true)} variant={'searchTermAction'}>
          +
        </Button>
      </div>

      <div className={`${styles.arrowWrapper} ${isActive ? styles.clicked : ''}`}>
        <Svg
          SvgImage={CaretUp}
          dataTestId='caret-up'
          variants={['rightCorner', 'grey', 'noMargin', 'verySmall']}
          alt={buttonAlt}
          onClick={onClick}
        />
      </div>
    </div>
  )
}

TextInputTagsList.propTypes = {
  values: PropTypes.array,
  onSearchTextSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  variants: PropTypes.array,
}

TextInputTagsList.defaultProps = {
  values: [],
  variants: [],
  placeholder: '',
}

export default TextInputTagsList
