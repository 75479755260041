import { put, cancelled } from 'redux-saga/effects'
import axios from 'axios'

import { incrementStatusCreator, decrementStatusCreator, STATUS_STATES } from 'store/Status'

export function* tryCatchWrapper(message, dataOperation, statusRef, refData = null) {
  let result = {}

  const cancelSource = axios.CancelToken.source()

  yield put(
    incrementStatusCreator({
      statusRef,
      message: `Start ${message}`,
      data: refData,
    })
  )

  let decrementMessage
  let hasError

  try {
    result = yield dataOperation()

    decrementMessage = `Finished ${message}`
  } catch (error) {
    console.error(error)

    hasError = true
    decrementMessage = `Error ${message} - ${error.message}`
  } finally {
    if (yield cancelled()) cancelSource.cancel()

    yield put(
      decrementStatusCreator({
        statusRef,
        message: decrementMessage,
        state: hasError && STATUS_STATES.ERROR,
        data: refData,
      })
    )
  }

  return result
}
