import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, Link } from 'react-router-dom'

import { signUpUser, ERROR_TYPE } from 'store/Account'
import { STATUS_STATES, clearStatus } from 'store/Status'

import Svg from 'components/Svg'
import { ReactComponent as LogoFull } from 'assets/images/logo-full-red.svg'
import Button from 'components/Button'
import TermsAndConditions from './components/TermsAndConditions'
import PasswordRequirements from 'components/PasswordRequirements'

import { paths } from 'router/paths'

import style from './signup.module.scss'

const signUpRef = 'SignUp_submit'

function SignUp() {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [passwordConfirm, setPasswordConfirm] = React.useState('')
  const [givenName, setGivenName] = React.useState('')
  const [familyName, setFamilyName] = React.useState('')
  const [submitted, setSubmitted] = React.useState(false)
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = React.useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const signUpState = useSelector(state => state.Status.statuses[signUpRef]) || {}

  const error =
    signUpState.state === STATUS_STATES.ERROR &&
    (Object.keys(ERROR_TYPE.signUp).find(key => ERROR_TYPE.signUp[key] === signUpState.data) ||
      'default')

  useEffect(() => {
    if (submitted && signUpState.state === STATUS_STATES.FINISHED)
      history.push(paths.signUpConfirmation, { username, password })
  }, [error, submitted, history, signUpState.state, password, username])

  const clearState = () => signUpState.state && dispatch(clearStatus({ clearStatus: signUpRef }))

  useEffect(
    () => clearState,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const passwordsMatch = password === passwordConfirm

  const submitSignUpForm = e => {
    e.preventDefault()

    if (passwordsMatch) {
      dispatch(
        signUpUser({
          username,
          password,
          givenName,
          familyName,
          statusRef: signUpRef,
          termsAccepted: termsAndConditionsAccepted,
        })
      )
      setSubmitted(true)
    }
  }

  return (
    <div className={style.base}>
      <div className={style.signUpContainer}>
        <Svg
          SvgImage={LogoFull}
          dataTestId='elute-logo'
          alt={t('GLOBAL.logoAlt')}
          variant='logoFullMedium'
        />

        <form onSubmit={submitSignUpForm}>
          <div>
            <label className={style.label} htmlFor='login-email'>
              {t('GLOBAL.email')}
            </label>

            <input
              id='login-email'
              type='email'
              className={style.input}
              placeholder={t('GLOBAL.emailExample')}
              onChange={e => {
                setUsername((e.target.value || '').toLowerCase())
                clearState()
                setSubmitted(false)
              }}
              required
            />
          </div>

          <div>
            <label className={style.label} htmlFor='login-password'>
              {t('GLOBAL.password')}
            </label>

            <input
              id='login-password'
              type='password'
              className={style.input}
              onChange={e => {
                setPassword(e.target.value)
                clearState()
                setSubmitted(false)
              }}
              required
            />
          </div>

          <div>
            <label className={style.label} htmlFor='login-password-confirm'>
              {t('GLOBAL.password')}
            </label>

            <input
              id='login-password-confirm'
              type='password'
              className={style.input}
              onChange={e => {
                setPasswordConfirm(e.target.value)
                clearState()
                setSubmitted(false)
              }}
              required
            />
          </div>

          <div>
            <label className={style.label} htmlFor='signUp-givenName'>
              {t('SignUp.givenName')}
            </label>

            <input
              id='signUp-givenName'
              type='text'
              className={style.input}
              placeholder={t('SignUp.givenNameExample')}
              onChange={e => setGivenName(e.target.value)}
              required
            />
          </div>

          <div>
            <label className={style.label} htmlFor='signUp-familyName'>
              {t('SignUp.familyName')}
            </label>

            <input
              id='signUp-familyName'
              type='text'
              className={style.input}
              placeholder={t('SignUp.familyNameExample')}
              onChange={e => setFamilyName(e.target.value)}
              required
            />
          </div>

          <PasswordRequirements password={password} passwordsMatch={password && passwordsMatch} />

          <TermsAndConditions
            termsAndConditionsAccepted={termsAndConditionsAccepted}
            setTermsAndConditionsAccepted={accepted => setTermsAndConditionsAccepted(accepted)}
          ></TermsAndConditions>

          {error && <div className={style.error}>{t(`SignUp.errors.${error}`)}</div>}

          <Button type='submit'>{t('GLOBAL.signUp')}</Button>
        </form>

        <div className={style.linkContainer}>
          <Link to={paths.signUpConfirmation}>{t('SignUp.gotTheCode')}</Link>
          <Link to={paths.logIn}>{t('SignUp.alreadySignedUp')}</Link>
        </div>
      </div>
    </div>
  )
}

export default SignUp
