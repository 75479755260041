import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { signInUser, ERROR_TYPE } from 'store/Account'
import { STATUS_STATES, clearStatus } from 'store/Status'

import Svg from 'components/Svg'
import { ReactComponent as LogoFull } from 'assets/images/logo-full-red.svg'
import Button from 'components/Button'
import { paths } from 'router/paths'

import style from './login.module.scss'

const signInRef = 'LogIn_submit'

function LogIn() {
  const location = useLocation()
  const [username, setUsername] = React.useState(location?.state?.username)
  const [password, setPassword] = React.useState('')

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const signInState = useSelector(state => state.Status.statuses[signInRef]) || {}

  const error =
    signInState.state === STATUS_STATES.ERROR &&
    (Object.keys(ERROR_TYPE.signIn).find(key => ERROR_TYPE.signIn[key] === signInState.data) ||
      'default')

  const clearState = () => signInState.state && dispatch(clearStatus({ statusRef: signInRef }))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearState, [])

  const userEmail = location.state?.username
  const userPassword = location.state?.password
  useEffect(() => {
    if (userEmail && userPassword)
      dispatch(signInUser({ username: userEmail, password: userPassword, statusRef: signInRef }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (error === ERROR_TYPE.signIn.userNotConfirmed) history.push(paths.signUpConfirmation)
  }, [error, history])

  const submitLogInForm = e => {
    e.preventDefault()

    dispatch(signInUser({ username, password, statusRef: signInRef }))
  }

  localStorage.setItem(
    'token',
    'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiI5ZTI3MDQyZC0xMzBjLTRkMjktYTUxNC1kMmIxYzk5ZWU2OTEiLCJpYXQiOjE2NDkyNTYwNzUsImV4cCI6MTY0OTI1NzI3NSwiaWQiOiIwNjU0YmVkYy1kYTdmLTQ5YTMtOWQ0Ni0xNDBlNWFjMjIyNmYifQ.EO_8LH8g6EjWnFoGmPe8DQX7Aiy9t69QHnyeT3HFBXmXmSPGSuTWaZnNZ9_WMK4pu0lRyyUvDDwsHXtCb_ucVw'
  )

  localStorage.setItem(
    'refreshToken',
    'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiI5ZjY4YjM3ZS0wYWZkLTQwMTAtOGUxZS1mNWJiZDQzMTBjNGUiLCJpYXQiOjE2NDkyNTYwNzUsImV4cCI6MTY1MDEyMDA3NSwiaWQiOiIwNjU0YmVkYy1kYTdmLTQ5YTMtOWQ0Ni0xNDBlNWFjMjIyNmYifQ.Tm1R2Wb6oX94rBldvStiq6TSwYWzlUqpdQqTiJy2t5OG5BN-SkRfc71sAc2NVDEBEG7Zz_rzYYdb7-FW233vrA'
  )

  return (
    <div className={style.base}>
      <div className={style.loginContainer}>
        <Svg
          SvgImage={LogoFull}
          dataTestId='elute-logo'
          alt={t('GLOBAL.logoAlt')}
          variant='logoFullMedium'
        />

        {userEmail && userPassword && (
          <div className={style.successMessage}>{t('LogIn.autoLogin')}</div>
        )}

        <form onSubmit={submitLogInForm}>
          <div>
            <label htmlFor='login-email'>{t('GLOBAL.email')}</label>
            <input
              data-testid='login-page-email'
              id='login-email'
              type='email'
              placeholder={t('GLOBAL.emailExample')}
              value={username}
              onChange={e => setUsername(e.target.value)}
              required
            />
          </div>

          <div>
            <label htmlFor='email-pasword'>{t('GLOBAL.password')}</label>
            <input
              data-testid='login-page-password'
              id='email-pasword'
              type='password'
              onChange={e => {
                setPassword(e.target.value)
                clearState()
              }}
              required
            />
          </div>

          {error && <div className={style.error}>{t(`LogIn.errors.${error}`)}</div>}

          <Button dataTestId='login-page-login-button' type='submit'>
            {t('GLOBAL.logIn')}
          </Button>
        </form>

        <div className={style.linkContainer}>
          <Link to={paths.forgotPassword}>{t('LogIn.forgotPassword')}</Link>
          <Link to={paths.signUp}>{t('GLOBAL.signUp')}</Link>
        </div>
      </div>
    </div>
  )
}

export default LogIn
