import 'intersection-observer'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

import { wrapInProviders } from './providers'
import App from 'containers/App'
import Amplify from 'aws-amplify'
import { amplifyConfig } from './amplifyConfig'

import './index.scss'

Amplify.configure(amplifyConfig)

ReactDOM.render(wrapInProviders(<App />).root, document.getElementById('root'))
