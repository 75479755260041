import { saveToLocalStore, loadFromLocalStore } from 'store/localStorage'

const UI_STATE_NAME = `UI_STATE`
const storedUiState = loadFromLocalStore(UI_STATE_NAME)

export const PATENT_UI_ELEMENTS = {
  citations: 'citationsListBox',
  bibliography: 'bibliographyBox',
  images: 'imagesBox',
  claims: 'claims',
  abstract: 'abstract',
  description: 'description',
  sourceText: 'sourceText',
}

/*************************************************/
/** Constants **/

const SET_EXPAND = 'interface/SET_EXPAND'
const CLEAR = 'interface/CLEAR_STATE'

/*************************************************/
/** Action creators **/
export const setExpandStateCreator = payload => ({ type: SET_EXPAND, payload })
export const clearStateCreator = payload => ({ type: CLEAR, payload })

/*************************************************/
/** Selectors **/
export const allStatesSelector = state => state.Interface.states
export const elementStateSelector = (state, parentName, childName) => {
  const parentValue = state.Interface.states[parentName] || {}
  return childName ? parentValue[childName] : parentValue
}

/*************************************************/
/** Reducer **/
const initialState = {
  states: storedUiState || {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXPAND: {
      const stateToSave = getElementState(state.states, action.payload)
      saveToLocalStore(UI_STATE_NAME, stateToSave)

      return {
        ...state,
        states: stateToSave,
      }
    }

    default:
      return state
  }
}

function getElementState(states, payload) {
  const existingValues = states[payload.parent]

  return {
    ...states,
    [payload.parent]: {
      ...existingValues,
      ...payload.child,
    },
  }
}
