import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import Svg from 'components/Svg'
import { ReactComponent as ScrollTopButton } from 'assets/images/scroll-top.svg'

import styles from './scrollToTop.module.scss'

function ScrollToTopButton({ areProjectsOpen }) {
  const { t } = useTranslation()
  const [showScrollButton, setShowScrollButton] = React.useState(false)

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    const checkScrollTop = () => {
      const scrollY = window.pageYOffset
      if (!showScrollButton && scrollY > 400) {
        setShowScrollButton(true)
      } else if (showScrollButton && scrollY <= 400) {
        setShowScrollButton(false)
      }
    }

    window.addEventListener('scroll', checkScrollTop)
    return () => {
      window.removeEventListener('scroll', checkScrollTop)
    }
  }, [showScrollButton, setShowScrollButton])

  return (
    <div className={`${styles.base} ${areProjectsOpen ? styles.projectsOpened : ''}`}>
      <div
        className={`${styles.scrollBtn} ${showScrollButton ? styles.showScrollButton : ''}`}
        onClick={scrollToTop}
      >
        <Svg SvgImage={ScrollTopButton} dataTestId='scrollTop' variant='darkenGrey' />
        <div>{t('SearchDetailsPage.scrollToTop')}</div>
      </div>
    </div>
  )
}

export default ScrollToTopButton
