export const FILTER_SECTIONS = {
  claims: 'claims',
  claimsAnd: 'and',
  claimsNot: 'not',
  claimsOr: 'or',
  applicants: 'applicants',
  inventors: 'inventors',
  classificationsIPCR: 'classificationsIPCR',
  countries: 'countries',
  publicationDateStart: 'publicationDateStart',
  publicationDateEnd: 'publicationDateEnd',
  priorityDateStart: 'priorityDateStart',
  priorityDateEnd: 'priorityDateEnd',
  terms: 'terms',
}

export const updateFilterArray = ({ currentValues, value, shouldAdd }) => {
  if (shouldAdd) {
    if (!currentValues.includes(value)) return [value, ...currentValues]
    return currentValues
  }

  const idx = currentValues.indexOf(value)
  return currentValues.slice(0, idx).concat(currentValues.slice(idx + 1))
}

export const updateFilterSection = ({ section, filters, value, subSection }) => {
  const currentValues = subSection ? filters?.[section][subSection] : filters?.[section]

  if (section === FILTER_SECTIONS.claims) {
    const updatedArray = updateFilterArray({ currentValues, value, shouldAdd: false })
    const { [subSection]: removeMe, ...rest } = filters?.[section]

    return updatedArray.length ? { ...filters?.[section], [subSection]: updatedArray } : { ...rest }
  } else if (section === FILTER_SECTIONS.terms)
    updateFilterArray({ currentValues, value, shouldAdd: false })
  else if (
    [
      FILTER_SECTIONS.publicationDateStart,
      FILTER_SECTIONS.publicationDateEnd,
      FILTER_SECTIONS.priorityDateStart,
      FILTER_SECTIONS.priorityDateEnd,
    ].includes(section)
  )
    return null

  return updateFilterArray({ currentValues, value, shouldAdd: false })
}
