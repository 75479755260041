import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import Button from 'components/Button'

import styles from './confirmCancelButtonGroup.module.scss'

function ConfirmCancelButtonGroup({ onCancel, onConfirm }) {
  const { t } = useTranslation()

  return (
    <div className={styles.base}>
      <Button dataTestId='confirm-dialog-cancel-button' onClick={onCancel}>
        {t('ConfirmCancelButtonGroup.cancel')}
      </Button>
      <Button dataTestId='confirm-dialog-confirm-button' onClick={onConfirm}>
        {t('ConfirmCancelButtonGroup.confirm')}
      </Button>
    </div>
  )
}

ConfirmCancelButtonGroup.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

ConfirmCancelButtonGroup.defaultProps = {}

export default ConfirmCancelButtonGroup
