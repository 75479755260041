export const STORAGE_ITEMS = { user: 'USER', interface: 'UI_STATE' }

const MESSAGE = 'There has been an error storing data in local storage'

export const saveToLocalStore = (name, item) => {
  try {
    const itemSerialized = JSON.stringify(item)
    localStorage.setItem(name, itemSerialized)
  } catch (e) {
    console.log(MESSAGE, e)
  }
}

export const loadFromLocalStore = name => {
  try {
    const item = localStorage.getItem(name)
    if (item === null) return undefined
    return JSON.parse(item)
  } catch (e) {
    console.log(MESSAGE, e)
    return undefined
  }
}

export const removeFromLocalStore = name => {
  try {
    localStorage.removeItem(name)
  } catch (e) {
    console.log(MESSAGE, e)
  }
}
