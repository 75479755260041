import { fork } from 'redux-saga/effects'

import Search from './Search'
import Account from './Account'
import Document from './Document'
import Pinger from './Pinger'
import Updater from './Updater'
import Project from './Project'

export default function* rootSaga() {
  yield fork(Search)
  yield fork(Account)
  yield fork(Document)
  yield fork(Pinger)
  yield fork(Updater)
  yield fork(Project)
}
