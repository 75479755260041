import { takeLatest, put, call } from 'redux-saga/effects'

import { tryCatchWrapper } from 'store/wrappers'

import * as ApiService from 'services/api'

// Constants
const UPDATE_CACHE_MAINTENANCE = 'pinger/UPDATE_CACHE_MAINTENANCE'
const GET_MAINTENANCE_STATUS = 'pinger/GET_MAINTENANCE_STATUS'

// Action Creators
export const getMaintenanceStatus = payload => ({ type: GET_MAINTENANCE_STATUS, payload })

// Selectors
export const maintenanceSelector = state => state.Pinger.maintenance

// Sagas
export default function* sagawatcher() {
  yield takeLatest(GET_MAINTENANCE_STATUS, checkMaintenanceStatus)
}

function* checkMaintenanceStatus({ payload: { statusRef } }) {
  const pinglyFile = `/${process.env.REACT_APP_PINGLY_FILE}`

  function* sendRequest() {
    const pinglyContent = yield call(ApiService.getFe, pinglyFile)

    return {
      maintenanceMode: pinglyContent.data.maintenanceMode,
      lastReleaseDate: pinglyContent.data.lastReleaseDate,
    }
  }

  const payload = yield tryCatchWrapper('maintenance status check', sendRequest, statusRef)

  yield put({
    type: UPDATE_CACHE_MAINTENANCE,
    payload,
  })
}

/*************************************************/
/** Reducer **/
const initialState = {
  maintenance: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CACHE_MAINTENANCE:
      return {
        ...state,
        maintenance: { ...state.maintanance, ...action.payload },
      }

    default:
      return state
  }
}
