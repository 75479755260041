export const paths = {
  //TODO: switch paths between reset password and password reminder
  forgotPassword: '/forgot-password',
  forgotPasswordConfirmation: '/forgot-password/confirm',
  passwordReset: '/password-reset',
  signUp: '/register',
  signUpConfirmation: '/register/confirm',
  signUpConfirmationCodeResend: '/register/resend',
  logIn: '/login',
  searchPage: '/search',
  searches: '/searches',
  archivedSearches: '/searches/archived',
  patentView: '/view/patent',
  pageNotFound: '/pageNotFound',
}
