import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import TextInput from 'components/TextInput'

import styles from './projectsSelector.module.scss'

const addPatentStatusRef = 'ProjectsSelector_addPatentRef'

function ProjectsSelector({ patent, projects, addPatent, hide }) {
  const [filter, setFilter] = React.useState('')

  const { t } = useTranslation()

  const regExp = new RegExp(`${filter.replace(/\s+/g, ' ')}`, 'i')
  const projectsFiltered = Object.values(projects).filter(project => regExp.test(project.name))

  const addPatentToProject = projectId => {
    addPatent({
      name: patent.title,
      patentNum: patent.patentNum,
      projectId,
      statusRef: addPatentStatusRef,
    })
  }
  const projectsRef = useRef()

  const checkIfClickedOutside = e => {
    if (
      projectsRef.current !== e.target &&
      e.target.tagName !== 'INPUT' &&
      e.target.parentElement !== projectsRef.current
    )
      hide()

    if (e.target.parentElement === projectsRef.current) {
      addPatentToProject(e.target.id)
      hide()
    }
  }

  useEffect(() => {
    document.addEventListener('click', checkIfClickedOutside, true)

    return () => {
      document.removeEventListener('click', checkIfClickedOutside, true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`${styles.base}`} ref={projectsRef}>
      <TextInput
        value={filter}
        shouldAutofocus={true}
        onInputValueChange={name => setFilter(name)}
        onEnterKeyPress={() => {}}
        placeholder={t('ProjectsPanel.filterPlaceholeder')}
        variant='bottomBorderOnly'
      />

      {projectsFiltered.map(project => (
        <div className={styles.name} key={project.ID} id={project.ID}>
          {project.name}
        </div>
      ))}
    </div>
  )
}

ProjectsSelector.propTypes = {
  patent: PropTypes.object.isRequired,
  projects: PropTypes.object,
  addPatent: PropTypes.func.isRequired,
  hide: PropTypes.func,
}

ProjectsSelector.defaultProps = {
  projects: {},
}

export default ProjectsSelector
