import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { updatePreprocessedList } from 'store/Search'
import { OPTIONS as SORT_OPTIONS, useSorter } from './useSorter'

import { ReactComponent as Spinner } from 'assets/images/spinner-animated.svg'
import { ReactComponent as SortAlphaAsc } from 'assets/images/sorters/alpha-asc.svg'
import { ReactComponent as SortAlphaDesc } from 'assets/images/sorters/alpha-desc.svg'
import { ReactComponent as SortCountAsc } from 'assets/images/sorters/num-asc.svg'
import { ReactComponent as SortCountDesc } from 'assets/images/sorters/num-desc.svg'
import Term from './components/Term'
import Svg from 'components/Svg'

import style from './termsList.module.scss'
import Button from 'components/Button'

// TODO: possibly switch to using include for terms, instead of exclude,
// to simplify logic, since now we put the value also for included
const TERM_OPERATION = { include: false, exclude: true }

export function TermsList({
  updateTerms,
  rawTerms,
  isPreprocessing,
  updateSearchText,
  triggerPreprocess,
}) {
  const [filterText, setFilterText] = React.useState('')
  const [newTermsText, setNewTermsText] = React.useState('')

  const { t } = useTranslation()

  const numberOfIncludedTerms = React.useMemo(() => {
    let count = 0
    rawTerms.forEach(term => {
      if (!term.exclude) count++
    })
    return count
  }, [rawTerms])

  const filteredTerms = React.useMemo(() => {
    if (filterText) {
      const filterWords = filterText.split(/\s|\t|,|\.|-|_/).filter(Boolean)
      return rawTerms.filter(term => filterWords.some(filter => term.key.includes(filter)))
    }

    return rawTerms
  }, [filterText, rawTerms])

  const { alphaSort, countSort, sortedTerms, switchAlphaSort, switchCountSort } = useSorter(
    filteredTerms
  )

  const AlphaSortComponent = alphaSort === SORT_OPTIONS.descending ? SortAlphaDesc : SortAlphaAsc
  const CountSortComponent = countSort === SORT_OPTIONS.ascending ? SortCountAsc : SortCountDesc

  return (
    <div className={style.base}>
      <div className={style.filterSortBar}>
        <input
          data-testid='search-setup-terms-filter'
          type='text'
          placeholder={t('SearchPage.TermsList.termsFilterPlaceholder')}
          onChange={e => setFilterText(e.target.value)}
        />

        <Svg
          dataTestId='search-setup-terms-alpha-sort'
          SvgImage={AlphaSortComponent}
          onClick={switchAlphaSort}
          alt={t('SearchPage.TermsList.sortAlphabetically')}
          variant={alphaSort ? 'active' : ''}
        />

        <Svg
          dataTestId='search-setup-terms-numeric-sort'
          SvgImage={CountSortComponent}
          onClick={switchCountSort}
          alt={t('SearchPage.TermsList.sortByCount')}
          variant={countSort ? 'active' : ''}
        />
      </div>

      <div data-testid='search-setup-terms-list'>
        {isPreprocessing ? (
          <div className={style.loadingContainer}>
            <Svg
              SvgImage={Spinner}
              alt={t('GLOBAL.spinnerAlt')}
              dataTestId='spinner'
              variants={['loader', 'large']}
            />
          </div>
        ) : (
          <div className={style.terms}>
            <div className={style.addTerm}>
              <input
                data-testid='search-setup-add-terms'
                type='text'
                value={newTermsText}
                placeholder={t('SearchPage.TermsList.addTerms')}
                onChange={e => setNewTermsText(e.target.value)}
              />

              <div className={style.buttonWrapper}>
                <Button
                  onClick={() => {
                    if (newTermsText) {
                      updateSearchText(newTermsText)
                      triggerPreprocess(newTermsText)
                      setNewTermsText('')
                    }
                  }}
                  variant={'searchTermAction'}
                >
                  +
                </Button>
              </div>
            </div>
            {sortedTerms.map(term => (
              <Term
                key={term.key}
                term={term}
                excludeTerm={() => updateTerms(term, TERM_OPERATION.exclude)}
                addTerm={() => updateTerms(term, TERM_OPERATION.include)}
              />
            ))}
          </div>
        )}
      </div>

      <div className={style.termsCount} data-testid='search-setup-summary-text'>
        {t('SearchPage.TermsList.termsCount', {
          termsCount: rawTerms.length,
          termsToBeSubmittedCount: numberOfIncludedTerms,
        })}
      </div>
    </div>
  )
}

const ConnectedTermsList = connect(null, (dispatch, { id, rawTerms }) => ({
  updateTerms(termToUpdate, exclude) {
    const updatedList = rawTerms.map(term =>
      termToUpdate.key === term.key ? { ...term, exclude } : term
    )
    dispatch(updatePreprocessedList({ id, dataToUpdate: { terms: updatedList } }))
  },
}))(TermsList)

ConnectedTermsList.propTypes = {
  rawTerms: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  status: PropTypes.string,
  preprocessRef: PropTypes.string,
  triggerPreprocess: PropTypes.func,
}

export default ConnectedTermsList
