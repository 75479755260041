import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { sendPasswordReminderConfirmation, ERROR_TYPE } from 'store/Account'
import { STATUS_STATES, clearStatus } from 'store/Status'

import Svg from 'components/Svg'
import { ReactComponent as LogoFull } from 'assets/images/logo-full-red.svg'
import Button from 'components/Button'
import PasswordRequirements from 'components/PasswordRequirements'

import { paths } from 'router/paths'

import style from './forgotPasswordConfirmation.module.scss'

const passwordConfirmationRef = 'ForgotPasswordConfirmation_confirmation'

function ForgotPasswordConfirmation() {
  const location = useLocation()

  const [code, setCode] = React.useState('')
  const [username, setUsername] = React.useState(location.state.username)
  const [newPassword, setNewPassword] = React.useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('')
  const [confirmationSent, setConfirmationSent] = React.useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const confirmPasswordState =
    useSelector(state => state.Status.statuses[passwordConfirmationRef]) || {}

  const error =
    confirmPasswordState.state === STATUS_STATES.ERROR &&
    (Object.keys(ERROR_TYPE.forgotPasswordConfirmation).find(
      key => ERROR_TYPE.forgotPasswordConfirmation[key] === confirmPasswordState.data
    ) ||
      'default')

  const clearState = () => {
    if (confirmPasswordState.state) dispatch(clearStatus({ statusRef: passwordConfirmationRef }))
    setConfirmationSent(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearState, [])

  const passwordsMatch = newPassword === newPasswordConfirm

  const success =
    passwordsMatch && confirmationSent && confirmPasswordState.state === STATUS_STATES.FINISHED

  useEffect(() => {
    if (confirmPasswordState.state === STATUS_STATES.FINISHED)
      history.push(paths.logIn, { username, password: newPassword })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPasswordState.state])

  const confirmNewPassword = async e => {
    e.preventDefault()

    if (passwordsMatch) {
      dispatch(
        sendPasswordReminderConfirmation({
          code,
          newPassword,
          username,
          statusRef: passwordConfirmationRef,
        })
      )
      setConfirmationSent(true)
    }
  }

  return (
    <div className={style.base}>
      <div className={style.passwordConfirmationContainer}>
        <Svg
          SvgImage={LogoFull}
          dataTestId='elute-logo'
          alt={t('GLOBAL.logoAlt')}
          variant='logoFullMedium'
        />
        <p className={style.info}>{t('ForgotPassword.info')}</p>

        <form onSubmit={confirmNewPassword}>
          <div>
            <label htmlFor='username'>{t('GLOBAL.email')}</label>
            <input
              id='username'
              type='text'
              value={username}
              placeholder={t('GLOBAL.emailExample')}
              onChange={e => {
                setUsername(e.target.value)
                clearState()
              }}
              required
            />
          </div>

          <div>
            <label htmlFor='verificationCode'>{t('GLOBAL.verificationCode')}</label>
            <input
              id='verificationCode'
              type='text'
              value={code}
              placeholder={t('GLOBAL.verificationCodeExample')}
              onChange={e => {
                setCode(e.target.value)
                clearState()
              }}
              required
            />
          </div>

          <div>
            <label htmlFor='password'>{t('ForgotPassword.newPassword')}</label>
            <input
              id='password'
              type='password'
              value={newPassword}
              onChange={e => {
                setNewPassword(e.target.value)
                clearState()
              }}
              autoComplete='off'
              required
            />
          </div>

          <div>
            <label htmlFor='passwordConfirm'>{t('ForgotPassword.newPasswordConfirm')}</label>
            <input
              id='passwordConfirm'
              type='password'
              value={newPasswordConfirm}
              onChange={e => {
                setNewPasswordConfirm(e.target.value)
                clearState()
              }}
              autoComplete='off'
              required
            />
          </div>

          <PasswordRequirements
            password={newPassword}
            passwordsMatch={newPassword && passwordsMatch}
          />

          {error && <div className={style.error}>{t(`ForgotPassword.errors.${error}`)}</div>}

          {!success && <Button type='submit'>{t('ForgotPassword.changePassword')}</Button>}
        </form>

        {success ? (
          <Button>
            <Link to={paths.logIn}>{t('GLOBAL.logIn')}</Link>
          </Button>
        ) : (
          <div className={style.linkContainer}>
            {/* TODO: possibly replace with triggering email from here */}
            <Link to={paths.forgotPassword}>{t('GLOBAL.resendCodeMessage')}</Link>{' '}
            <Link to={paths.logIn}>{t('GLOBAL.logIn')}</Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default ForgotPasswordConfirmation
