import axios from 'axios'
import { serialize } from 'object-to-formdata'
import { SEARCH_STATUS } from 'store/Search/constants'

import { loadFromLocalStore, STORAGE_ITEMS } from 'store/localStorage'

const apiUrl = `${process.env.REACT_APP_BACKEND_URL}`

/********************************************************
 * Build URL and query parms etc
 ********************************************************/
//TODO: create functions instead of constant for URLs
// export const postSearch = (data, category) => {
//   const url = `search${category ? '/' + category: ''}`;
//   return get(url, data);
// }

export const formatGetUrl = (endpoint = '', data) => {
  const stringQuery = Object.entries(data)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')

  return `/${endpoint}${stringQuery && `?${stringQuery}`}`
}

export const SEARCH_EDPOINTS = {
  preprocess: '/preprocess',
  search: '/search',
  retrieve: '/retrieve',
  retrieveResults: '/retrieve/results',
  ResultsMarkup: '/retrieve/results/markup',
  document: '/document',
}

export const SEARCH_TYPE = {
  active: 'active',
  archive: 'archive',
}

//TODO: put all format body consts in a separate file: body formatters
export const defaultFilters = {
  priorityDateStart: null,
  priorityDateEnd: null,
  publicationDateStart: null,
  publicationDateEnd: null,
  countries: [
    { name: 'AT', value: 'AT' },
    { name: 'BE', value: 'BE' },
    { name: 'CA', value: 'CA' },
    { name: 'CH', value: 'CH' },
    { name: 'CN', value: 'CN' },
    { name: 'DE', value: 'DE' },
    { name: 'DK', value: 'DK' },
    { name: 'EP', value: 'EP' },
    { name: 'ES', value: 'ES' },
    { name: 'FI', value: 'FI' },
    { name: 'FR', value: 'FR' },
    { name: 'GB', value: 'GB' },
    { name: 'JP', value: 'JP' },
    { name: 'KR', value: 'KR' },
    { name: 'NL', value: 'NL' },
    { name: 'NO', value: 'NO' },
    { name: 'RU', value: 'RU' },
    { name: 'SE', value: 'SE' },
    { name: 'TW', value: 'TW' },
    { name: 'US', value: 'US' },
    { name: 'WO', value: 'WO' },
  ],
}

// TO be removed. Formatters shouldn't be needed and if/when they are used then they don't belong in the API service.
// export const formatArchiveBody = (searchId, userId) =>
// `searchID=${searchId}&userName=${userId}&status=${SEARCH_STATUS.archive}`

export const formatUpdateSearchBody = (searchId, userId, name) =>
  `searchID=${searchId}&userName=${userId}&name=${name}`

// export const formatDeleteBody = (searchId, userId) => ({ userName: userId, searchID: searchId })

export const formatRetrieveSearchesBody = (id, searchType, isPolling) => {
  const retrieveType = 'general' //TODO: find list of types of retrieve
  const userName = id //TODO: username is actually an id in the BE

  return { retrieveType, userName, status: searchType, isPolling }
}

// export const formatRetrieveSearchById = (id, userId) => ({
//   userName: userId,
//   searchID: id,
// })
/// End to be removed /////

/********************************************************
 * Add any headers etc needed for every call
 ********************************************************/
function axiosConfig(params, headers = {}) {
  const user = loadFromLocalStore(STORAGE_ITEMS.user)

  const token = user?.accessToken?.jwtToken

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      //   "X-localization": getLocale(),
      ...headers,
    },
  }

  config.params = {}

  if (params) {
    config.params = params

    Object.keys(config.params).map(function (key, index) {
      if (config.params[key] === 'true' || config.params[key] === true) {
        config.params[key] = 1
      }
      if (config.params[key] === 'false' || config.params[key] === false) {
        config.params[key] = 0
      }

      return null
    })
  }

  return config
}

/********************************************************
 * Actual calls made here
 ********************************************************/

// GET
export function get(url, data, cancelToken) {
  return axios.get(apiUrl + url, axiosConfig(data), { cancelToken })
}

//TODO: refactor
export function getFe(url, data) {
  return axios.get(url, axiosConfig(data))
}

// POST
export function post(url, data, cancelToken, headers = {}) {
  const config = axiosConfig(data, headers)

  const configToSend = { headers: config.headers, cancelToken }

  return axios.post(apiUrl + url, config.params, configToSend)
}

// PUT
export function put(url, data, cancelToken) {
  const config = axiosConfig(data)

  const configToSend = { headers: config.headers, cancelToken }

  return axios.put(apiUrl + url, config.params, configToSend)
}

// DELETE
export function deleteIt(url, data, cancelToken, headers = {}) {
  const config = axiosConfig(data, headers)

  const configToSend = { headers: config.headers, cancelToken }
  console.log('deleting', cancelToken)

  return axios.delete(apiUrl + url, { data: config.params }, configToSend)
}

// POST form data as x-www-form-urlencoded
// Expects and object of form values
export function postForm(url, data, cancelToken, headers = {}) {
  const config = axiosConfig(data, headers)

  const configToSend = {
    headers: config.headers,
    cancelToken,
  }
  // const body = config.params;

  const options = {
    /**
     * whether or not to include array indices in FormData keys
     * defaults to false
     */
    indices: true,

    /**
     * treat null values like undefined values and ignore them
     * defaults to false
     */
    nullsAsUndefineds: false,

    /**
     * store arrays even if they're empty
     * defaults to false
     */
    allowEmptyArrays: true,
  }

  const formData = serialize(config.params, options)
  // config.headers['Content-Type'] = `multipart/form-data; boundary=${data._boundary}`;
  config.headers['Content-Type'] = `application/x-www-form-urlencoded`

  return axios.post(apiUrl + url, formData, configToSend)
}

export const generateCancelSource = () => {
  const CancelToken = axios.CancelToken
  let cancel

  return {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),

    cancel,
  }
}
