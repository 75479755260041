import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import styles from './textInput.module.scss'

function TextInput({
  value,
  placeholder,
  onInputValueChange,
  onEnterKeyPress,
  disabled,
  shouldAutofocus,
  onFocus,
  onBlur,
  variant,
  variants,
  type,
}) {
  const { t } = useTranslation()

  return (
    <input
      className={`${styles.base} ${variant ? styles[variant] : ''} ${variants
        .map(v => styles[v])
        .join(' ')}`}
      type={type || 'text'}
      onChange={ev => onInputValueChange(ev.target.value)}
      placeholder={placeholder || t('Filter.TextInput.placeholder')}
      value={value}
      onKeyPress={e => e.key === 'Enter' && onEnterKeyPress()}
      disabled={disabled}
      autoFocus={shouldAutofocus}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
}

TextInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onInputValueChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
  variants: PropTypes.array,
  disabled: PropTypes.bool,
  shouldAutofocus: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
}

TextInput.defaultProps = {
  value: '',
  disabled: false,
  variants: [],
}

export default TextInput
