export const amplifyConfig = {
  Auth: {
    identityPoolId: `${process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID}`,
    region: `${process.env.REACT_APP_COGNITO_AWS_REGION}`,
    userPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID}`,
  },
  Storage: {
    AWSS3: {
      bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
      region: `${process.env.REACT_APP_COGNITO_AWS_REGION}`,
    },
    MAINTENANCE: {
      bucket: `${process.env.REACT_APP_AWS_MAINTENANCE_BUCKET}`,
      region: `${process.env.REACT_APP_COGNITO_AWS_REGION}`,
    },
  },
}
