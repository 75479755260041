import { takeLatest, takeEvery, put, select, call, cancelled } from 'redux-saga/effects'
import axios from 'axios'

import { userDataSelector } from 'store/Account'
import { formatGetUrl, SEARCH_EDPOINTS, get } from 'services/api'
import * as ApiServiceV1 from 'services/api-v1'
import { formatNames } from 'store/topResultsFormatters'
import { incrementStatusCreator, decrementStatusCreator, STATUS_STATES } from 'store/Status'
import { tryCatchWrapper } from 'store/wrappers'

// Constants
const GET_PATENT_DOCUMENT = 'document/GET_PATENT_DOCUMENT'
const GET_PATENT_PDF = 'document/GET_PATENT_PDF'
const GET_PATENT_IMAGES = 'document/GET_PATENT_IMAGES'

const UPDATE_CACHE_PATENTS = 'document/UPDATE_CACHE_PATENTS'
const UPDATE_CACHE_IMAGES = 'document/UPDATE_CACHE_IMAGES'

export const DOCUMENT_TYPE = {
  patent: 'patent',
}

// Action Creators
export const getPatentDocument = payload => ({ type: GET_PATENT_DOCUMENT, payload })
export const getPatentDocumentPdf = payload => ({ type: GET_PATENT_PDF, payload })
export const getPatentDocumentImages = payload => ({ type: GET_PATENT_IMAGES, payload })

// Selectors
export const patentsSelector = state => state.Document.patents
export const imagesSelector = state => state.Document.images

// Sagas
export default function* sagawatcher() {
  yield takeEvery(GET_PATENT_DOCUMENT, getPatent)
  yield takeEvery(GET_PATENT_PDF, getPatentPdf)
  yield takeLatest(GET_PATENT_IMAGES, getPatentImages)
}

function* getPatent({ payload: { docType, docName, statusRef } }) {
  if (!docType || !docName) return //TODO: this should be an error

  yield put(
    incrementStatusCreator({
      statusRef,
      message: `Start getting patent doc ${docName}`,
    })
  )

  if (!/[a-zA-Z]{2}\d{4}/g.test(docName)) {
    yield put(
      decrementStatusCreator({
        statusRef,
        message: `Invalid patent number`,
        state: STATUS_STATES.INVALID,
      })
    )
    return
  }
  const user = yield select(userDataSelector)

  let payload = {}
  const cancelSource = axios.CancelToken.source()
  try {
    const url = formatGetUrl(SEARCH_EDPOINTS.document, {
      docType,
      docName,
      userName: user.id,
    })
    const result = yield call(get, url)

    const applicants = formatNames(result.data.applicants)
    const inventors = formatNames(result.data.inventors)

    payload = { [docName]: { ...result.data, applicants, inventors } }

    yield put(
      decrementStatusCreator({
        statusRef,
        message: `Patent doc received - ${docName}`,
      })
    )
  } catch (error) {
    if (error.response?.status === 404) {
      yield put(
        decrementStatusCreator({
          statusRef,
          message: `Error getting patent doc - ${docName} - ${error.message}`,
          state: STATUS_STATES.NOT_FOUND,
        })
      )
    } else {
      yield put(
        decrementStatusCreator({
          statusRef,
          message: `Error getting patent doc - ${docName} - ${error.message}`,
          state: STATUS_STATES.ERROR,
        })
      )
    }
  } finally {
    if (yield cancelled()) cancelSource.cancel()
  }

  yield put({ type: UPDATE_CACHE_PATENTS, payload })
}

function* getPatentPdf({ payload: { docType, patentNum, statusRef } }) {
  if (!docType || !patentNum) return

  const user = yield select(userDataSelector)

  const docName = `${patentNum}.pdf`

  function* sendRequest() {
    const url = formatGetUrl(SEARCH_EDPOINTS.document, {
      docType,
      docName,
      userName: user.id,
    })

    const result = yield call(get, url)

    blobToPdf(result.data, docName)
  }

  yield tryCatchWrapper(
    `Start downloading patent PDF ${patentNum}.`,
    sendRequest,
    statusRef,
    patentNum
  )
}

function* getPatentImages({ payload: { docType, docID, statusRef } }) {
  if (!docType || !docID) return

  yield put(
    incrementStatusCreator({
      statusRef,
      message: `Start getting patent images ${docID}`,
    })
  )

  const user = yield select(userDataSelector)

  let payload = {}

  const cancelSource = axios.CancelToken.source()

  try {
    const result = yield call(
      ApiServiceV1.get,
      ApiServiceV1.ENDPOINTS.documentImages(docType, docID)
    )

    payload = { [docID]: { ...result } }

    yield put(
      decrementStatusCreator({
        statusRef,
        message: `Patent images received - ${docID}`,
      })
    )
  } catch (error) {
    console.log('There was an issue with the api call ', error)
    yield put(
      decrementStatusCreator({
        statusRef,
        message: `Error getting patent images - ${docID} - ${error.message}`,
        state: STATUS_STATES.ERROR,
      })
    )
  } finally {
    if (yield cancelled()) cancelSource.cancel()
  }

  yield put({ type: UPDATE_CACHE_IMAGES, payload })
}
/*************************************************/
/** Reducer **/
const initialState = {
  patents: {},
  images: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CACHE_PATENTS:
      return {
        ...state,
        patents: { ...state.patents, ...action.payload },
      }
    case UPDATE_CACHE_IMAGES:
      return {
        ...state,
        images: { ...state.images, ...action.payload },
      }

    default:
      return state
  }
}

//Utils

function blobToPdf(blob, docName) {
  const binaryString = window.atob(blob)
  const binaryLen = binaryString.length
  const bytes = new Uint8Array(binaryLen)

  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }

  const newBlob = new Blob([bytes], {
    type: 'application/pdf',
  })

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob)
  const link = document.createElement('a')
  link.href = data
  link.download = docName
  link.click()

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data)
  }, 100)
}
