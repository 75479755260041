import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PageLink from './components/PageLink'

import styles from './pagination.module.scss'

function Pagination({ lastPageNumber, onPageChange }) {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = React.useState(0)

  const onPageLinkClick = pageNumber => {
    onPageChange(pageNumber)
    setCurrentPage(pageNumber)
  }

  return (
    <div className={styles.base}>
      <PageLink
        pageLinkName={'<<'}
        disabled={currentPage === 0}
        onClick={() => onPageLinkClick(0)}
      />

      <PageLink
        pageLinkName={'<'}
        disabled={currentPage === 0}
        onClick={() => onPageLinkClick(currentPage - 1)}
      />

      <span>
        {t('SearchDetailsPage.Pagination.page') +
          (currentPage + 1) +
          t('SearchDetailsPage.Pagination.of') +
          (lastPageNumber + 1)}
      </span>

      <PageLink
        pageLinkName={'>'}
        disabled={currentPage === lastPageNumber}
        onClick={() => onPageLinkClick(currentPage + 1)}
      />

      <PageLink
        pageLinkName={'>>'}
        disabled={currentPage === lastPageNumber}
        onClick={() => onPageLinkClick(lastPageNumber)}
      />
    </div>
  )
}

Pagination.propTypes = {
  lastPageNumber: PropTypes.number,
  onPageChange: PropTypes.func,
}

export default Pagination
