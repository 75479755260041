import React from 'react'

export const OPTIONS = {
  unsorted: 0,
  ascending: 1,
  descending: 2,
}

export function useSorter(terms) {
  const [sort, setSort] = React.useState({ alpha: OPTIONS.unsorted, count: OPTIONS.descending })

  const sortedTerms = React.useMemo(() => {
    return [...terms].sort((a, b) => {
      if (sort.alpha)
        return sort.alpha === OPTIONS.ascending
          ? a.key.localeCompare(b.key)
          : b.key.localeCompare(a.key)

      if (sort.count && a.value !== b.value)
        return sort.count === OPTIONS.ascending ? a.value - b.value : b.value - a.value

      return a.key.localeCompare(b.key)
    })
  }, [terms, sort])

  return {
    alphaSort: sort.alpha,
    countSort: sort.count,
    switchCountSort() {
      setSort({
        count: sort.count === OPTIONS.descending ? OPTIONS.ascending : OPTIONS.descending,
        alpha: OPTIONS.unsorted,
      })
    },
    switchAlphaSort() {
      setSort({
        alpha: sort.alpha === OPTIONS.ascending ? OPTIONS.descending : OPTIONS.ascending,
        count: OPTIONS.unsorted,
      })
    },
    sortedTerms,
  }
}
