import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Svg from 'components/Svg'
import { ReactComponent as LogoFull } from 'assets/images/logo-full-red.svg'
import Button from 'components/Button'
import { paths } from 'router/paths'

import { resendSignUpUser, ERROR_TYPE } from 'store/Account'
import { STATUS_STATES, clearStatus } from 'store/Status'

import style from './signUpConfirmationCodeResend.module.scss'

const signUpConfirmationCodeResendRef = 'SignUpConfirmationCodeResend_submit'

function SignUpConfirmationCodeResend() {
  const [username, setUsername] = React.useState('')
  const [codeSent, setCodeSent] = React.useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const signUpConfirmationCodeResendState =
    useSelector(state => state.Status.statuses[signUpConfirmationCodeResendRef]) || {}

  const error =
    signUpConfirmationCodeResendState.state === STATUS_STATES.ERROR &&
    (Object.keys(ERROR_TYPE.signUpConfirmationCodeResend).find(
      key => ERROR_TYPE.signUpConfirmationCodeResend[key] === signUpConfirmationCodeResendState.data
    ) ||
      'default')

  const clearState = () => {
    if (signUpConfirmationCodeResendState.state)
      dispatch(clearStatus({ statusRef: signUpConfirmationCodeResendRef }))
    setCodeSent(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearState, [])

  useEffect(() => {
    if (codeSent && signUpConfirmationCodeResendState.state === STATUS_STATES.FINISHED)
      history.push(paths.signUpConfirmation)
  }, [error, codeSent, history, signUpConfirmationCodeResendState.state])

  const submitConfirmationForm = e => {
    e.preventDefault()

    dispatch(resendSignUpUser({ username, statusRef: signUpConfirmationCodeResendRef }))
    setCodeSent(true)
  }

  return (
    <div className={style.base}>
      <div className={style.resendContainer}>
        <Svg
          SvgImage={LogoFull}
          dataTestId='elute-logo'
          alt={t('GLOBAL.logoAlt')}
          variant='logoFullMedium'
        />

        <form onSubmit={submitConfirmationForm}>
          <div>
            <label htmlFor='signupConfirm-username'>{t('GLOBAL.email')}</label>
            <input
              type='text'
              placeholder={t('GLOBAL.emailExample')}
              value={username}
              onChange={e => {
                setUsername(e.target.value)
                clearState()
              }}
              required
            />
          </div>

          {error && <div className={style.error}>{t(`ForgotPassword.errors.${error}`)}</div>}

          <Button type='submit'> {t('GLOBAL.send')}</Button>
        </form>

        <div className={style.linkContainer}>
          <Link to={paths.logIn}>{t('SignUp.alreadySignedUp')}</Link>
          <Link to={paths.signUp}>{t('GLOBAL.signUp')}</Link>{' '}
        </div>
      </div>
    </div>
  )
}

export default SignUpConfirmationCodeResend
