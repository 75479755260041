import { takeLatest, call, put, select, takeEvery } from 'redux-saga/effects'

// eslint-disable-next-line import/no-namespace
import * as ApiService from 'services/api'
import * as constants from './constants'
import * as ApiServiceV1 from 'services/api-v1'

import { formatNames } from 'store/topResultsFormatters'
import { tryCatchWrapper } from 'store/wrappers'

import { saveAsCsv, saveAsExcel } from 'containers/fileExporter'

export const DOWNLOAD_STATUS = { success: 'success', failure: 'failure' }
const RESULTS_COUNT_HEADER_NAME = 'x-results-count'

//TODO - remove this. Used data and data from api should have same shape
const formatSearchDataForStore = ({
  ID,
  state,
  name,
  status,
  created_at,
  updated_at,
  filename,
  terms,
}) => {
  return {
    id: ID,
    state,
    name,
    filename,
    status: constants.SEARCH_STATUS[status] || status, //TODO: update SEARCH_STATUS to include all statuses.
    createDate: created_at,
    modifiedDate: updated_at,
    searchTermsCount: terms.length, // TODO - should be a calculated field
  }
}

// Action Constants
const GET_SEARCHES = 'search/GET_SEARCHES'
const GET_SEARCH = 'search/GET_SEARCH'
const GET_SEARCH_RESULTS = 'search/GET_SEARCH_RESULTS'
const GET_SEARCH_RESULT_MARKUP = 'search/GET_SEARCH_RESULT_MARKUP'

const UPDATE_SEARCH = 'search/UPDATE_SEARCH'
const DELETE_SEARCH = 'search/DELETE_SEARCH'
const REMOVE_FROM_PREPROCESSED = 'search/REMOVE_FROM_PREPROCESSED'

const SUBMIT_TO_PREPROCESS = 'search/SUBMIT_TO_PREPROCESS'
const CREATE_PREPROCESSED_FROM_EXISTING_SEARCH = 'search/CREATE_PREPROCESSED_FROM_EXISTING_SEARCH'
const SUBMIT_TO_SEARCH = 'search/SUBMIT_TO_SEARCH'
const UPDATE_PREPROCESSED_SEARCH = 'search/UPDATE_PREPROCESSED_SEARCH'
const SUBMIT_SIMPLIFIED_SEARCH = 'search/SUBMIT_SIMPLIFIED_SEARCH'

const REMOVE_FROM_SEARCHES = 'search/REMOVE_FROM_SEARCHES'

const UPDATE_CACHE_PREPROCESSED = 'search/UPDATE_CACHE_PREPROCESSED'

const UPDATE_CACHE_SEARCHES = 'search/UPDATE_CACHE_SUBMITTED'
const UPDATE_CACHE_SEARCHES_SET = 'search/UPDATE_CACHE_SUBMITTED_SET'

const DOWNLOAD_EXPORTED_RESULTS_FILE = 'search/DOWNLOAD_EXPORTED_RESULTS_FILE'

// Action Creators
export const getSearches = (payload = {}) => ({ type: GET_SEARCHES, payload })
export const getSearchDetails = (payload = {}) => ({ type: GET_SEARCH, payload })
export const getSearchResults = (payload = {}) => ({ type: GET_SEARCH_RESULTS, payload })
export const getSearchResultMarkup = (payload = {}) => ({ type: GET_SEARCH_RESULT_MARKUP, payload })
export const copySearch = (payload = {}) => ({
  type: CREATE_PREPROCESSED_FROM_EXISTING_SEARCH,
  payload,
})
export const preprocess = (payload = {}) => ({ type: SUBMIT_TO_PREPROCESS, payload })
export const preprocessAndSearch = (payload = {}) => ({
  type: SUBMIT_SIMPLIFIED_SEARCH,
  payload,
})
export const search = (payload = {}) => ({ type: SUBMIT_TO_SEARCH, payload })
export const archive = ({ id, statusRef } = {}) => ({
  type: UPDATE_SEARCH,
  payload: { id, params: { state: constants.SEARCH_STATUS.archive }, statusRef },
})
export const updateName = ({ id, name, statusRef } = {}) => ({
  type: UPDATE_SEARCH,
  payload: { id, params: { name }, statusRef },
})
export const update = (payload = {}) => ({ type: UPDATE_SEARCH, payload })
export const deleteSearch = (payload = {}) => ({ type: DELETE_SEARCH, payload })
export const removePreprocessed = (payload = {}) => ({ type: REMOVE_FROM_PREPROCESSED, payload })
export const updatePreprocessedList = (payload = {}) => ({
  type: UPDATE_PREPROCESSED_SEARCH,
  payload,
})

export const downloadExportedResults = payload => ({
  type: DOWNLOAD_EXPORTED_RESULTS_FILE,
  payload,
})

// Selectors
export const allSearchesSelector = state => state.Search.searches
export const allSearchesArraySelector = state => Object.values(state.Search.searches)
export const allPreprocessedSearchesSelector = state => state.Search.preprocessed
export const specificPreprocessed = (state, searchId) => state.Search.preprocessed[searchId]

export const specificSearch = (state, searchId) => state.Search.searches[searchId]

// Sagas
/* Consider using conditional saga watch for the takeEvery/takeLatest 
for preprocessing and search based on: https://github.com/redux-saga/redux-saga/issues/1461 */
export default function* sagawatcher() {
  yield takeEvery(GET_SEARCHES, getSearchesSaga) //FIXME: Change back to takeLatest once the cancelToken is properly passed to the request
  yield takeLatest(GET_SEARCH, getSearchSaga)
  yield takeLatest(GET_SEARCH_RESULTS, getSearchResultsSaga)
  yield takeLatest(GET_SEARCH_RESULT_MARKUP, getSearchResultMarkupSaga)

  yield takeLatest(UPDATE_SEARCH, updateSearchSaga)
  yield takeEvery(DELETE_SEARCH, deleteSearchSaga)

  yield takeEvery(SUBMIT_TO_PREPROCESS, submitToPreprocess)
  yield takeLatest(UPDATE_PREPROCESSED_SEARCH, updatePreprocessed)
  yield takeEvery(SUBMIT_TO_SEARCH, submitToSearch)

  yield takeEvery(SUBMIT_SIMPLIFIED_SEARCH, submitPreprocessAndSearch)
  yield takeEvery(UPDATE_CACHE_SEARCHES, updateCacheSubmittedSearches)

  yield takeLatest(DOWNLOAD_EXPORTED_RESULTS_FILE, downloadExportedResultsSaga)
}

const isDesktopApp = Boolean(window && window.nw)
const textProcessor = isDesktopApp ? window.require('src/textProcessor') : null

function* getSearchesSaga({ payload }) {
  function* sendRequest() {
    const params = {
      state: payload.searchType,
      // isPoll: payload.poll // NOT YET IMPLEMENTED
    }

    const response = yield call(ApiServiceV1.get, ApiServiceV1.ENDPOINTS.search, params)

    const responseData = response.data || []

    return responseData.map(data => formatSearchDataForStore(data))
  }

  const storePayload = yield tryCatchWrapper(
    `gettting searches with type ${payload.searchType}`,
    sendRequest,
    payload.statusRef
  )

  yield put({ type: UPDATE_CACHE_SEARCHES, payload: storePayload, replace: true })
}

function* getSearchSaga({ payload: { id, statusRef } }) {
  function* sendRequest() {
    const params = {}

    const response = yield call(ApiServiceV1.get, ApiServiceV1.ENDPOINTS.singleSearch(id), params)
    const searchResultData = response?.data
    searchResultData.id = searchResultData.ID // TODO: refactor throughout to use uppercase ID

    if (searchResultData.desktop_filename) {
      if (isDesktopApp) {
        const { result, sourceTextDeleted } = textProcessor.readFile(
          searchResultData.desktop_filename
        )
        if (sourceTextDeleted) searchResultData.sourceTextDeleted = true
        else searchResultData.text = result
      } else {
        //browser
        searchResultData.sourceTextNotAvailable = true
      }
    }

    return [searchResultData]
  }

  const payload = yield tryCatchWrapper(
    `getting single search with id ${id}`,
    sendRequest,
    statusRef
  )

  yield put({ type: UPDATE_CACHE_SEARCHES, payload })
}

function* getSearchResultsSaga({
  payload: { id, filter, offset, limit, clearResults, statusRef, pageNumber, isPolling },
}) {
  function* sendRequest() {
    const params = {
      offset,
      limit,
      filter,
      // isPoll //TODO
    }

    const response = yield call(
      ApiServiceV1.post, // Needs to be post to handle nested objects and char limit
      ApiServiceV1.ENDPOINTS.searchResults(id),
      params
    )

    const responseData = response?.data || []
    const resultsCount = response?.headers[RESULTS_COUNT_HEADER_NAME]

    const results = responseData

    const allSearches = yield select(allSearchesSelector) || {}
    const oldSearch = allSearches[id] || {}

    if (!oldSearch.topResults) oldSearch.topResults = []

    const topResultsUpdated = results.map(result => ({
      ...result,
      applicants: formatNames(result.applicants),
      inventors: formatNames(result.inventors),
    }))

    const topResults = clearResults
      ? { [pageNumber]: topResultsUpdated }
      : { ...oldSearch.topResults, [pageNumber]: topResultsUpdated }

    const result = {
      ...oldSearch,
      id,
      topResults,
      resultsCount,
    }

    return result
  }

  const payload = yield tryCatchWrapper(
    `getting search results for id ${id}. limit = ${limit}, offset=${offset}`,
    sendRequest,
    statusRef
  )

  yield put({ type: UPDATE_CACHE_SEARCHES, payload: [payload] })
}

function* getSearchResultMarkupSaga({
  payload: { searchID, pageNumber, resultID, terms, filter, statusRef },
}) {
  function* sendRequest() {
    const params = {
      bigrams: terms.join(','),
    }

    const response = yield call(
      ApiServiceV1.get, // Needs to be post to handle nested objects and char limit
      ApiServiceV1.ENDPOINTS.searchResultsMarkup(searchID, resultID),
      params
    )

    const markupArr = response?.data

    const allSearches = yield select(allSearchesSelector) || {}
    const oldSearch = allSearches[searchID] || {}

    const updateResults = () => {
      const updated = oldSearch.topResults[pageNumber].map(res => {
        const markupPositions = res.markupPositions || []
        return res.id === resultID
          ? { ...res, markupPositions: [...markupArr, ...markupPositions] }
          : res
      })
      return { [pageNumber]: updated }
    }

    return [{ ...oldSearch, topResults: updateResults() }]
  }

  const payload = yield tryCatchWrapper(
    `getting markup for search ${searchID}, result ${resultID}.`,
    sendRequest,
    statusRef
  )

  yield put({ type: UPDATE_CACHE_SEARCHES, payload })
}

function* desktopAppPreprocessing({
  payload: { id, file, inputText, statusRef, name, filterTerms },
}) {
  function* sendRequest() {
    let filename

    if (file.path) {
      filename = yield textProcessor.saveDocument(file)
    } else {
      filename = yield textProcessor.savePlainTextToFile({
        filename: file.name,
        text: inputText,
      })
    }

    const { result, missingFile } = yield textProcessor.executePreprocessor(
      filename,
      process.env.REACT_APP_PREPROCESSOR_JAR
    )

    if (missingFile) alert(missingFile)

    const obj = JSON.parse(result)
    const terms = formatTerms(obj.wordPairs, filterTerms)

    return {
      id,
      dataToUpdate: {
        name,
        terms,
        filename,
        text: obj.full_text,
        status: constants.SEARCH_STATUS.Preprocessed,
      },
    }
  }

  const payload = yield tryCatchWrapper(`preprocessing - desktop`, sendRequest, statusRef)

  yield updatePreprocessed({ payload })
}

function* submitToPreprocess({ payload: { id, file, name, statusRef, inputText } }) {
  if (!file || !id) throw new TypeError('Invalid args')

  const _name = name || file.name

  const allPreprocessed = yield select(allPreprocessedSearchesSelector)
  const currentPreprocessed = allPreprocessed[id] || {}

  if (isDesktopApp) {
    yield desktopAppPreprocessing({
      payload: {
        id,
        file,
        inputText,
        statusRef,
        name: _name,
        filterTerms: currentPreprocessed.filter?.terms,
      },
    })
  } else {
    function* sendRequest() {
      const response = yield call(ApiServiceV1.postForm, ApiServiceV1.ENDPOINTS.preprocess, {
        file,
      })

      const terms = formatTerms(response.data?.wordPairs, currentPreprocessed.filter?.terms)
      const preprocessedStatus =
        terms.length === 0 ? constants.SEARCH_STATUS.noTerms : constants.SEARCH_STATUS.Preprocessed

      return {
        id,
        dataToUpdate: {
          name: _name,
          terms,
          text: response.data?.full_text,
          status: preprocessedStatus,
        },
      }
    }

    const payload = yield tryCatchWrapper('preprocessing', sendRequest, statusRef)

    if (Object.keys(payload).length) yield updatePreprocessed({ payload })
  }
}

function* updatePreprocessed({ payload: { id, dataToUpdate } }) {
  if (!id || !dataToUpdate) throw new TypeError('Invalid args')

  const allPreprocessed = yield select(allPreprocessedSearchesSelector)
  const searchToUpdate = allPreprocessed[id] || {}

  yield put({
    type: UPDATE_CACHE_PREPROCESSED,
    payload: { [id]: { ...searchToUpdate, ...dataToUpdate } },
  })
}

function* submitToSearch({ payload: { id, name, statusRef } }) {
  if (!id) throw new TypeError('Invalid args')

  const allPreprocessed = yield select(allPreprocessedSearchesSelector)
  const fileData = allPreprocessed[id]

  if (fileData.error || fileData.status === constants.SEARCH_STATUS.noTerms) return

  //TODO: double check when BE is updated to terms being objects
  const termsFiltered = fileData.terms?.filter(term => !term.exclude).map(term => term.key) || []

  const getFilters = () => {
    const { terms, ...filters } = fileData?.filter || {}
    return filters
  }

  const _name = name || fileData.filename

  let searchBody
  let filename = fileData.filename

  if (isDesktopApp) {
    if (!filename) {
      filename = yield textProcessor.saveSubmittedText({
        uploadedFileName: fileData.uploadedFileName,
        text: fileData.text,
      })
    }

    searchBody = {
      desktopFilename: filename,
      terms: termsFiltered,
      name: _name,
      filter: getFilters(),
    }
  } else {
    searchBody = {
      text: fileData.text,
      terms: termsFiltered,
      name: _name,
      filter: getFilters(),
    }
  }

  function* sendRequest() {
    const response = yield call(ApiServiceV1.post, ApiServiceV1.ENDPOINTS.search, searchBody)
    return {
      [id]: {
        ...fileData,
        status: constants.SEARCH_STATUS.processing,
        name: _name,
        filename,
        searchId: response.data.ID,
      },
    }
  }

  const payload = yield tryCatchWrapper(`submit search: ${name}`, sendRequest, statusRef)

  yield put({ type: UPDATE_CACHE_PREPROCESSED, payload })
}

function* submitPreprocessAndSearch({
  payload: { id, file, name, inputText, preprocessStatusRef, searchStatusRef },
}) {
  yield* submitToPreprocess({
    payload: { id, file, name, inputText, statusRef: preprocessStatusRef },
  })

  yield* submitToSearch({ payload: { id, name, statusRef: searchStatusRef } })
}

function* updateSearchSaga({ payload: { id, params, statusRef } }) {
  function* sendRequest() {
    const response = yield call(ApiServiceV1.patch, ApiServiceV1.ENDPOINTS.singleSearch(id), params)

    const respData = response?.data || {}

    const searchResultData = formatSearchDataForStore(respData)

    return [searchResultData]
  }

  const payload = yield tryCatchWrapper(`update search ${id}`, sendRequest, statusRef)

  yield put({ type: UPDATE_CACHE_SEARCHES, payload })
}

function* deleteSearchSaga({ payload: { id, statusRef } }) {
  if (!id) throw new TypeError('Invalid args')

  function* sendRequest() {
    const params = {}

    yield call(ApiServiceV1.deleteIt, ApiServiceV1.ENDPOINTS.singleSearch(id), params)

    return { id }
  }

  const payload = yield tryCatchWrapper(`delete search ${id}`, sendRequest, statusRef)

  yield put({ type: REMOVE_FROM_SEARCHES, payload })
}

function* updateCacheSubmittedSearches(data) {
  if (!data || !data.payload || data.payload.length === 0) return

  let searches = yield select(allSearchesSelector) || {}

  if (data.replace) {
    searches = {}
  }

  if (Array.isArray(data.payload))
    data.payload.forEach(item => {
      searches[String(item.id)] = { ...item }
    })

  yield put({ type: UPDATE_CACHE_SEARCHES_SET, payload: searches })
}

function formatTerms(wordPairs, filterTerms = []) {
  return Object.entries(wordPairs).map(([key, value]) => {
    const exclude = filterTerms.length && filterTerms.every(toExclude => toExclude.key !== key)

    return { key, value, exclude }
  })
}

function* downloadExportedResultsSaga({
  payload: { searchID, exportFormat, numResults, columns, patents, filename, statusRef },
}) {
  function* sendRequest() {
    const params = {
      format: exportFormat,
      numResults,
      columns,
      patents,
    }
    const result = yield call(
      ApiServiceV1.post,
      ApiServiceV1.ENDPOINTS.exportResults(searchID),
      params,
      null,
      null,
      'arraybuffer'
    )
    if (exportFormat == 'excel') {
      saveAsExcel(result.data, filename)
    } else {
      saveAsCsv(result.data, filename)
    }
  }

  yield tryCatchWrapper(
    `Start downloading selected patents CSV for ${searchID}.`,
    sendRequest,
    statusRef
  )
}

/*************************************************/
/** Reducer **/
const initialState = {
  searches: {},
  preprocessed: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CACHE_SEARCHES_SET:
      return {
        ...state,
        searches: { ...action.payload },
      }

    case REMOVE_FROM_SEARCHES:
      return {
        ...state,
        searches: Object.keys(state.searches)
          .map(key => key !== action.payload.id && { [key]: state.searches[key] })
          .reduce((previous, current) => Object.assign({}, previous, current), {}),
      }

    case REMOVE_FROM_PREPROCESSED:
      return {
        ...state,
        preprocessed: Object.keys(state.preprocessed)
          .map(key => key !== action.payload.id && { [key]: state.preprocessed[key] })
          .reduce((previous, current) => ({ ...previous, ...current }), {}),
      }

    case CREATE_PREPROCESSED_FROM_EXISTING_SEARCH:
      return {
        ...state,
        preprocessed: {
          ...state.preprocessed,
          [action.payload.name]: (() => {
            const { name, text } = state.searches[action.payload.id]
            const filters = action.payload.detailsFilter

            const terms = filters.terms
              ? filters.terms.map(term => ({
                  key: term,
                  value: 0,
                }))
              : []

            return {
              name,
              text,
              filter: { ...filters, terms },
              status: constants.SEARCH_STATUS.Preparing,
            }
          })(),
        },
      }

    case UPDATE_CACHE_PREPROCESSED:
      return {
        ...state,
        preprocessed: { ...state.preprocessed, ...action.payload },
      }

    default:
      return state
  }
}
