import React, { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Button from 'components/Button'
import Svg from 'components/Svg'
import { ReactComponent as Hamburger } from 'assets/images/hamburger.svg'

import { signOutUser } from 'store/Account'
import { paths } from 'router/paths'

import style from './dropdown.module.scss'

function Dropdown() {
  const [isOpen, setIsOpen] = React.useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const menuRef = useRef(null)

  const handleClickOutside = e => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <div className={style.base}>
      <Button
        dataTestId='user-menu-button'
        variant={'transparentContainer'}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={`${style.hamburgerContainer} ${isOpen ? style.open : ''}`}>
          <Svg
            SvgImage={Hamburger}
            alt='menu hamburger'
            variants={['noMargin', 'iconSmall']}
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
      </Button>

      {isOpen && (
        <ul className={style.menuList} data-testid='dropdown-menu' ref={menuRef}>
          <li className={style.item}>
            {/* TODO: build a component wrapping password reset and reminder, as the only meaningful difference is styling */}
            <Link data-testid='dropdown-reset-password-link' to={paths.passwordReset}>
              {t('Nav.resetPassword')}
            </Link>
            <Button dataTestId='dropdown-sign-out-button' onClick={() => dispatch(signOutUser())}>
              {t('GLOBAL.signOut')}
            </Button>
          </li>
        </ul>
      )}
    </div>
  )
}

export default Dropdown
