import React from 'react'
import PropTypes from 'prop-types'

import styles from './pageLink.module.scss'

function PageLink({ pageLinkName, disabled, onClick }) {
  return (
    <div
      className={`${styles.base} ${disabled ? styles.disabled : ''}`}
      onClick={disabled ? undefined : onClick}
    >
      {pageLinkName}
    </div>
  )
}

PageLink.propTypes = {
  pageLinkName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default PageLink
