import { takeLatest, put } from 'redux-saga/effects'

// Constants
const CHECK_FOR_UPDATES = 'updater/CHECK_FOR_UPDATES'
const NEW_RELEASE = 'updater/NEW_RELEASE'
const UPDATE_APP = 'search/UPDATE_APP'
const UPDATE_STATUS = 'updater/UPDATE_STATUS'

export const DESKTOP_UPDATE_STATUS = {
  newReleaseAvailable: 'newReleaseAvailable',
  downloading: 'downloading',
  installing: 'installing',
  restart: 'restart',
  swapping: 'swapping',
  swapDone: 'swapDone',
}

// Action Creators
export const checkNeedsUpdate = (payload = {}) => ({ type: CHECK_FOR_UPDATES, payload })
export const updateDesktopApp = (payload = {}) => ({ type: UPDATE_APP, payload })

// Selectors
export const newReleaseAvailableSelector = state => state.Updater.newReleaseAvailable
export const updateStatusSelector = state => state.Updater.updateStatus

// Sagas
export default function* sagawatcher() {
  yield takeLatest(CHECK_FOR_UPDATES, checkForUpdates)
  yield takeLatest(UPDATE_APP, updateApp)
}

const isDev = process.env.NODE_ENV === 'development'

const isDesktopApp = Boolean(window && window.nw)
const desktopPath = isDesktopApp ? window.require('path').resolve('') + '/desktop-hash.txt' : null
const log = isDesktopApp ? window.require('src/utils/logging') : null
const fs = isDesktopApp ? window.require('fs') : null
const AutoUpdater = isDesktopApp ? window.require('src/nw-autoupdater') : null

const readDesktopFile = () => {
  const defaultValue = '0000'
  if (!fs.existsSync(desktopPath)) return defaultValue

  try {
    return fs.readFileSync(desktopPath, 'utf8')
  } catch (err) {
    return defaultValue
  }
}

const manifest = {
  name: 'patent-reader-frontend-react',
  release: {
    url: process.env.REACT_APP_REMOTE_DESKTOP_ZIP_URL,
  },
  remoteDesktopHashUrl: process.env.REACT_APP_REMOTE_HASH_URL,
  currentHash: isDesktopApp ? readDesktopFile() : null,
}

const updater = isDesktopApp ? new AutoUpdater(manifest) : null

function* swap() {
  log('Swapping...')

  yield put({ type: UPDATE_STATUS, payload: DESKTOP_UPDATE_STATUS.swapping })
  yield updater.swap()
}
function* restart() {
  log('Done...')

  yield put({ type: UPDATE_STATUS, payload: DESKTOP_UPDATE_STATUS.swapDone })
  yield updater.restart()
}

function* checkForUpdates() {
  if (updater.isSwapRequest()) {
    yield* swap()
    yield* restart()
    return
  }
  // Download/unpack update if any available
  const remoteHash = yield updater.readRemoteHash()
  log('remoteHash: ' + remoteHash)

  const needsUpdate = yield updater.checkNewVersion(remoteHash)
  if (!needsUpdate) {
    log('App is up to date...')
    return
  }
  log('New release is available')

  yield put({ type: NEW_RELEASE, payload: DESKTOP_UPDATE_STATUS.newReleaseAvailable })
}

const calculateProgress = (x, totalX) => {
  return Math.floor((x / totalX) * 100)
}

function* install(updateFile) {
  updater.on('install', (installFiles, totalFiles) => {
    log('install progress' + calculateProgress(installFiles, totalFiles) + '%')
  })

  yield put({ type: UPDATE_STATUS, payload: DESKTOP_UPDATE_STATUS.installing }) //=unpacking
  yield updater.unpack(updateFile)
}

function* restartToSwap() {
  yield put({ type: UPDATE_STATUS, payload: DESKTOP_UPDATE_STATUS.restart })

  if (isDev) {
    alert('You are in Dev. Swapping not allowed')
    return
  }

  yield updater.restartToSwap()
}

function* updateApp() {
  // Subscribe for progress events
  updater.on('download', (downloadSize, totalSize) => {
    log('download progress' + calculateProgress(downloadSize, totalSize) + '%')
  })

  yield put({ type: UPDATE_STATUS, payload: DESKTOP_UPDATE_STATUS.downloading })

  const updateFile = yield updater.download(manifest)
  yield* install(updateFile)

  log('unpacked updateDir: ' + updater.options.updateDir)

  yield* restartToSwap()
}

/*************************************************/
/** Reducer **/
const initialState = {
  newReleaseAvailable: false,
  updateStatus: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_RELEASE:
      return {
        ...state,
        newReleaseAvailable: action.payload,
      }
    case UPDATE_STATUS:
      return {
        ...state,
        updateStatus: action.payload,
      }
    default:
      return state
  }
}
