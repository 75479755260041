/*************************************************/
/** Constants **/

const STATUS_INCREMENT = 'status/STATUS_INCREMENT'
const STATUS_DECREMENT = 'status/STATUS_DECREMENT'
const CLEAR = 'status/CLEAR_STATUS'

export const STATUS_STATES = {
  BUSY: 'status/state/busy',
  FINISHED: 'status/state/finished',
  ERROR: 'status/state/error',
  CANCELLED: 'status/state/cancelled',
  NOT_FOUND: 'status/state/notfound',
  INVALID: 'status/state/invalid',
}

/*************************************************/
/** Action creators **/
export const incrementStatusCreator = payload => ({ type: STATUS_INCREMENT, payload })
export const decrementStatusCreator = payload => ({ type: STATUS_DECREMENT, payload })
export const clearStatus = payload => ({ type: CLEAR, payload })

/*************************************************/
/** Selectors **/
export const allStatusesSelector = state => state.Status.statuses

/*************************************************/
/** Reducer **/
const initialState = {
  statuses: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_INCREMENT:
      return {
        ...state,
        statuses: getStatuses(state.statuses, action.payload, 1),
      }

    case STATUS_DECREMENT:
      return {
        ...state,
        statuses: getStatuses(state.statuses, action.payload, -1),
      }

    case CLEAR:
      return {
        ...state,
        statuses: Object.keys(state.statuses)
          .map(key => key !== action.payload.statusRef && { [key]: state.statuses[key] })
          .reduce((previous, current) => Object.assign({}, previous, current), {}),
      }

    default:
      return state
  }
}

function getStatuses(statuses, payload, valueIncrement) {
  const count = (statuses[payload.statusRef]?.count || 0) + valueIncrement
  const state = payload.state || (count > 0 ? STATUS_STATES.BUSY : STATUS_STATES.FINISHED)

  return {
    ...statuses,
    [payload.statusRef]: {
      statusRef: payload.statusRef,
      count,
      data: payload.data,
      state,
    },
  }
}
