import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { paths } from 'router/paths'

import Svg from 'components/Svg'
import { ReactComponent as LinkIcon } from 'assets/images/external-link-alt.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/minus-circle.svg'

import styles from './patent.module.scss'

function Patent({ patent, removeFromProject }) {
  const { t } = useTranslation()

  return (
    <div className={styles.base}>
      {/* TODO: make the data tooltip display condition more resilient */}
      <div className={styles.data} data-tooltip={patent.name.length > 39 ? patent.name : undefined}>
        <p>{patent.name.toLowerCase()}</p>
        <p>{patent.patentNum}</p>
      </div>

      <div className={styles.actions}>
        <Link to={`${paths.patentView}/${patent.patentNum}`} target='_blank'>
          <Svg
            SvgImage={LinkIcon}
            alt={t('ProjectsPanel.openPatent')}
            dataTestId='projects-panel-open-patent'
            variants={['verySmall', 'grey']}
          />
        </Link>
        <div onClick={removeFromProject}>
          <Svg
            SvgImage={DeleteIcon}
            alt={t('ProjectsPanel.removePatent')}
            dataTestId='projects-panel-remove-patent'
            variants={['verySmall', 'red']}
          />
        </div>
      </div>
    </div>
  )
}

export default Patent
