import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import style from './submittedSearchItem.module.scss'

function SubmittedSearchItem({ path, name }) {
  const { t } = useTranslation()
  const history = useHistory()

  const handleClick = () => {
    history.push(path)
  }

  return (
    <div className={style.base} data-testid='search-setup-search-link' onClick={handleClick}>
      <div className={style.name}>{name}</div>

      <div className={style.submimttedTextContainer}>
        {t('SearchPage.SubmittedSearchItem.submitted')}
      </div>
    </div>
  )
}

export default SubmittedSearchItem

SubmittedSearchItem.propTypes = {
  path: PropTypes.string.isRequired,
}
