import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { paths } from 'router/paths'

import { userDataSelector } from 'store/Account'

import Svg from 'components/Svg'
import { ReactComponent as LogoFull } from 'assets/images/logo-full-red.svg'
import Dropdown from './containers/Dropdown'
import PatentSearchBox from './containers/PatentSearchBox'
import styles from './header.module.scss'

function Header() {
  const { t } = useTranslation()
  const user = useSelector(userDataSelector)

  const logoVariants = window.innerWidth >= 1095 ? ['headerLogo'] : ['headerLogoSmall']

  return (
    <header className={styles.base}>
      <nav>
        {/* <NavLink to={paths.searchPage} className={styles.link}> */}
        <Svg SvgImage={LogoFull} dataTestId='elute-logo' alt='logo' variants={logoVariants} />
        {/* </NavLink> */}

        <NavLink
          data-testid='nav-link-search-page'
          to={paths.searchPage}
          className={styles.link}
          activeClassName={styles.activeNavLink}
        >
          <div className={styles.linkText}>{t('Nav.searchPage')}</div>
        </NavLink>

        {/* Exact means doesn't get active class when children are active */}
        <NavLink
          exact
          data-testid='nav-link-current-searches'
          to={paths.searches}
          className={styles.link}
          activeClassName={styles.activeNavLink}
        >
          <div className={styles.linkText}>{t('Nav.searches')}</div>
        </NavLink>

        <NavLink
          data-testid='nav-link-archived-searches'
          to={paths.archivedSearches}
          className={styles.link}
          activeClassName={styles.activeNavLink}
        >
          <div className={styles.linkText}>{t('Nav.archivedSearches')}</div>
        </NavLink>
      </nav>

      <div className={styles.mainMenuContainer}>
        <PatentSearchBox />
        <p className={styles.username}>{user.userDisplayName}</p>
        <Dropdown />
      </div>
    </header>
  )
}

export default Header
