import React from 'react'
import PropTypes from 'prop-types'

import styles from './checkbox.module.scss'

function Checkbox({ children, onChange, checked, name, dataTestId, required, variants }) {
  return (
    <label className={`${styles.base} ${variants.map(v => styles[v]).join(' ')}`}>
      <input
        data-testid={dataTestId}
        type='checkbox'
        checked={checked}
        onChange={onChange}
        name={name}
        required={required}
      />
      <span>{children}</span>
    </label>
  )
}

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  name: PropTypes.string,
  dataTestId: PropTypes.string,
  required: PropTypes.bool,
  variants: PropTypes.array,
}

Checkbox.defaultProps = {
  checked: false,
  name: '',
  dataTestId: 'checkbox',
  disabled: false,
  required: false,
  variants: [],
}

export default Checkbox
