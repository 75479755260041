import React from 'react'
import PropTypes from 'prop-types'

import style from './modal.module.scss'

function Modal({ children, onCancel, message, variant }) {
  return (
    <div className={`${style.base} ${style[variant]}`} onClick={onCancel}>
      <div className={style.messageContainer}>
        <div className={style.message}>{message}</div>
        {children}
      </div>
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func,
  message: PropTypes.string,
}

Modal.defaultProps = {
  message: '',
  onCancel: () => {},
}

export default Modal
