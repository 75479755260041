import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import 'url-search-params-polyfill'

import Svg from 'components/Svg'
import { ReactComponent as LogoFull } from 'assets/images/logo-full-red.svg'
import Button from 'components/Button'
import { paths } from 'router/paths'

import { signUpConfirmation, userDataSelector, ERROR_TYPE } from 'store/Account'
import { STATUS_STATES, clearStatus } from 'store/Status'

import style from './signUpConfirmation.module.scss'

const signUpConfirmationRef = 'SignUpConfirmation_submit'

//TODO: check passed user
function SignUpConfirmation() {
  const user = useSelector(userDataSelector)

  const location = useLocation()

  const query = new URLSearchParams(location.search)

  const [code, setCode] = React.useState(query.get('confirmCode'))
  const [username, setUsername] = React.useState(user?.username)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const signUpConfirmationState =
    useSelector(state => state.Status.statuses[signUpConfirmationRef]) || {}

  const error =
    signUpConfirmationState.state === STATUS_STATES.ERROR &&
    (Object.keys(ERROR_TYPE.signUpConfirmation).find(
      key => ERROR_TYPE.signUpConfirmation[key] === signUpConfirmationState.data
    ) ||
      'default')

  const clearState = () =>
    signUpConfirmationState.state && dispatch(clearStatus({ statusRef: signUpConfirmationRef }))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearState, [])

  const userEmail = location.state?.username
  const password = location.state?.password
  useEffect(() => {
    if (signUpConfirmationState.state === STATUS_STATES.FINISHED) {
      if (user?.confirmed || (userEmail && password))
        history.push(paths.logIn, { username: userEmail, password })
      else {
        history.push(paths.logIn, { username })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpConfirmationState.state, password, userEmail, user?.confirmed, history])

  const submitConfirmationForm = e => {
    e.preventDefault()

    dispatch(signUpConfirmation({ username, code, statusRef: signUpConfirmationRef }))
  }

  return (
    <div className={style.base}>
      <div className={style.signUpConfirmationContainer}>
        <Svg
          SvgImage={LogoFull}
          dataTestId='elute-logo'
          alt={t('GLOBAL.logoAlt')}
          variant='logoFullMedium'
        />

        <p className={style.info}>{t('SignUpConfirmation.info')}</p>

        <form onSubmit={submitConfirmationForm}>
          <div>
            <label htmlFor='signupConfirm-username'>{t('GLOBAL.email')}</label>
            <input
              type='text'
              placeholder={t('GLOBAL.emailExample')}
              value={username}
              onChange={e => {
                setUsername(e.target.value)
                clearState()
              }}
              required
            />
          </div>

          <div>
            <label htmlFor='signupConfirm-code'>{t('GLOBAL.verificationCode')}</label>
            <input
              type='text'
              placeholder={t('GLOBAL.verificationCodeExample')}
              value={code}
              onChange={e => {
                setCode(e.target.value)
                clearState()
              }}
              required
            />
          </div>

          {error && <div className={style.error}>{t(`SignUpConfirmation.errors.${error}`)} </div>}

          <Button type='submit'>{t('SignUpConfirmation.confirm')}</Button>
        </form>

        <div className={style.linkContainer}>
          <Link to={paths.signUpConfirmationCodeResend}>{t('GLOBAL.resendCodeMessage')}</Link>
          <Link to={paths.logIn}>{t('SignUpConfirmation.alreadyConfirmed')}</Link>
          <Link to={paths.signUp}>{t('GLOBAL.signUp')}</Link>
        </div>
      </div>
    </div>
  )
}

export default SignUpConfirmation
