import React from 'react'
import PropTypes from 'prop-types'

import Modal from 'components/Modal'
import ConfirmCancelButtonGroup from 'components/ConfirmCancelButtonGroup'
import styles from './confirmDialog.module.scss'

function ConfirmDialog({ onConfirm, onCancel, message, children, info, variant }) {
  return (
    <Modal message={message} variant={variant}>
      {children}
      <div className={styles.base}>
        <div className={styles.info}>{info}</div>
        <ConfirmCancelButtonGroup onConfirm={onConfirm} onCancel={onCancel} />
      </div>
    </Modal>
  )
}

ConfirmDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  children: PropTypes.node,
  info: PropTypes.string,
  variant: PropTypes.string,
}

ConfirmDialog.defaultProps = {
  info: '',
  variant: '',
}

export default ConfirmDialog
