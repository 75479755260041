import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Svg from 'components/Svg'
import { ReactComponent as LogoFull } from 'assets/images/logo-full-red.svg'
import Button from 'components/Button'

import { sendPasswordReminder, ERROR_TYPE } from 'store/Account'
import { STATUS_STATES, clearStatus } from 'store/Status'
import { paths } from 'router/paths'

import style from './forgotPassword.module.scss'

const forgotPasswordRef = 'ForgotPassword_submit'

function ForgotPassword() {
  const [username, setUsername] = React.useState('')
  const [reminderSent, setReminderSent] = React.useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const forgotPasswordState = useSelector(state => state.Status.statuses[forgotPasswordRef]) || {}

  const error =
    forgotPasswordState.state === STATUS_STATES.ERROR &&
    (Object.keys(ERROR_TYPE.forgotPassword).find(
      key => ERROR_TYPE.forgotPassword[key] === forgotPasswordState.data
    ) ||
      'default')

  const clearState = () => {
    if (forgotPasswordState.state) dispatch(clearStatus({ statusRef: forgotPasswordRef }))
    setReminderSent(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearState, [])

  useEffect(() => {
    if (reminderSent && forgotPasswordState.state === STATUS_STATES.FINISHED)
      history.push(paths.forgotPasswordConfirmation, { username })
  }, [reminderSent, error, history, forgotPasswordState.state, username])

  const sendReminder = e => {
    e.preventDefault()

    dispatch(sendPasswordReminder({ username, statusRef: forgotPasswordRef }))
    setReminderSent(true)
  }

  return (
    <div className={style.base}>
      <div className={style.forgotPasswordContainer}>
        <Svg
          SvgImage={LogoFull}
          dataTestId='elute-logo'
          alt={t('GLOBAL.logoAlt')}
          variant='logoFullMedium'
        />
        <form onSubmit={sendReminder}>
          <div>
            <label htmlFor='login-email'>{t('GLOBAL.email')}</label>
            <input
              type='email'
              placeholder={t('GLOBAL.emailExample')}
              onChange={e => {
                setUsername(e.target.value)
                clearState()
              }}
              required
            />
          </div>

          {error && <div className={style.error}>{t(`ForgotPassword.errors.${error}`)}</div>}

          <Button type='submit'>{t('ForgotPassword.sendReminder')}</Button>
        </form>
        <div className={style.linkContainer}>
          <Link to={paths.logIn}>{t('GLOBAL.logIn')}</Link>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
