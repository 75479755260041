const charsToIgnore = [
  '`',
  '~',
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '-',
  '_',
  '=',
  '+',
  '{',
  '}',
  '[',
  ']',
  '\\',
  '|',
  ':',
  ';',
  "'",
  '"',
  ',',
  '.',
  '<',
  '>',
  '/',
  '?',
]

const highlightMarkUp = (markInstance, toMarkUpArr, highlightClassName, isMatchTerm) => {
  if (markInstance) {
    toMarkUpArr.forEach(text => {
      // eslint-disable-next-line no-useless-escape
      const replacedText = text.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
      const reg = isMatchTerm
        ? new RegExp('(?<!-)\\b' + replacedText + '\\b(?!-)', 'gi')
        : new RegExp(replacedText, 'gi')
      markInstance.markRegExp(reg, {
        className: highlightClassName,
        ignoreJoiners: true,
        acrossElements: true,
        ignorePunctuation: charsToIgnore,
      })
    })
  }
  return markInstance
}

export const highlightText = (markInstance, toMarkUpArr, highlightClassName) => {
  return highlightMarkUp(markInstance, toMarkUpArr, highlightClassName)
}

export const highlightTerms = (markInstance, toMarkUpArr, highlightClassName) => {
  return highlightMarkUp(markInstance, toMarkUpArr, highlightClassName, true)
}
