import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { updatePreprocessedList } from 'store/Search'
import { FILTER_SECTIONS, updateFilterSection } from 'components/filter/updateFilters'
import * as ApiService from 'services/api'

import TextInputTagsList from 'components/filter/TextInputTagsList'
import BooleanTextGroup from 'components/filter/BooleanTextGroup'
import CountrySelector from 'components/filter/CountrySelector'
import DateRangeSelector from 'components/filter/DateRangeSelector'
import Background from 'components/filter/Background'
import AppliedFilterLabels from 'components/filter/AppliedFilterLabels'

import style from './filters.module.scss'
import moment from 'moment'

function Filters({ id, data }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [filters, setFilters] = React.useState({})

  useEffect(() => {
    const _filters = data?.filter || {}

    setFilters(_filters)
    // eslint-disable-next-line
  }, [])

  const onFilterValueChange = (type, value) => {
    setFilters(prev => {
      const updatedFilters = { ...prev, [type]: value }
      dispatch(
        updatePreprocessedList({
          id,
          dataToUpdate: { filter: updatedFilters },
        })
      )

      return updatedFilters
    })
  }

  const formatDate = dt => {
    if (moment.isDate(dt)) return moment(dt).format('yyyy-MM-DD')

    return null
  }

  return (
    <form className={style.base}>
      <div className={style.filterSelection}>
        <div>
          <BooleanTextGroup
            title={t('GLOBAL.patentSections.claims')}
            onInputValueChange={value => onFilterValueChange(FILTER_SECTIONS.claims, value)}
            values={filters?.claims}
            includeOr={false}
          />
        </div>

        <div>
          <div className={style.filter}>
            <span>{t('GLOBAL.patentSections.applicants')}</span>
            <Background>
              <TextInputTagsList
                values={filters?.applicants}
                onSearchTextSubmit={value => onFilterValueChange(FILTER_SECTIONS.applicants, value)}
                variants={['withBorder', 'withButton']}
              />
            </Background>
          </div>

          <div>
            <span>{t('GLOBAL.patentSections.inventors')}</span>
            <Background>
              <TextInputTagsList
                values={filters?.inventors}
                onSearchTextSubmit={value => onFilterValueChange(FILTER_SECTIONS.inventors, value)}
                variants={['withBorder', 'withButton']}
              />
            </Background>
          </div>
          <DateRangeSelector
            title={t('Filters.publicationDate')}
            valueStart={filters?.publicationDateStart}
            valueEnd={filters?.publicationDateEnd}
            onStartChange={value =>
              onFilterValueChange(FILTER_SECTIONS.publicationDateStart, formatDate(value))
            }
            onEndChange={value =>
              onFilterValueChange(FILTER_SECTIONS.publicationDateEnd, formatDate(value))
            }
          />
        </div>

        <div>
          <div>
            <CountrySelector
              initialValues={ApiService.defaultFilters.countries}
              values={filters?.countries}
              onFilterChange={values => onFilterValueChange('countries', values)}
            />
          </div>
          <div>
            <span>{t('GLOBAL.patentSections.classificationsIPCR')}</span>
            <Background>
              <TextInputTagsList
                values={filters?.classificationsIPCR}
                onSearchTextSubmit={value =>
                  onFilterValueChange(FILTER_SECTIONS.classificationsIPCR, value)
                }
                variants={['withBorder', 'withButton']}
              />
            </Background>
          </div>
          <DateRangeSelector
            title={t('SearchDetailsPage.PatentFilters.priorityDate')}
            valueStart={filters?.priorityDateStart}
            valueEnd={filters?.priorityDateEnd}
            onStartChange={value =>
              onFilterValueChange(FILTER_SECTIONS.priorityDateStart, formatDate(value))
            }
            onEndChange={value =>
              onFilterValueChange(FILTER_SECTIONS.priorityDateEnd, formatDate(value))
            }
          />
        </div>
      </div>
      <AppliedFilterLabels
        filters={filters}
        showTerms={false}
        onRemoveFilter={(section, value, subSection = null) => {
          console.log({ filters })
          const updatedFilterSection = updateFilterSection({ section, filters, value, subSection })

          onFilterValueChange(section, updatedFilterSection)
        }}
      />
    </form>
  )
}

Filters.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export default Filters
