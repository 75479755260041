import { hot } from 'react-hot-loader/root'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

import { userDataSelector, signInWithLocalData } from 'store/Account'
import { maintenanceSelector, getMaintenanceStatus } from 'store/Pinger'
import { Routes } from 'router/routes'
import { paths } from 'router/paths'

import Header from 'components/Header'
import Autoupdater from './containers/Autoupdater'
import PageMaintenance from 'components/PageMaintenance'
import ProjectPanel from './containers/ProjectPanel'

import styles from './App.module.scss'

const appMaintenanceStatusRef = 'App_maintenanceStatus'
const maintenanceCheckInterval = 60000

function App() {
  //Google Analytics
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-185287163-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  const [refreshTheApp, setRefreshTheApp] = React.useState(false)
  const [areProjectsOpen, setAreProjectsOpen] = React.useState(false)

  const isDesktopApp = Boolean(window && window.nw)
  const user = useSelector(userDataSelector)
  const showAuthedView = user?.authenticated && user.confirmed

  const { maintenanceMode, lastReleaseDate } = useSelector(maintenanceSelector)
  const lastReleaseDateRef = React.useRef()

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (user) dispatch(signInWithLocalData())
  }, [user, dispatch])

  useEffect(() => {
    if (
      !maintenanceMode &&
      lastReleaseDateRef.current &&
      lastReleaseDateRef.current !== lastReleaseDate
    )
      setRefreshTheApp(true)
  }, [lastReleaseDate, maintenanceMode])

  const path = location.pathname
  useEffect(() => {
    if (showAuthedView && (path === '/' || path === paths.logIn)) history.push(paths.searchPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAuthedView, path, history])

  useEffect(() => {
    dispatch(getMaintenanceStatus({ statusRef: appMaintenanceStatusRef }))

    const interval = setInterval(() => {
      dispatch(getMaintenanceStatus({ statusRef: appMaintenanceStatusRef }))
    }, maintenanceCheckInterval)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!lastReleaseDateRef.current) lastReleaseDateRef.current = lastReleaseDate
  })

  return (
    <div className={styles.base}>
      {showAuthedView &&
        (maintenanceMode || refreshTheApp ? (
          <PageMaintenance
            status={{
              maintenanceMode,
              pageRefresh:
                !maintenanceMode &&
                lastReleaseDateRef.current &&
                lastReleaseDateRef.current !== lastReleaseDate,
            }}
          />
        ) : (
          <Header />
        ))}

      {isDesktopApp && <Autoupdater />}

      <Routes areProjectsOpen={areProjectsOpen} />

      {showAuthedView && (
        <ProjectPanel
          setAreProjectsOpen={value => setAreProjectsOpen(value)}
          areProjectsOpen={areProjectsOpen}
        />
      )}
    </div>
  )
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App
