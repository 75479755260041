import React from 'react'
import PropTypes from 'prop-types'

import styles from './matchedTerm.module.scss'

function MatchedTerm({ term, onTermClick, isTermSelected }) {
  const onTermClicked = () => (isTermSelected ? onTermClick(term, false) : onTermClick(term, true))

  return (
    <span
      data-testid='details-page-list-view-matched-term'
      className={`${styles.base} ${isTermSelected ? styles.active : ''}`}
      onClick={() => onTermClicked()}
    >
      {`${term.term.replace('_', ' ')} (${term.count})`}
    </span>
  )
}

MatchedTerm.propTypes = {
  term: PropTypes.object,
  onTermClick: PropTypes.func,
  isTermSelected: PropTypes.bool,
}

MatchedTerm.defaultProps = {
  term: {},
  selectedTerm: {},
  isTermSelected: false,
}

export default MatchedTerm
